import React, { FC } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Modal } from 'antd'
import { Button } from '@madup-inc/mad-design'
import { DeleteFilled, EditOutlined } from '@ant-design/icons'
import useTemplateAPI from '../../../hooks/template/useTemplateAPI'

const TemplateDetailButtons: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const id = location.pathname.split('/template/')[1]
  const { deleteTemplate } = useTemplateAPI()
  const [modal, contextHolder] = Modal.useModal()

  const onEditClick = () => {
    history.push(`/template/edit?id=${id}`)
  }

  const onDeleteClick = () => {
    modal.confirm({
      title: 'Delete Template',
      content: 'Are you sure you want to delete this template?',
      onOk: async () => {
        await deleteTemplate()
      },
    })
  }

  return (
    <div>
      <Button
        size="small"
        style={{ marginRight: '4px' }}
        onClick={() => {
          onEditClick()
        }}
        type="text"
      >
        <EditOutlined />
      </Button>

      <Button size="small" onClick={() => onDeleteClick()} type="text" danger>
        <DeleteFilled />
      </Button>
      {contextHolder}
    </div>
  )
}

export default TemplateDetailButtons
