import { useSelector, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../../../modules";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTemplateInfo() {
  let templateInfos = typedUseSelector(
    (state: RootState) => state.createJob.template.templateInfos
  );
  const currentTemplate = typedUseSelector(
    (state: RootState) => state.createJob.template.currentTemplate
  );

  if (!templateInfos) {
    templateInfos = [
      {
        template_id: "",
        desc: "",
        group: ""
      },
    ];
  }

  return templateInfos[currentTemplate];
}
