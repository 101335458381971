import React, { FC } from 'react'

import { Button } from '@madup-inc/mad-design'

import { EditOutlined, DeleteFilled } from '@ant-design/icons'

import useScheduleDetailButtons from '../../../hooks/schedule/scheduleDetail/useScheduleDetailButtons'

const ScheduleDetailButtons: FC = () => {
  const { onClickDelete, contextHolder, onClickEdit } = useScheduleDetailButtons()

  return (
    <>
      <Button
        size="small"
        style={{ marginRight: '4px' }}
        onClick={() => {
          onClickEdit()
        }}
        type="text"
      >
        <EditOutlined />
      </Button>
      <Button
        size="small"
        onClick={() => {
          onClickDelete()
        }}
        type="text"
        danger
      >
        <DeleteFilled />
      </Button>
      {contextHolder}
    </>
  )
}

export default ScheduleDetailButtons
