import { useSelector, TypedUseSelectorHook } from "react-redux";
import { RootState } from "../../modules";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTable() {
  const data = typedUseSelector((state: RootState) => state.table.data);

  return data;
}
