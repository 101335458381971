import React, { FC } from "react";
import moment from "moment";

import { Checkbox, TimePicker, Form } from "antd";

const InputPeriodWeek: FC = () => (
  <div>
    <Form.Item name={["period", "value", "day"]} noStyle>
      <Checkbox.Group options={week} defaultValue={[]} />
    </Form.Item>
    <Form.Item name={["period", "value", "time"]} noStyle>
      <TimePicker
        format="HH:mm"
        defaultValue={moment("00:00", "HH:mm")}
        allowClear={false}
      />
    </Form.Item>
  </div>
);

const week = [
  { label: "일", value: 0 },
  { label: "월", value: 1 },
  { label: "화", value: 2 },
  { label: "수", value: 3 },
  { label: "목", value: 4 },
  { label: "금", value: 5 },
  { label: "토", value: 6 },
];

export default InputPeriodWeek;
