import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../../../modules";

import { changeTemplate } from "../../../modules/createJob/template";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTemplates() {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([""]);
  const currentTemplate = typedUseSelector(
    (state: RootState) => state.createJob.template.currentTemplate
  );
  const templateInfos = typedUseSelector(
    (state: RootState) => state.createJob.template.templateInfos
  );

  useEffect(() => {
    try {
      setTemplates(templateInfos.map((template) => template.template_id));
    } catch (e) {
      console.log(e);
    }
  }, [templateInfos]);

  const onClickTemplate = useCallback(
    (key: number) => dispatch(changeTemplate(key)),
    [dispatch]
  );

  return { currentTemplate, templates, onClickTemplate };
}
