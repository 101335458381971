import * as React from "react";

import { Descriptions } from "antd";

import useTemplateInfo from "../../../hooks/job/jobCreate/useTemplateInfo";

const JobCreateTemplateInfo: React.FC = () => {
  const templateInfo = useTemplateInfo();

  return (
    <Descriptions title="Template Information" bordered column={1}>
      <Descriptions.Item label="Template ID" span={1}>
        {templateInfo.template_id}
      </Descriptions.Item>
      <Descriptions.Item label="Group" span={1}>
        {templateInfo.group}
      </Descriptions.Item>
      <Descriptions.Item label="Description" span={1}>
        {templateInfo.desc}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default JobCreateTemplateInfo;
