import { useEffect } from 'react'
import { parseSearchParams, loadJs } from '@madup-inc/utils'
import { useLocation } from 'react-router'
import { getToken } from '../lib/token'

export default function useGoogleOneTap({ callback }) {
  const location = useLocation()

  useEffect(() => {
    if (getToken()) {
      return
    }

    const { token, code } = parseSearchParams(location.search)
    if (token || code) {
      // token 이나 code 값등 로그인 credentials 를 가지고 들어오는 경우
      return
    }

    loadJs('https://accounts.google.com/gsi/client').then(() => {
      if (!window.google) {
        throw Error('[GoogleOneTabLogin error] Google library is undefined.')
      }
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback,
        context: 'use',
        cancel_on_tap_outside: false,
      })
      window.google.accounts.id.prompt()
    })

    return () => window.google?.accounts.id.cancel()
  }, [])
}
