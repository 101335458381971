import * as React from "react";

import "./../../lib/styles/JSONViewer.scss";

type JSONViewerPropertyProps = {
  keyName: string;
  value: string;
};

const JSONViewerProperty: React.FC<JSONViewerPropertyProps> = ({
  keyName,
  value,
}) => (
  <>
    <p className="json-value-keyname">{keyName}</p>
    <p className="json-value-value">{value}</p>
  </>
);

export default JSONViewerProperty;
