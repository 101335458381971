import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ScheduleListPage from "./ScheduleListPage";
import ScheduleCreatePage from "./ScheduleCreatePage";
import ScheduleDetailPage from "./ScheduleDetailPage";
import ScheduleEditPage from "./ScheduleEditPage";

const Schedule: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ScheduleListPage} />
      <Route exact path={`${path}/create`} component={ScheduleCreatePage} />
      <Route exact path={`${path}/edit`} component={ScheduleEditPage} />
      <Route
        exact
        path={`${path}/:scheduleID`}
        component={ScheduleDetailPage}
      />
    </Switch>
  );
};

export default Schedule;
