import React, { FC, useState } from "react";
import { Modal, Input, Typography } from "antd";

import "./style.scss";
import { getField, ReportField } from "../../../lib/api/report";
import useError from "../../../hooks/useError";

type ReportNameModalProps = {
  setStep: (step: number) => void;
  setReportField: (reportField: ReportField) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  channelID: string | number;
  reportInfo: { name: string; type: string };
  setReportInfo: (reportInfo: { name: string; type: string }) => void;
};

const ReportNameModal: FC<ReportNameModalProps> = ({
  visible,
  setVisible,
  setStep,
  setReportField,
  channelID,
  reportInfo,
  setReportInfo,
}) => {
  const { handleError } = useError();
  const [reportName, setReportName] = useState(reportInfo.name);
  const handleNextClick = async () => {
    try {
      const response = await getField({ channelName: channelID, reportType: reportInfo.type });
      setReportInfo({ ...reportInfo, name: reportName });
      setReportField(response.data);
      setStep(2);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Report Name"
      className="report-name-modal"
      onCancel={() => {
        setVisible(false);
      }}
      okText="Next"
      cancelButtonProps={{ style: { display: "hidden" } }}
      onOk={handleNextClick}
    >
      <Input
        placeholder="report name"
        value={reportName}
        onChange={e => {
          setReportName(e.target.value);
        }}
      />
      <Typography.Text type={"secondary"} style={{ fontSize: "12px" }}>
        동일한 report name 있다면 변경해주시기 바랍니다(영어 소문자, 숫자만 가능, 띄어쓰기 불가능)
      </Typography.Text>
    </Modal>
  );
};

export default ReportNameModal;
