import axios from "axios";

import baseUrl from "./baseurl";
import { getToken } from "../token";
import { Context } from "./context";

type TableData = { [key: string]: string }[];

type Product = {
  product_id: string;
  owner_id: string;
  product_name: string;
  desc: string;
};

type Sourcecode = {
  file_name: string;
  file: any[];
  class_name: string;
  desc: string;
};

export const getTableData = async (path: string, search: string) =>
  await axios.get<TableData>(`${baseUrl}${path}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    params: { search: search },
  });

export const createNewData = async (path: string, data: Context | Product | Sourcecode) => {
  if (path === "/sourcecode") {
    return await createSourcecode(data as Sourcecode);
  }

  return await axios.post(`${baseUrl}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

export const createSourcecode = async (data: Sourcecode) => {
  const formData = new FormData();

  formData.append("file_name", data.file_name);
  formData.append("class_name", data.class_name);
  formData.append("file", data.file[0].originFileObj);
  formData.append("desc", data.desc);

  return await axios.post(`${baseUrl}/sourcecode`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
  });
};
