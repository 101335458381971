import React, { FC } from "react";

import { Divider } from "antd";

import ContextDetailJobList from "./TableDetailJobList";
import TableDetailBasicInfo from "./TableDetailBasicInfo";
import useContextDetail from "../../../hooks/context/useContextDetail";
import { DetailWithRef } from "../../../constants/detail/type";
import { Form } from "../../../constants/form/type";

type TableDetailProps = {
  form: DetailWithRef;
  modalForm: () => Form[];
}

const TableDetail: FC<TableDetailProps> = ({ form, modalForm }) => {
  const { detail } = useContextDetail(form.path);

  return (
    <>
      <TableDetailBasicInfo
        form={form}
        data={detail}
        modalForm={modalForm}
      />
      <Divider/>
      <ContextDetailJobList data={detail.job_list}/>
    </>
  );
};

export default TableDetail;
