// Actions
const OPEN_MODAL = "modal/OPEN_MODAL" as const;
const CLOSE_MODAL = "modal/CLOSE_MODAL" as const;

// Actions Type
type ModalAction = ReturnType<typeof openModal> | ReturnType<typeof closeModal>;

// State Type & Initial State
type ModalState = {
  isOpen: boolean;
  index: number | string;
};

const initialState: ModalState = {
  isOpen: false,
  index: ""
};

// Reducer
const modal = (state: ModalState = initialState, action: ModalAction) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { isOpen: true, index: action.payload };
    case CLOSE_MODAL:
      return { isOpen: false, index: "" };
    default:
      return state;
  }
};

export default modal;

// Action Creators
export const openModal = (index: number | string) => ({
  type: OPEN_MODAL,
  payload: index
});

export const closeModal = () => ({
  type: CLOSE_MODAL
});
