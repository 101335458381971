import * as React from 'react'
import { Upload, Form } from 'antd'
import { Button } from '@madup-inc/mad-design'
import { UploadOutlined } from '@ant-design/icons'

import useFileTypeChecker from '../../hooks/input/useFileTypeChecker'

type InputPyFileProps = {
  name: string
  required: boolean
  editable: boolean
}

const InputPyFile: React.FC<InputPyFileProps> = ({ name, required, editable }) => {
  const props = useFileTypeChecker('text/x-python-script')
  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Form.Item
      name={name}
      label={name}
      rules={[
        {
          required: editable && required,
          message: `'${name}' is required`,
        },
      ]}
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload {...props}>
        <Button size="small">
          <UploadOutlined /> Upload .py file only
        </Button>
      </Upload>
    </Form.Item>
  )
}

export default InputPyFile
