import * as React from "react";
import { Link } from "react-router-dom";

import { Layout, Menu } from "antd";

import useSidebar, { categories } from "../../hooks/sidebar/useSidebar";

import "./../../lib/styles/layout.scss";

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [openKeys, setOpenKeys] = React.useState([""]);
  const { isCollapsed, selectedKey } = useSidebar();
  const rootSubmenuKeys = Object.entries(categories).map(
    (item, idx) => `${idx}`
  );

  const getCategories = () =>
    Object.entries(categories).map((item, idx) => {
      if (item[1].menu) {
        return (
          <SubMenu key={idx} icon={item[1].icon} title={item[0]}>
            {getSubclasses(item[1].menu, idx, item[1].path)}
          </SubMenu>
        );
      }
      return (<Menu.Item key={`${idx}`} icon={item[1].icon}>
        <Link to={`${item[1].path}`}>{item[0]}</Link>
      </Menu.Item>);
    });

  const getSubclasses = (menu: string[][], subIdx, subPath: string) =>
    menu.map((item, idx) => (
      <Menu.Item key={`${subIdx}-${idx}`}>
        <Link to={`${subPath}${item[1]}`}>{item[0]}</Link>
      </Menu.Item>
    ));

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider trigger={null} collapsible collapsed={isCollapsed} theme="light">
      <div className="layout-sider-logo">
        <h1>Argo</h1>
      </div>
      <Menu
        theme="light"
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={["/"]}
        selectedKeys={[selectedKey]}
      >
        {getCategories()}
      </Menu>
    </Sider>
  );
};
export default Sidebar;
