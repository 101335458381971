import axios from "axios";
import baseUrl from "./baseurl";
import { getToken } from "./../token";

const url = `${baseUrl}/schedule`;

export type ScheduleForm = {
  job_id: string;
  scheduled_time: string;
  expired_time: string;
  status: string;
  period: {
    case: number;
    value?: any;
  };
};

export const createSchedule = async (schedule: ScheduleForm) =>
  await axios.post(`${url}`, schedule, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getSchedule = async (index: string) =>
  await axios.get(`${url}/${index}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteSchedule = async (index: string, job_id: string) =>
  await axios.delete(`${url}/${index}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      id: job_id
    }
  });

export const editSchedule = async (schedule: ScheduleForm, idx: string) =>
  await axios.put(`${url}/${idx}`, schedule, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      id: schedule.job_id
    }
  });
