import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { Form } from "antd";

import useCurrentTime from "../../useCurrentTime";
import { getDetail } from "../../../lib/api/listTable";
import useError from "../../useError";

export default function useScheduleForm() {
  const [fields] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleError } = useError();
  const location = useLocation();
  const time = useCurrentTime();

  const getJobIdFromURL = () => {
    if (location.search.split("=")[1]) {
      return location.search.split("=")[1].replace("%20", " ");
    }

    return "";
  };

  useEffect(() => {
    (async () => {
      await setFieldsOnLoad();
    })();
  }, [fields]);

  const setFieldsOnLoad = async () => {
    setLoading(true);
    if (location.pathname === "/schedule/edit") {
      try {
        const index = location.search.split("index=")[1];
        const scheduleDetail = (await getDetail("/schedule", index)).data;
        fields.setFieldsValue({
          job_id: `${scheduleDetail.job_name} (${scheduleDetail.job_id})`,
          status: `${scheduleDetail.status}`,
          scheduled_time: moment(
            `${scheduleDetail.scheduled_time}`,
            "YYYY-MM-DD HH:mm:ss"
          ),
          expired_time:
            scheduleDetail.expired_time === "None"
              ? undefined
              : moment(`${scheduleDetail.expired_time}`, "YYYY-MM-DD HH:mm:ss"),
          period: convertPeriodTime(scheduleDetail.period),
        });
      } catch (e) {
        handleError(e);
      }
    } else {
      fields.setFieldsValue({
        job_id: getJobIdFromURL(),
        status: "enable",
        scheduled_time: time,
        expired_time: "",
        period: { case: 0 },
      });
    }
    setLoading(false);
  };

  const convertPeriodTime = (period: { case: number; value?: any }) => {
    const newPeriod = { ...period };
    if (period.value && period.value.time) {
      newPeriod.value.time = moment(period.value.time, "HH:mm");
    }
    return newPeriod;
  };

  const onFieldsChange = (name: string, value: any) => {
    const newField = {};
    newField[`${name}`] = value;
    fields.setFieldsValue(newField);
  };

  const getFormatValue = (value: object) => {
    const newValue = { ...value };
    Object.entries(value).map((item) => {
      if (item[1]) {
        if (item[1].hasOwnProperty("_d")) {
          newValue[`${item[0]}`] = item[1].format("YYYY-MM-DD HH:mm:ss");
        }
        if (item[0] === "job_id") {
          let splitId = item[1].split(" (")[1];
          newValue["job_id"] = splitId.slice(0, splitId.length - 1);
        } else if (item[0] === "period") {
          if (item[1].value && item[1].value.time) {
            newValue["period"]["value"]["time"] = item[1].value.time.format(
              "HH:mm"
            );
          }
        }
      }
    });

    if (newValue["period"].case === 0 || !newValue["expired_time"]) {
      delete newValue["expired_time"];
    }

    return newValue;
  };

  return {
    fields,
    onFieldsChange,
    getFormatValue,
    loading
  };
}
