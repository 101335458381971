import React, { FC, useEffect, useState } from 'react'
import { List, Select, Card, message, Modal, Space, DatePicker } from 'antd'
import { Button, Input, Tag } from '@madup-inc/mad-design'
import { useHistory } from 'react-router-dom'
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons'

import useError from '../../../hooks/useError'
import {
  getReportOwnerIDs,
  getReports,
  deleteReport as deleteReportAPI,
  Reports,
  getLogs,
  postReportRegister,
  postRunReport,
  postRunPrismReport,
  postRecollectPrismReport,
} from '../../../lib/api/report'
import DashboardLogItem from '../../Dashboard/DashboardLog/DashboardLogItem'

type ReportListProps = {
  ownerID: string | undefined
  setOwnerID: (ownerID: string | undefined) => void
  fetchChannels: () => void
}

type Log = {
  job_id: string
  log_level: 100 | 200 | 300 | 400
  log_time: string
}

const ReportList: FC<ReportListProps> = ({ ownerID = '', setOwnerID, fetchChannels }) => {
  const history = useHistory()
  const [modal, context] = Modal.useModal()
  const [loading, setLoading] = useState<boolean>(false)
  const [reports, setReports] = useState<Reports>()
  const [ownerIDs, setOwnerIDs] = useState<string[]>()
  const [jobID, setJobID] = useState<string>('')
  const [logs, setLogs] = useState<Log[]>([])
  const [disable, setDisable] = useState(false)
  const [recollectTarget, setRecollectTarget] = useState<number | undefined>()
  const [date, setDate] = useState<any>()
  const { handleError } = useError()
  const fetchReportList = async (ownerID: string | undefined) => {
    setLoading(true)
    try {
      const response = await getReports({ ownerID })
      setReports(response.data)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }
  const fetchReportOwnerIDs = async () => {
    try {
      const response = await getReportOwnerIDs()
      setOwnerIDs(response.data.data)
    } catch (e) {
      handleError(e)
    }
  }
  const deleteReport = async ({ reportID, reportName }) => {
    try {
      await deleteReportAPI(reportID)
      message.success(`${reportName}을 성공적으로 삭제하였습니다.`)
      await fetchReportList(ownerID)
    } catch (e) {
      handleError(e)
    }
  }

  const fetchLogs = async (ownerID: string, jobID?: string) => {
    try {
      const response = await getLogs({ ownerID, jobID })
      setLogs(response.data.data)
    } catch (e) {
      handleError(e)
    }
  }
  const registerReport = async reportID => {
    try {
      await postReportRegister(reportID)
    } catch (e) {
      handleError(e)
    }
  }
  const handleClickRerun = async (reportID, runType?: 'query') => {
    try {
      await postRunReport(reportID, runType)
      await fetchLogs(ownerID)
      await fetchReportList(ownerID)
      setDisable(true)
      setTimeout(() => {
        setDisable(false)
      }, 10000)
    } catch (e) {
      handleError(e)
    }
  }
  const handleClickPrismRerun = async reportID => {
    try {
      await postRunPrismReport(reportID)
      await fetchLogs(ownerID)
      setDisable(true)
      setTimeout(() => {
        setDisable(false)
      }, 10000)
    } catch (e) {
      handleError(e)
    }
  }
  const handleClickPrismRecollect = async () => {
    try {
      await postRecollectPrismReport(
        recollectTarget,
        date[0].format('YYYY-MM-DD'),
        date[1].format('YYYY-MM-DD'),
      )
      await fetchLogs(ownerID)
      setRecollectTarget(undefined)
      setDisable(true)
      setTimeout(() => {
        setDisable(false)
      }, 10000)
    } catch (e) {
      handleError(e)
    }
  }

  useEffect(() => {
    fetchReportOwnerIDs()
    fetchReportList(ownerID)
    if (ownerID) {
      fetchLogs(ownerID)
    }
  }, [])

  return (
    <section>
      <Select
        showSearch
        allowClear
        style={{
          width: 200,
          margin: '0 12px 14px 0',
        }}
        options={ownerIDs?.map(option => ({
          label: option,
          value: option,
        }))}
        placeholder="Owner ID"
        value={ownerID}
        onChange={e => {
          setOwnerID(e)
          fetchLogs(e)
          fetchReportList(e)
        }}
      />
      <Button
        type="primary"
        onClick={async () => {
          if (!ownerID) {
            message.error('추가할 리포트의 Owner ID를 선택해 주세요.')
            return
          }
          await fetchChannels()
          history.push('/report/create')
        }}
        size="small"
      >
        리포트 추가
      </Button>
      <List
        style={{ marginBottom: '24px' }}
        bordered
        itemLayout="horizontal"
        dataSource={reports?.data}
        loading={loading}
        renderItem={item => (
          <List.Item
            actions={[
              ...(item.is_registered
                ? item.is_prism
                  ? [
                      <Button
                        size="small"
                        ghost
                        disabled={disable}
                        onClick={() => {
                          handleClickPrismRerun(item.id)
                        }}
                      >
                        재실행
                      </Button>,
                      <Button
                        size="small"
                        ghost
                        onClick={() => {
                          setRecollectTarget(item.id)
                        }}
                        disabled={disable}
                      >
                        재수집
                      </Button>,
                    ]
                  : [
                      <Button
                        size="small"
                        ghost
                        onClick={() => {
                          handleClickRerun(item.id)
                        }}
                        disabled={disable}
                      >
                        재실행(전체)
                      </Button>,
                      <Button
                        size="small"
                        ghost
                        onClick={() => {
                          handleClickRerun(item.id, 'query')
                        }}
                        disabled={disable}
                      >
                        재실행(부분)
                      </Button>,
                    ]
                : [
                    <Button
                      size="small"
                      ghost
                      type="primary"
                      onClick={async () => {
                        await registerReport(item.id)
                        await fetchReportList(ownerID)
                        await fetchLogs(ownerID)
                      }}
                    >
                      등록
                    </Button>,
                  ]),
              <Button
                ghost
                size="small"
                onClick={() => {
                  history.push(`/report/edit/${item.id}`)
                }}
              >
                수정
              </Button>,
              <Button
                size="small"
                ghost
                danger
                onClick={() => {
                  modal.confirm({
                    okText: '삭제',
                    okButtonProps: { danger: true },
                    cancelText: '취소',
                    title: '리포트 Job 삭제',
                    content: `정말 ${item.report_name}을 삭제하시겠습니까?`,
                    onOk: () => deleteReport({ reportID: item.id, reportName: item.report_name }),
                  })
                }}
              >
                삭제
              </Button>,
            ]}
          >
            <Tag
              style={{ display: 'inline-block', marginRight: 8 }}
              color={item.is_prism ? 'orange' : 'blue'}
            >
              {item.is_prism ? 'Prism' : 'Argo'}
            </Tag>
            {item.report_name}
          </List.Item>
        )}
      />
      <Space>
        <Input
          placeholder="Search Job Name"
          addonAfter={
            <Button onClick={() => fetchLogs(ownerID, jobID)}>
              <SearchOutlined />
            </Button>
          }
          size="small"
          onChange={e => setJobID(e.target.value)}
          onPressEnter={() => fetchLogs(ownerID, jobID)}
        />
        <Button
          onClick={() => {
            fetchLogs(ownerID)
          }}
          size="small"
        >
          <ReloadOutlined />
        </Button>
      </Space>
      <Card
        style={{
          backgroundColor: '#262626',
          overflowY: 'auto',
          height: '500px',
          marginTop: '12px',
        }}
      >
        {logs?.map(log => (
          <DashboardLogItem
            job_name={log.job_id}
            log_level={log.log_level}
            start_time={log.log_time}
          />
        ))}
      </Card>
      <Modal
        visible={Boolean(recollectTarget)}
        title="재수집 기간 선택"
        onCancel={() => setRecollectTarget(undefined)}
        onOk={() => {
          handleClickPrismRecollect()
        }}
      >
        <DatePicker.RangePicker style={{ width: '100%' }} onChange={date => setDate(date)} />
      </Modal>
      {context}
    </section>
  )
}

export default ReportList
