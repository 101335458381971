import * as React from "react";

import { Form, Input } from "antd";

import { SectionName } from "../../modules/createJob/form";

const { TextArea } = Input;

type InputTextProps = {
  required: boolean;
  editable: boolean;
  name: string;
  DESC: string;
  sectionName?: SectionName;
};

const InputText: React.FC<InputTextProps> = ({
  name,
  required,
  DESC,
  editable,
  sectionName,
}) => (
  <Form.Item
    label={name}
    name={sectionName ? `${sectionName}/${name}` : `${name}`}
    rules={[
      {
        required: editable && required,
        message: `'${name}' is required`,
      },
    ]}
  >
    <TextArea autoSize disabled={!editable} placeholder={DESC}/>
  </Form.Item>
);

export default InputText;
