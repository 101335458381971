import React, { FC } from "react";

import { Divider, Row, Typography } from "antd";

import Input from "../../Input";
import useJobEditTemplate from "../../../hooks/job/jobEdit/useJobEditTemplate";
import { SectionName } from "../../../modules/createJob/form";

const { Title } = Typography;

type JobEditFormSectionProps = {
  name: string;
  desc: string;
  sectionName: SectionName;
  getFieldValue: (name: string) => any;
  onFieldChange: (name: string, value: any) => void;
};

const JobEditFormSection: FC<JobEditFormSectionProps> = ({
  name,
  desc,
  sectionName,
  getFieldValue,
  onFieldChange,
}) => {
  const template = useJobEditTemplate();

  if (template[sectionName].length !== 0) {
    return (
      <section>
        <Divider />
        <Title level={4}>{name}</Title>
        <p>{desc}</p>
        <Row gutter={16}>
          {template[sectionName].map((item) => (
            <Input
              name={item.name}
              required={item.required}
              editable={item.editable}
              desc={item.desc}
              type={item.type}
              sectionName={sectionName}
              getFieldValue={getFieldValue}
              onFieldsChange={onFieldChange}
            />
          ))}
        </Row>
      </section>
    );
  }
  return null;
};

export default JobEditFormSection;
