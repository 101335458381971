import React, { FC } from "react";

import { Form } from "antd";
import { FormInstance } from "antd/lib/form";

import Modal from "./";
import Input from "../Input";
import { Form as FormType } from "../../constants/form/type";

type ModalFormProps = {
  title: string;
  visible: boolean;
  form: () => FormType[];
  fields: FormInstance;
  handleOK: () => void;
  handleCancel: () => void;
  okText: string;
}

const ModalForm: FC<ModalFormProps> = ({ title, visible, form, fields, handleOK, handleCancel, okText }) => {
  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      handleOK={handleOK}
      handleCancel={handleCancel}
    >
      <Form layout="vertical" form={fields}>
        {
          form().map(item =>
            <Input
              name={item.name}
              required={item.required}
              editable={item.editable}
              desc={item.desc}
              type={item.type}
              onFieldsChange={() => {
              }}
              getFieldValue={() => {
              }}
              span={24}
              autoOptions={item.autoOptions}
            />)
        }
      </Form>
    </Modal>
  );
};

export default ModalForm;
