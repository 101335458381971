// Actions
const INIT_TABLE = "table/INIT_TABLE" as const;
const SET_TABLE = "table/SET_TABLE" as const;

// Actions Type
type TableAction =
  | ReturnType<typeof initTable>
  | ReturnType<typeof setTable>

// State Type & Initial State
type TableState = {
  total: number;
  data: { [key: string]: any }[];
};

const initialState: TableState = {
  total: 0,
  data: [],
};

// Reducer
const table = (state: TableState = initialState, action: TableAction) => {
  switch (action.type) {
    case INIT_TABLE:
      return initialState;
    case SET_TABLE:
      if (action.payload.hasOwnProperty("total")) {
        return {
          ...state,
          ...(action.payload as {
            total: number;
            data: { [key: string]: any }[];
          }),
        };
      }
      return {
        ...state,
        data: action.payload as { [key: string]: any }[],
        total: 0,
      };
    default:
      return state;
  }
};

export default table;

// Action Creators
export const initTable = () => ({
  type: INIT_TABLE,
});

export const setTable = (
  table:
    | {
    total: number;
    data: { [key: string]: any }[];
  }
    | { [key: string]: any }[]
) => ({
  type: SET_TABLE,
  payload: table,
});
