import React, { FC, useState } from 'react'

import { Button } from '@madup-inc/mad-design'

import useStepState from '../../../../hooks/useStepState'
import useForm from '../../../../hooks/job/jobCreate/useForm'

const JobCreateButtonOnFirst: FC = () => {
  const { onClickNext } = useStepState()
  const [isLoading, setIsLoading] = useState(false)
  const { setForm } = useForm()

  const onClickNextFirst = async () => {
    setIsLoading(true)
    await setForm()
    await setIsLoading(false)
    onClickNext()
  }

  return (
    <section style={{ marginTop: '24px', textAlign: 'right' }}>
      <Button style={{ marginLeft: '8px' }} type="primary" onClick={() => onClickNextFirst()}>
        Next
      </Button>
    </section>
  )
}

export default JobCreateButtonOnFirst
