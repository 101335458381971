import * as React from "react";

import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { GoogleNormalButton } from "../../assets";

import useSidebar from "../../hooks/sidebar/useSidebar";
import useUser from "../../hooks/useUser";

import UserDropdown from "./UserDropdown";
import signInUrl from "../../constants/signInUrl";

const { Header: HeaderComponent } = Layout;

const Header: React.FC = () => {
  const { isCollapsed, setIsCollapsed } = useSidebar();
  const { isLogin } = useUser();

  return (
    <HeaderComponent
      className="site-layout-background"
      style={{
        padding: "0 24px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {React.createElement(
        isCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "layout-header-trigger",
          onClick: setIsCollapsed,
        }
      )}
      {isLogin ? <UserDropdown/> :
        <a style={{
          all: "unset",
          cursor: "pointer",
          // width: "12px",
          height: "40px"
        }} href={signInUrl}>
          <img
            style={{ height: "100%" }}
            src={GoogleNormalButton}
          />
        </a>}
    </HeaderComponent>
  );
};

export default Header;
