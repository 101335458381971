import React, { FC } from "react";

import Table from "./../../Table";
import { scheduleTable } from "../../../constants/table";

const ScheduleList: FC = () => (
  <div>
    <Table table={scheduleTable}/>
  </div>
);

export default ScheduleList;
