import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { Form, message } from "antd";

import { RootState } from "../../../modules";
import { cloneJob } from "../../../lib/api/job";
import useError from "../../useError";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useJobClone() {
  const [fields] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const jobName = (() => {
    const JSONViewerState = typedUseSelector(
      (state: RootState) => state.JSONViewer
    );

    if (JSONViewerState.hasOwnProperty("basic_info"))
      return JSONViewerState.basic_info.job_name;
    return "";
  })();
  const jobID = location.pathname.split("/job/")[1];
  const { handleError } = useError();

  const onClickOK = () => {
    fields.validateFields().then((values) => {
      postCloneJob().then(() => {
      });
      fields.resetFields();
    });
  };

  const postCloneJob = async () => {
    try {
      const jobName = fields.getFieldValue("job_name");
      await cloneJob(jobID, jobName);
      message.success("Job has been successfully cloned!");
      history.push("/job");
    } catch (e) {
      handleError(e);
    }
  };

  return {
    jobName,
    fields,
    onClickOK
  };
}
