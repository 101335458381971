import * as React from "react";

import useScheduleAPI from "../../../hooks/schedule/scheduleCreate/useScheduleAPI";
import ScheduleCreateSubmitButton from "./ScheduleCreateSubmitButton";
import ScheduleForm from "../ScheduleForm";

const ScheduleCreate: React.FC = () => {
  const { submitNewSchedule, loading } = useScheduleAPI();

  return (
    <ScheduleForm
      SubmitButton={<ScheduleCreateSubmitButton loading={loading} />}
      onFinish={submitNewSchedule}
    />
  );
};

export default ScheduleCreate;
