import React, { FC } from "react";

import ContextDetail from "../../components/Table/Detail";
import context from "../../constants/detail/context";
import contextForm from "../../constants/form/context";

const ContextDetailPage: FC = () => <ContextDetail modalForm={contextForm}
                                                   form={context}/>;

export default ContextDetailPage;
