import React, { FC } from "react";

import { Form, Switch } from "antd";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

type TemplateFormSwitchProps = {
  field: any;
  name: string;
  required: boolean;
  desc: string;
  editable: boolean;
};

const TemplateFormSwitch: FC<TemplateFormSwitchProps> = ({
  field,
  name,
  required,
  desc,
  editable,
}) => (
  <Form.Item
    {...field}
    label={name}
    name={[field.name, name]}
    fieldKey={[field.fieldKey, name]}
    rules={[{ required: required, message: `Missing ${name}` }]}
    valuePropName="checked"
    initialValue={false}
  >
    <Switch
      disabled={!editable}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
    />
  </Form.Item>
);

export default TemplateFormSwitch;
