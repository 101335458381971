import React from "react";

import { Table } from "./type";
import TableSearchFilter from "../../components/Table/TableSearchFilter";

export default {
  path: "/template",
  detail: "template_id",
  pagination: {
    position: ["bottomCenter"],
    defaultPageSize: 20,
    size: "default",
  },
  columns: [
    {
      title: "Group",
      dataIndex: "group",
      filterDropdown: <TableSearchFilter name="group" placeholder="Search Group" />,
      sorter: {
        compare: (a, b) => `${a.group}`.localeCompare(`${b.group}`),
        multiple: 0,
      },
    },
    {
      title: "Template ID",
      dataIndex: "template_id",
      filterDropdown: <TableSearchFilter name="template_id" placeholder="Search Template ID" />,
      sorter: {
        compare: (a, b) => `${a.template_id}`.localeCompare(`${b.template_id}`),
        multiple: 0,
      },
    },
    {
      title: "Description",
      dataIndex: "desc",
      filterDropdown: <TableSearchFilter name="desc" placeholder="Search Description" />,
      sorter: {
        compare: (a, b) => `${a.desc}`.localeCompare(`${b.desc}`),
        multiple: 0,
      },
    },
  ],
} as Table;
