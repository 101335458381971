import { useLocation, useHistory } from "react-router-dom";

import { Modal, message } from "antd";
import useError from "../../useError";

import {
  deleteSchedule as deleteScheduleAPI,
  getSchedule
} from "../../../lib/api/schedule";

export default function useScheduleDetailButtons() {
  const location = useLocation();
  const history = useHistory();
  const index = location.pathname.split("schedule/")[1];
  const [modal, contextHolder] = Modal.useModal();
  const { handleError } = useError();

  const onClickDelete = () => {
    modal.confirm({
      title: "Delete Schedule",
      content: "Are you sure you want to delete this schedule?",
      okText: "Delete",
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteSchedule();
      },
    });
  };

  const onClickEdit = () => {
    history.push(`/schedule/edit?index=${index}`);
  };

  const deleteSchedule = async () => {
    try {
      const response = await getSchedule(index);
      await deleteScheduleAPI(index, response.data.job_id);
      history.push("/schedule");
      message.success("Successfully schedule has been deleted!");
    } catch (e) {
      handleError(e);
    }
  };

  return {
    onClickDelete,
    contextHolder,
    onClickEdit
  };
}
