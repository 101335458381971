import { DetailWithRef } from "./type";

export default <DetailWithRef>{
  title: "Context",
  info: [{
    label: "Context Key",
    dataIndex: "key"
  }, {
    label: "Context Value",
    dataIndex: "value"
  }, {
    label: "Description",
    dataIndex: "desc"
  }],
  path: "/context_param"
};
