import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Spin } from 'antd'
import { Button } from '@madup-inc/mad-design'

import './ReportCreate/style.scss'
import ReportJobSettingForm from './ReportJobSettingForm'
import useError from '../../hooks/useError'
import { getEditField, putEditedReport } from '../../lib/api/report'

const ReportEdit: FC = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams()
  const [reportField, setReportField] = useState({})
  const [loading, setLoading] = useState<boolean>(false)
  const { handleError } = useError()
  const fetchReportField = async () => {
    setLoading(true)
    try {
      const response = await getEditField(id)
      setReportField(response.data)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }
  const editReport = async () => {
    try {
      const report = form.getFieldsValue()
      await putEditedReport({ report, reportID: id })
    } catch (e) {
      handleError(e)
    }
  }

  useEffect(() => {
    if (!id) {
      history.push('/report')
    }
    fetchReportField()
  }, [id])

  return (
    <section className="report-job-wrapper">
      {loading && <Spin />}
      <Form form={form}>
        <ReportJobSettingForm field={reportField} isEdit />
        <div className="report-button-wrapper">
          <Button
            onClick={() => {
              history.goBack()
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              await editReport()
              history.push('/report')
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </section>
  )
}

export default ReportEdit
