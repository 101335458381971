import React, { FC } from "react";

import { AutoComplete, Form, Input } from "antd";

type InputAutoCompleteProps = {
  required: boolean;
  editable: boolean;
  name: string;
  DESC: string;
  autoOptions?: { value: string }[];
}

const InputAutoComplete: FC<InputAutoCompleteProps> = ({ required, editable, name, DESC, autoOptions}) => {
  return (
    <Form.Item
      label={name}
      name={name}
      rules={[
        {
          required: editable && required,
          message: `'${name}' is required`,
        },
      ]}
    >
      <AutoComplete
        options={autoOptions}
        filterOption={(
          inputValue,
          option
        ) => {
          if (option) return option.value.indexOf(inputValue) !== -1;
          return true;
        }}
      >
        <Input placeholder={DESC}/>
      </AutoComplete>
    </Form.Item>
  );
};

export default InputAutoComplete;
