import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getDetail } from '../../lib/api/listTable'
import useError from '../useError'

export default function useMonitoringDetail() {
  const [detail, setDetail] = useState<object>({})
  const location = useLocation()
  const { handleError } = useError()

  useEffect(() => {
    if (location.pathname !== '/monitoring') getMonitoringDetail()
  }, [location.pathname])

  const getMonitoringDetail = async () => {
    try {
      const response = await getDetail('/monitoring', location.pathname.split('/monitoring/')[1])
      setDetail(response.data)
    } catch (e) {
      handleError(e)
    }
  }

  return { detail }
}
