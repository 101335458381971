import axios from "axios";

import baseUrl from "./baseurl";
import { getToken } from "./../token";

const url = `${baseUrl}/job`;

export const submitNewJob = async (job: { [key: string]: any }) =>
  await axios.post(`${url}`, job, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteJob = async (job_id: string) =>
  await axios.delete(`${url}/${job_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      id: job_id
    }
  });

export const cloneJob = async (job_id: string, job_name: string) =>
  await axios.post(
    `${url}/copy`,
    {
      job_id,
      edit_name: job_name
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );

export const checkJobSchedule = async (job_id: string) =>
  await axios.get(`${url}/schedule/${job_id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const editJob = async (job_id: string, job: { [key: string]: any }) =>
  await axios.put(`${url}/${job_id}`, job, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      id: job_id
    }
  });
