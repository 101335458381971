import React from 'react'

import { Table } from './type'
import TableSearchFilter from '../../components/Table/TableSearchFilter'

export default {
  path: '/product',
  detail: ['owner_id', 'product_id'],
  pagination: {
    position: ['bottomCenter'],
    defaultPageSize: 20,
    size: 'default',
  },
  columns: [
    {
      title: 'Product ID',
      dataIndex: 'product_id',
      filterDropdown: <TableSearchFilter name="product_id" placeholder="Search Product ID" />,
      sorter: {
        compare: (a, b) => `${a.product_id}`.localeCompare(`${b.product_id}`),
        multiple: 0,
      },
    },
    {
      title: 'Owner ID',
      dataIndex: 'owner_id',
      filterDropdown: <TableSearchFilter name="owner_id" placeholder="Search Owner ID" />,
      sorter: {
        compare: (a, b) => `${a.owner_id}`.localeCompare(`${b.owner_id}`),
        multiple: 0,
      },
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      filterDropdown: <TableSearchFilter name="product_name" placeholder="Search Product Name" />,
      sorter: {
        compare: (a, b) => `${a.product_name}`.localeCompare(`${b.product_name}`),
        multiple: 0,
      },
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      filterDropdown: <TableSearchFilter name="industry" placeholder="Search Industry" />,
      sorter: {
        compare: (a, b) => `${a.industry}`.localeCompare(`${b.industry}`),
        multiple: 0,
      },
      render: text => (text === null ? '-' : text),
    },
    {
      title: 'Description',
      dataIndex: 'desc',
      filterDropdown: <TableSearchFilter name="desc" placeholder="Search Description" />,
      sorter: {
        compare: (a, b) => `${a.desc}`.localeCompare(`${b.desc}`),
        multiple: 0,
      },
    },
  ],
} as Table
