import React, { FC } from "react";
import { Link } from "react-router-dom";

import { Card, Typography } from "antd";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";

const { Title } = Typography;

type DashboardStatusCardProps = {
  type: string;
  number: any;
}

const DashboardStatusCard: FC<DashboardStatusCardProps> = ({ type, number }) =>
  <Link to={`${getLink(type)}`}>
    <Card style={{
      width: "100%",
      height: "100%"
    }}>
      {getIcon(type)}
      <Title level={4} style={{ margin: 0 }}>{number}</Title>
      <p style={{ margin: 0 }}>{convertFirstCharToUpperCase(type)}</p>
    </Card>
  </Link>;

export default DashboardStatusCard;

const convertFirstCharToUpperCase = (str: string) => {
  let newStr = "";
  newStr += str[0].toUpperCase();
  newStr += str.slice(1, str.length);
  return newStr;
};

const getIcon = (type: string) => {
  switch (type) {
    case "success":
      return <CheckCircleOutlined style={{
        fontSize: "32px",
        color: "#52c41a",
        marginBottom: "12px"
      }}/>;
    case "warn":
      return <ExclamationCircleOutlined style={{
        fontSize: "32px",
        color: "#faad14",
        marginBottom: "12px"
      }}/>;
    case "error":
      return <CloseCircleOutlined style={{
        fontSize: "32px",
        color: "#ff4d4f",
        marginBottom: "12px"
      }}/>;
    case "total":
      return <PlusCircleOutlined style={{
        fontSize: "32px",
        color: "#1890ff",
        marginBottom: "12px"
      }}/>;
  }
};

const getLink = (type: string) => {
  switch (type) {
    case "total":
      return "/monitoring";
    case "success":
      return "/monitoring?log_level=200";
    case "warn":
      return "/monitoring?log_level=300";
    case "error":
      return "/monitoring?log_level=400";
    case "default":
      return "/";
  }
};
