import axios from 'axios'

import baseUrl from './baseurl'
import { getToken } from './../token'
import { ModalTableState } from '../../modules/modaltable'

export const getList = async (
  path: string,
  param: URLSearchParams,
  search?: string,
  page?: number | string,
) => {
  if (path !== '/context_param') {
    if (!param.has('page')) param.append('page', '1')
    if (!param.has('range')) param.append('range', '20')
  }

  if (search) param.append('search', search)
  if (page) {
    param.set('range', '10')
    param.set('page', `${page}`)
  }

  const response = await axios.get<ModalTableState>(`${baseUrl}${path}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    params: param,
  })

  return response
}

export const getDetail = async (path: string, index: string | number) => {
  const response = await axios.get(`${baseUrl}${path}/${index}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })
  return response
}
// 여기서 detail을 위한 값을 받아온다
// basic, job 나눠서 들고온다

export const checkSchedule = async (index: number | string) => {
  const response = await axios.get(`${baseUrl}/job/schedule/${index}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  return response
}

export const deleteData = async (path: string, index: string | number) => {
  const response = await axios.delete(`${baseUrl}${path}/${index}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  return response
}

export const editData = async (
  path: string,
  index: string | number,
  data: { [key: string]: any },
) => {
  const response = await axios.put(`${baseUrl}${path}/${index}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  })

  return response
}
