import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AuditLogPage from "./AuditLogPage";

const Audit: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AuditLogPage} />
    </Switch>
  );
};

export default Audit;
