import { useCallback } from 'react'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'

import { decreaseState, increaseState, occurError, changeStatusProcess } from '../modules/step'
import { RootState } from '../modules'

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector

export default function useStep() {
  const dispatch = useDispatch()

  const onClickNext = useCallback(() => {
    dispatch(increaseState())
    dispatch(changeStatusProcess())
  }, [dispatch])
  const onClickPrev = useCallback(() => {
    dispatch(decreaseState())
    dispatch(changeStatusProcess())
  }, [dispatch])
  const onClickDone = useCallback(() => {
    dispatch(increaseState())
  }, [dispatch])

  const status = typedUseSelector((state: RootState) => state.step.status)
  const handleError = () => {
    dispatch(occurError())
  }

  return {
    onClickNext,
    onClickPrev,
    status,
    onClickDone,
    handleError,
  }
}
