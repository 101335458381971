import * as React from "react";

import useJSONViewer from "../../hooks/JSONViewer/useJSONViewer";
import JSONViewerObject from "./JSONViewerObject";
import JSONViewerProperty from "./JSONViewerProperty";

import "./../../lib/styles/detail.scss";

const getNullString = (value: any) => {
  switch (value) {
    case null:
      return "null";
    case "":
      return "null";
    default:
      return `${value}`;
  }
};

const isValueObject = (param: object) => {
  return Object.entries(param).map((property) => {
    if (typeof property[1] === "object" && property[1] !== null) {
      return (
        <JSONViewerObject
          heading={property[0]}
          value={isValueObject(property[1])}
        />
      );
    } else {
      return (
        <JSONViewerProperty
          keyName={property[0]}
          value={getNullString(property[1])}
        />
      );
    }
  });
};

const JSONViewer: React.FC = () => {
  const { jsonState } = useJSONViewer();

  return <div className="json-value-container">{isValueObject(jsonState)}</div>;
};

export default JSONViewer;
