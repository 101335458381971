import React, { FC, useState } from 'react'
import { Result } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import { Button } from '@madup-inc/mad-design'
import useGoogleOneTap from '../../hooks/useGoogleOneTap'
import signInUrl from '../../constants/signInUrl'
import jwt from 'jwt-decode'
import { initUserInfo, login, updateUserInfo } from '../../modules/user'
import { useDispatch } from 'react-redux'
import { postUserInfo } from '../../lib/api/auth'
import { setToken } from '../../lib/token'
import useError from '../../hooks/useError'
import { useHistory } from 'react-router-dom'

const Auth: FC = () => {
  const dispatch = useDispatch()
  const { handleError } = useError()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const history = useHistory()

  useGoogleOneTap({
    callback: async ({ credential: accessToken }) => {
      // console.log('googleOneTapLogin accessToken:', accessToken)

      const userInfo: any = jwt(accessToken)

      // console.log('userInfo', userInfo)

      dispatch(updateUserInfo(userInfo.name, userInfo.picture))

      try {
        setIsLoading(true)
        const response = await postUserInfo({
          name: userInfo.name,
          id: userInfo.email,
          password: userInfo.sub,
        })
        dispatch(login())
        setToken(response.data.access_token)
      } catch (e) {
        handleError(e)
        dispatch(initUserInfo())
      } finally {
        setIsLoading(false)
      }

      history.replace('/')
    },
  })

  return (
    <>
      <Result
        status="403"
        title="Access Denied"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <a href={signInUrl}>
            <Button>
              <GoogleOutlined />
              Sign in with Google
            </Button>
          </a>
        }
      />
    </>
  )
}

export default Auth
