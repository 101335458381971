// Actions
const UPDATE_USER_INFO = "user/UPDATE_USER_INFO" as const;
const INIT_USER_INFO = "user/INIT_USER_INFO" as const;
const LOGIN = "user/LOGIN" as const;

// Action type
type UserAction =
  | ReturnType<typeof updateUserInfo>
  | ReturnType<typeof initUserInfo>
  | ReturnType<typeof login>;

// State Type & Initial State
type UserState = {
  name: string;
  picture: string;
  isLogin: boolean;
};

const initialState: UserState = {
  name: "",
  picture: "",
  isLogin: false
};

// Reducer
const user = (state: UserState = initialState, action: UserAction) => {
  switch (action.type) {
    case UPDATE_USER_INFO:
      return {
        ...state,
        name: action.payload.name,
        picture: action.payload.picture
      };
    case INIT_USER_INFO:
      return initialState;
    case LOGIN:
      return {
        ...state,
        isLogin: true
      };
    default:
      return state;
  }
};

export default user;

// Action Creators
export const updateUserInfo = (name: string, picture: string) => ({
  type: UPDATE_USER_INFO,
  payload: {
    name,
    picture
  }
});

export const initUserInfo = () => ({
  type: INIT_USER_INFO
});

export const login = () => ({
  type: LOGIN
});
