// Actions
const INIT_DETAIL = "Index/INIT_DETAIL" as const;
const LOAD_DETAIL = "Index/LOAD_DETAIL" as const;
const UPDATE_DETAIL = "Index/UPDATE_DETAIL" as const;

// Action Creators
export const initDetail = () => ({ type: INIT_DETAIL });

export const loadDetail = (payload: { [key: string]: any }) => ({
  type: LOAD_DETAIL,
  payload: payload,
});

export const updateDetail = (payload: {
  sectionName: string;
  name: string;
  value: string;
}) => ({
  type: UPDATE_DETAIL,
  payload: payload,
});

// Actions Type
type DetailAction =
  | ReturnType<typeof initDetail>
  | ReturnType<typeof loadDetail>
  | ReturnType<typeof updateDetail>;

// State Type & Initial State
type DetailState = {
  [key: string]: any;
};

const initialState: DetailState = {};

// Reducer
const detail = (state: DetailState = initialState, action: DetailAction) => {
  switch (action.type) {
    case LOAD_DETAIL:
      return action.payload;
    case INIT_DETAIL:
      return initialState;
    case UPDATE_DETAIL:
      let newForm = { ...state };

      newForm[action.payload.sectionName][action.payload.name] =
        action.payload.value;

      return newForm;
    default:
      return state;
  }
};

export default detail;
