import React, { FC } from "react";

import useScheduleAPI from "../../../hooks/schedule/scheduleCreate/useScheduleAPI";
import ScheduleForm from "../ScheduleForm";
import ScheduleEditButtons from "./ScheduleEditButtons";

const ScheduleEdit: FC = () => {
  const { submitEditedSchedule, loading } = useScheduleAPI();

  return (
    <ScheduleForm
      SubmitButton={<ScheduleEditButtons loading={loading} />}
      onFinish={submitEditedSchedule}
    />
  );
};

export default ScheduleEdit;
