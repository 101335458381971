import * as React from "react";

import { Modal as ModalComponent } from "antd";

type ModalProps = {
  title: string | null;
  visible: boolean;
  notClosable?: true;
  handleOK?: () => void;
  handleCancel?: () => void;
  width?: number;
  okText?: string;
  footer?: null;
  centered?: true;
};

const Modal: React.FC<ModalProps> = ({
  title,
  visible,
  notClosable,
  handleOK,
  handleCancel,
  children,
  width,
  okText,
  footer,
  centered
}) => {
  return (
    <ModalComponent
      centered={centered}
      closable={!notClosable}
      title={title}
      visible={visible}
      onOk={handleOK && (() => handleOK())}
      onCancel={handleCancel && (() => handleCancel())}
      width={width}
      okText={okText}
      footer={footer}
    >
      {children}
    </ModalComponent>
  );
};

export default Modal;
