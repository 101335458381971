import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getSchedule } from "../../../lib/api/schedule";
import useError from "../../useError";

type ScheduleDetail = {
  index: string | number;
  job_name: string;
  scheduled_time: string;
  expired_time: string;
  status: string;
  parsed_period: string;
  created_time: string;
  modified_time: string;
  [key: string]: any;
};

export default function useScheduleDetail() {
  const { scheduleID } = useParams();
  const { handleError } = useError();
  const initialState = {
    index: scheduleID,
    job_name: "",
    scheduled_time: "",
    expired_time: "",
    status: "",
    parsed_period: "",
    created_time: "",
    modified_time: "",
  };
  const [scheduleInfo, setScheduleInfo] = useState<ScheduleDetail>(
    initialState
  );

  const getScheduleData = async () => {
    try {
      const response = await getSchedule(scheduleID);
      setScheduleInfo(response.data);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    getScheduleData();
  }, []);

  return { scheduleInfo };
}
