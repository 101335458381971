import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "antd";

const { Title } = Typography;

type ContextDetailJobListProps = {
  data: {
    job_id: string;
    job_name: string;
  }[];
};

const TableDetailJobList: FC<ContextDetailJobListProps> = ({ data }) => {
  const history = useHistory();

  return (
    <>
      <Title level={4} style={{ fontSize: "16px" }}>Reference</Title>
      <ul style={{ marginTop: "20px" }}>
        {data.map((item) => (
          <li
            style={{ cursor: "pointer", marginBottom: "12px" }}
            onClick={() => history.push(`/job/${item.job_id}`)}
          >
            {item.job_name}
          </li>
        ))}
      </ul>
    </>
  );
};

export default TableDetailJobList;
