import React, { FC } from "react";

import { Input, Form } from "antd";

type TemplateFormInputProps = {
  field: any;
  name: string;
  required: boolean;
  desc: string;
  editable: boolean;
};

const TemplateFormInput: FC<TemplateFormInputProps> = ({
  field,
  name,
  required,
  desc,
  editable,
}) => (
  <Form.Item
    {...field}
    label={name}
    name={[field.name, name]}
    fieldKey={[field.fieldKey, name]}
    rules={[{ required: required, message: `Missing ${name}` }]}
  >
    <Input placeholder={desc} disabled={!editable} />
  </Form.Item>
);

export default TemplateFormInput;
