import React, { FC } from "react";
import moment from "moment";

import { InputNumber, TimePicker, Form } from "antd";

const InputPeriodMonth: FC = () => (
  <>
    <p style={{ opacity: 0.5, marginBottom: "8px", fontSize: "12px" }}>
      <strong>매월 0일</strong>로 설정하면 매일 실행됩니다.
    </p>
    매월{" "}
    <Form.Item noStyle name={["period", "value", "date"]}>
      <InputNumber
        min={0}
        max={31}
        defaultValue={1}
        style={{ width: "60px" }}
      />
    </Form.Item>{" "}
    일{" "}
    <Form.Item noStyle name={["period", "value", "time"]}>
      <TimePicker
        format="HH:mm"
        defaultValue={moment("00:00", "HH:mm")}
        allowClear={false}
      />
    </Form.Item>
  </>
);

export default InputPeriodMonth;
