import React, { FC } from "react";

import { Pagination } from "antd";

import useTablePagination from "../../hooks/table/useTablePagination";

const TablePagination: FC = () => {
  const { total, range, page, handlePaginationChange } = useTablePagination();

  return (
    <Pagination
      total={total}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      pageSize={range}
      current={page}
      pageSizeOptions={["10", "20", "30", "50", "70", "100"]}
      style={{
        textAlign: "center",
        marginTop: "48px"
      }}
      onChange={(page, pageSize) => {
        handlePaginationChange(page, pageSize);
      }}
    />
  );
};
export default TablePagination;
