import React from 'react'

import TableSearchFilter from '../../components/Table/TableSearchFilter'
import { Table } from './type'
import { convertEmptyString, convertEmailToId, formatTime } from '../../utils/tableDataRenderer'

export default {
  path: '/audit',
  detail: 'expand',
  pagination: false,
  columns: [
    {
      title: 'Index',
      dataIndex: 'idx',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="index" placeholder="Search Index" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: '5%',
    },
    {
      title: 'Log Time',
      dataIndex: 'log_time',
      render: text => formatTime(text),
      filterDropdown: <TableSearchFilter name="log_time" placeholder="Search Log Time" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: '10%',
    },
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="service_type" placeholder="Search Service Type" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: '15%',
    },
    {
      title: 'Argo ID',
      dataIndex: 'argo_id',
      render: text => <>{convertEmailToId(text)}</>,
      filterDropdown: <TableSearchFilter name="argo_id" placeholder="Search Argo ID" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: '9%',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="data" placeholder="Search Data" />,
    },
  ],
} as Table
