import React, { FC } from "react";

import { Form, Radio } from "antd";

type TemplateFormTypeSelectProps = {
  field: any;
  name: string;
  required: boolean;
  editable: boolean;
  defaultValue?: string;
};

const TemplateFormTypeSelect: FC<TemplateFormTypeSelectProps> = ({
  field,
  name,
  required,
  editable,
  defaultValue,
}) => (
  <Form.Item
    {...field}
    label={name}
    name={[field.name, name]}
    fieldKey={[field.fieldKey, name]}
    rules={[{ required: required, message: `Missing ${name}` }]}
    initialValue={defaultValue}
  >
    <Radio.Group disabled={!editable}>
      <Radio.Button value="text">Text</Radio.Button>
      <Radio.Button value="pop_up">Popup</Radio.Button>
    </Radio.Group>
  </Form.Item>
);

export default TemplateFormTypeSelect;
