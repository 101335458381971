import React, { FC } from "react";

import { Input, Form } from "antd";

const cronRegExp = /^((?:\*|[0-5]?[0-9](?:(?:-[0-5]?[0-9])|(?:,[0-5]?[0-9])+)?)(?:\/[0-9]+)?)\s+((?:\*|(?:1?[0-9]|2[0-3])(?:(?:-(?:1?[0-9]|2[0-3]))|(?:,(?:1?[0-9]|2[0-3]))+)?)(?:\/[0-9]+)?)\s+((?:\*|(?:[1-9]|[1-2][0-9]|3[0-1])(?:(?:-(?:[1-9]|[1-2][0-9]|3[0-1]))|(?:,(?:[1-9]|[1-2][0-9]|3[0-1]))+)?)(?:\/[0-9]+)?)\s+((?:\*|(?:[1-9]|1[0-2])(?:(?:-(?:[1-9]|1[0-2]))|(?:,(?:[1-9]|1[0-2]))+)?)(?:\/[0-9]+)?)\s+((?:\*|[0-7](?:-[0-7]|(?:,[0-7])+)?)(?:\/[0-9]+)?)$/;

const InputPeriodCustom: FC = () => (
  <>
    <p style={{
      opacity: 0.5,
      marginBottom: "4px",
      fontSize: "12px"
    }}>
      실행 주기를 크론탭 형식으로 직접 입력해 주세요. 글자 사이에는 띄어쓰기를
      반드시 해 주세요.
    </p>
    <Form.Item noStyle name={["period", "value"]}
               rules={[{
                 pattern: new RegExp(cronRegExp),
                 message: "Invalid Crontap format."
               }]}>
      <Input
        style={{
          width: "50%",
          backgroundColor: "rgb(245 247 249)",
          color: "rgb(136 153 179)",
        }}
      />
    </Form.Item>
  </>
);

export default InputPeriodCustom;
