import { TypedUseSelectorHook, useSelector } from "react-redux";

import { RootState } from "../../../modules";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useJobEditTemplate() {
  return typedUseSelector(
    (state: RootState) => state.createJob.template.template
  );
}
