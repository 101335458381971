import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "antd";

import "./style.scss";
import ReportJobSettingForm from "../ReportJobSettingForm";
import { postReport, ReportField } from "../../../lib/api/report";
import useError from "../../../hooks/useError";

type ReportJobSettingProps = {
  channelID: number | string;
  setStep: (step: number) => void;
  reportField: ReportField;
  ownerID: string;
  reportInfo: { name: string; type: string };
};

const ReportJobSetting: FC<ReportJobSettingProps> = ({
  setStep,
  reportField,
  ownerID,
  channelID,
  reportInfo,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { handleError } = useError();
  const [loading, setLoading] = useState(false);
  const createNewReport = async report => {
    setLoading(false);
    try {
      const newReport = {
        channel_id: channelID,
        report_type: reportInfo.type,
        name: reportInfo.name,
        owner_id: ownerID,
        ...report,
      };
      await postReport(newReport);
      history.push("/report");
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="report-job-wrapper">
      <Form
        form={form}
        onFinish={e => {
          console.log(e);
        }}
      >
        <ReportJobSettingForm field={reportField} />
        <div className="report-button-wrapper">
          <Button
            onClick={() => {
              setStep(1);
            }}
          >
            Prev
          </Button>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                createNewReport(form.getFieldsValue());
              }}
              htmlType="submit"
              loading={loading}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </section>
  );
};

export default ReportJobSetting;
