import axios from 'axios'

import baseurl from './baseurl'
import { getToken } from '../token'

export type ReportField = {
  [key: string]: Array<Field>
}

export type ReportEditField = ReportField & { sub_report_type?: string }

export type Reports = {
  data: Array<Report>
}

type Channels = {
  total: number
  data: Array<Channel>
}

export type Channel = { id: number; name: string; channel_info: Array<ChannelInfo> }

type ChannelInfo = {
  channel_id: number
  report_desc: string
  report_group: string
  report_name: string
  sub_report_type: string
  report_type: string
}

export type Field = {
  name: string
  label: string
  value: string | Array<string>
  desc: string
  required: boolean
  column?: Array<string>
}

type Report = {
  is_prism: boolean
  id: number
  report_name: string
  is_registered: boolean
}

export const getField = async ({ channelName, reportType }) =>
  await axios.get<ReportField>(`${baseurl}/report/input_field/${channelName}/${reportType}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getEditField = async reportID =>
  await axios.get<ReportEditField>(`${baseurl}/report/${reportID}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const putEditedReport = async ({ report, reportID }) =>
  await axios.put(`${baseurl}/report/${reportID}`, report, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getReports = async ({ ownerID }) => {
  return await axios.get<Reports>(`${baseurl}/report${ownerID ? `?owner_id=${ownerID}` : ''}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })
}

export const postReport = async report =>
  await axios.post(`${baseurl}/report`, report, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getReportOwnerIDs = async () =>
  await axios.get(`${baseurl}/report/owner_id_list`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getChannels = async () =>
  await axios.get<Channels>(`${baseurl}/report/channel_list`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const deleteReport = async reportID =>
  await axios.delete(`${baseurl}/report`, {
    data: [reportID],
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const getLogs = async ({ jobID, ownerID }: { jobID?: string; ownerID: string }) =>
  await axios.get(`${baseurl}/report/logs/${ownerID}${jobID ? `?job_id=${jobID}` : ''}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })

export const postReportRegister = async (reportID: number) =>
  await axios.post(`${baseurl}/report/register?report_id=${reportID}`, undefined, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const postRunReport = async (reportID, runType?: 'query') =>
  await axios.post(
    `${baseurl}/report/run?report_id=${reportID}${runType ? `&run_type=${runType}` : ''}`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  )

export const postRunPrismReport = async reportID =>
  await axios.post(`${baseurl}/report/prism/run?report_id=${reportID}`, undefined, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  })

export const postRecollectPrismReport = async (reportID, from, to) =>
  await axios.post(
    `${baseurl}/report/prism/re_collect?report_id=${reportID}&from_date=${from}&to_date=${to}`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  )
