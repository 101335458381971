import * as React from "react";

import { Form, Row, Spin } from "antd";

import useScheduleForm
  from "../../hooks/schedule/scheduleCreate/useScheduleForm";
import ScheduleFormValue from "../../constants/schedule/form";
import Input from "../Input";

type ScheduleFormProps = {
  SubmitButton: React.ReactElement;
  onFinish: (value: object) => void;
};

const ScheduleForm: React.FC<ScheduleFormProps> = ({
  SubmitButton,
  onFinish,
}) => {
  const { fields, onFieldsChange, getFormatValue, loading } = useScheduleForm();

  return (
    <Spin spinning={loading}>
      <section style={{ padding: "0 8px" }}>
        <Form
          layout={"vertical"}
          form={fields}
          onFinish={(schedule) => {
            onFinish(getFormatValue(schedule));
          }}
        >
          <Row gutter={16}>
            {ScheduleFormValue().map((item) => (
              <Input
                name={item.name}
                desc={item.desc}
                required={item.required}
                editable={item.editable}
                type={item.type}
                onFieldsChange={onFieldsChange}
                getFieldValue={fields.getFieldValue}
                options={item.options}
              />
            ))}
          </Row>
          {SubmitButton}
        </Form>
      </section>
    </Spin>
  );
};

export default ScheduleForm;
