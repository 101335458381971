import * as React from 'react'

import Table from './../Table'

import { sourcecodeTable } from './../../constants/table'
import useModalForm from '../../hooks/context/useModalForm'
import { Button, Icon } from '@madup-inc/mad-design'
import ModalForm from '../Modal/ModalForm'
import sourcecode from '../../constants/form/sourcecode'

const Sourcecode: React.FC = () => {
  const { isVisible, setIsVisible, fields, onClickCreate } = useModalForm(sourcecodeTable.path)

  return (
    <>
      <div
        style={{
          marginBottom: '24px',
          textAlign: 'right',
        }}
      >
        <Button
          type="dashed"
          ghost
          onClick={() => {
            setIsVisible(true)
          }}
        >
          <Icon type="add" />
          Create
        </Button>
      </div>
      <Table table={sourcecodeTable} />
      <ModalForm
        form={sourcecode}
        visible={isVisible}
        handleOK={() => {
          onClickCreate()
        }}
        handleCancel={() => {
          setIsVisible(false)
        }}
        fields={fields}
        title="Create Sourcecode"
        okText="Create"
      />
    </>
  )
}

export default Sourcecode
