import React, { FC } from "react";

import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Card, Typography } from "antd";

import useDashboard from "../../../hooks/dashboard/useDashboard";

const { Title } = Typography;

const DashboardJobExecutionGraph: FC = () => {
  const { data } = useDashboard();

  return (
    <Card>
      <Title level={4}>Job Execution Graph</Title>
      {data && (
        <ResponsiveContainer width={"100%"} height={350}>
          <LineChart data={data.graph}>
            <Legend />
            <Line dataKey="total" stroke="#1890ff" dot={false} />
            <Line dataKey="success" stroke="#52c41a" dot={false} />
            <Line dataKey="warn" stroke="#faad14" dot={false} />
            <Line dataKey="error" stroke="#ff4d4f" dot={false} />
            <CartesianGrid stroke="#ccc" strokeDasharray="1 5" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default DashboardJobExecutionGraph;
