import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { getList } from "../../lib/api/listTable";
import { initTable, setTable } from "../../modules/table";
import useError from "../useError";

export default function useTableAPI(
  path: string,
  keyword?: string
)
{
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const param = new URLSearchParams(location.search);
  const { handleError } = useError();

  const fetchTableData = async (page?: number) => {
    try {
      setLoading(true);

      if (location.pathname === "/schedule/create" || location.pathname === "/schedule/edit") {
        const response = await getList(
          path,
          param,
          keyword,
          page
        );
        dispatch(setTable(response.data));
      } else {
        const response = await getList(
          path,
          param,
        );
        dispatch(setTable(response.data));
      }
    } catch (e) {
      dispatch(initTable());
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    () => () => {
      dispatch(initTable());
    },
    []
  );

  useEffect(() => {
    (async () => await fetchTableData())();
  }, [location.search]);

  const onSearch = async () => {
    try {
      setLoading(true);
      await fetchTableData();
      setLoading(false);
    } catch (e) {
      handleError(e);
    }
  };

  const onPageChange = async (page: number) => {
    try {
      setLoading(true);
      await fetchTableData(page);
      setLoading(false);
    } catch (e) {
      handleError(e);
    }
  };

  return {
    fetchTableData,
    loading,
    onSearch,
    onPageChange
  };
}
