import React, { FC } from "react";

import { Form, Button, Row, Col, Typography, Divider } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import TemplateFormInput from "./TemplateFormInput";
import TemplateFormSwitch from "./TemplateFormSwitch";
import TemplateFormPopup from "./TemplateFormPopup";
import TemplateFormTypeSelect from "./TemplateFormTypeSelect";
import { FormInstance } from "antd/lib/form";
import { SectionName } from "../../../modules/createJob/form";

const { Title } = Typography;

type TemplateFormDynamicFieldProps = {
  isDetail?: boolean;
  sectionName: SectionName;
  title: string;
  form: FormInstance;
};

const TemplateFormDynamicField: FC<TemplateFormDynamicFieldProps> = ({
  sectionName,
  title,
  isDetail,
  form,
}) => (
  <>
    <Divider />
    <Title level={3}>{title}</Title>
    <Form.List name={sectionName}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[24, 40]}>
            {fields.map(field => {
              return (
                <>
                  <Col span={11}>
                    <TemplateFormInput
                      field={field}
                      name="name"
                      required={true}
                      editable={!isDetail && true}
                      desc="필드의 이름을 입력해 주세요."
                    />
                    <TemplateFormInput
                      field={field}
                      name="desc"
                      required={true}
                      editable={!isDetail && true}
                      desc="필드에 대한 설명을 입력해 주세요."
                    />
                    {sectionName === "context_param" ? (
                      <TemplateFormPopup
                        field={field}
                        name="value"
                        required={false}
                        editable={!isDetail && true}
                        desc="필드의 기본값을 입력해 주세요."
                        form={form}
                        sectionName={sectionName}
                      />
                    ) : (
                      <TemplateFormInput
                        field={field}
                        name="value"
                        required={false}
                        editable={!isDetail && true}
                        desc="필드의 기본값을 입력해 주세요."
                      />
                    )}
                    <Row>
                      <Col flex={1}>
                        <TemplateFormSwitch
                          field={field}
                          name="required"
                          required={true}
                          editable={!isDetail && true}
                          desc="필드 값 입력 필수 여부를 입력해 주세요."
                        />
                      </Col>
                      <Col flex={1}>
                        <TemplateFormSwitch
                          field={field}
                          name="editable"
                          required={true}
                          editable={!isDetail && true}
                          desc="필드 값 수정 가능 여부를 입력해 주세요."
                        />
                      </Col>
                      <Col flex={6}>
                        <TemplateFormTypeSelect
                          field={field}
                          name="type"
                          required={true}
                          editable={false}
                          defaultValue={sectionName === "context_param" ? "pop_up" : "text"}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {isDetail || (
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Col>
                  )}
                </>
              );
            })}
          </Row>
          {isDetail || (
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  </>
);

export default TemplateFormDynamicField;
