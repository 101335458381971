// Actions
const INPUT_SEARCH = "popup/INPUT_SEARCH" as const;
const UPDATE_ACTIVE = "popup/UPDATE_ACTIVE" as const;
const UPDATE_DATA = "popup/UPDATE_DATA" as const;
const OPEN_POPUP = "popup/OPEN_POPUP" as const;
const CLOSE_POPUP = "popup/CLOSE_POPUP" as const;
const INIT_POPUP = "popup/INIT_POPUP" as const;

// Actions Type
type PopupAction =
  | ReturnType<typeof inputSearch>
  | ReturnType<typeof updateActive>
  | ReturnType<typeof updateData>
  | ReturnType<typeof openPopup>
  | ReturnType<typeof closePopup>
  | ReturnType<typeof initPopup>;

// State Type & Initial State
type PopupState = {
  sectionName: "basic_info" | "context_param" | "job_param";
  isOpen: boolean;
  search: string;
  active: string;
  label: string;
  data: { [key: string]: any }[];
};

const initialState: PopupState = {
  sectionName: "basic_info",
  isOpen: false,
  search: "",
  active: "",
  label: "label",
  data: []
};

// Reducer
const popup = (state: PopupState = initialState, action: PopupAction) => {
  switch (action.type) {
    case INPUT_SEARCH:
      return {
        ...state,
        search: action.payload
      };
    case UPDATE_ACTIVE:
      return {
        ...state,
        active: action.payload
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: action.payload
      };
    case OPEN_POPUP:
      return {
        ...state,
        sectionName: action.payload.sectionName,
        isOpen: true,
        search: "",
        active: action.payload.active,
        label: action.payload.label,
        data: action.payload.data
      };
    case CLOSE_POPUP:
      return {
        ...state,
        isOpen: false
      };
    case INIT_POPUP:
      return initialState;
    default:
      return state;
  }
};

export default popup;

// Action Creators
export const inputSearch = (payload: string) => ({
  type: INPUT_SEARCH,
  payload: payload
});

export const updateActive = (payload: string) => ({
  type: UPDATE_ACTIVE,
  payload: payload
});

export const updateData = (payload: { [key: string]: any }[]) => ({
  type: UPDATE_DATA,
  payload: payload
});

export const openPopup = (payload: {
  sectionName: "basic_info" | "context_param" | "job_param" ;
  active: string;
  label: string;
  data: { [key: string]: any }[];
}) => ({
  type: OPEN_POPUP,
  payload: payload
});

export const closePopup = () => ({
  type: CLOSE_POPUP
});

export const initPopup = () => ({
  type: INIT_POPUP
});
