import React, { FC } from "react";

import { Row, Col } from "antd";

import DashboardStatusCard from "./DashboardStatusCard";
import useDashboard from "../../../hooks/dashboard/useDashboard";
import DashboardServerStatusCard from "./DashboardServerStatusCard";

const DashboardStatus: FC = () => {
  const { data } = useDashboard();

  return (
    <Row gutter={24} style={{ height: "100%" }}>
      {data && (
        <Col flex={"1 1 4.8%"}>
          <DashboardServerStatusCard serverStatus={data.server_status} />
        </Col>
      )}
      {data &&
        Object.entries(data.status).map(item => {
          return (
            <Col flex={"1 1 4.8%"}>
              <DashboardStatusCard type={item[0]} number={item[1]} />
            </Col>
          );
        })}
    </Row>
  );
};
export default DashboardStatus;
