import * as React from "react";

import { Button, Form } from "antd";

type TemplateFormButtonProps = {
  loading: boolean;
};

const TemplateFormButton: React.FC<TemplateFormButtonProps> = ({ loading }) => {
  return (
    <div style={{ float: "right" }}>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create
        </Button>
      </Form.Item>
    </div>
  );
};

export default TemplateFormButton;
