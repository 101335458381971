import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Result } from 'antd'
import { Button } from '@madup-inc/mad-design'

type JobCreateCompleteProps = {
  job: string
}

const JobCreateComplete: FC<JobCreateCompleteProps> = ({ job }) => {
  const history = useHistory()

  const onScheduleClick = () => {
    history.push(`/schedule/create?job=${job}`)
  }

  const onJobClick = () => {
    history.push('/job')
  }

  return (
    <div>
      <Result
        status="success"
        title="Successfully has been created Job!"
        subTitle={`Job ID: ${job ? job.split(' (')[1].split(')')[0] : ''}`}
        extra={[
          <Button type="primary" onClick={() => onScheduleClick()} style={{ marginRight: 10 }}>
            Schedule 등록
          </Button>,
          <Button onClick={() => onJobClick()}>Job 목록</Button>,
        ]}
      />
    </div>
  )
}

export default JobCreateComplete
