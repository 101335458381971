import * as React from 'react'

import JobCreateSteps from './JobCreateSteps'
import JobCreateSelectTemplate from './JobCreateSelectTemplate'
import JobCreateForm from './JobCreateForm'
import useStep from '../../../hooks/useStep'
import JobCreatePreview from './JobCreatePreview'
import JobCreateComplete from './JobCreateResults/JobCreateComplete'
import useJobAPI from '../../../hooks/job/jobCreate/useJobAPI'

const JobCreate: React.FC = () => {
  const { current } = useStep()
  const { createJob, job } = useJobAPI()
  const [form, setForm] = React.useState<any>()

  const getCurrentComponent = () => {
    switch (current) {
      case 0:
        return <JobCreateSelectTemplate />
      case 1:
        return <JobCreateForm setForm={setForm} />
      case 2:
        return <JobCreatePreview createJob={() => createJob(form)} />
      case 3:
        return <JobCreateComplete job={job} />
      default:
        return 'not found'
    }
  }

  return (
    <>
      <JobCreateSteps />
      {getCurrentComponent()}
    </>
  )
}

export default JobCreate
