import React, { FC } from "react";

import { InputNumber, Form } from "antd";

const InputPeriodCycle: FC = () => (
  <div>
    <Form.Item noStyle name={["period", "value", "hour"]}>
      <InputNumber defaultValue={0} min={0} />
    </Form.Item>{" "}
    시간{" "}
    <Form.Item noStyle name={["period", "value", "min"]}>
      <InputNumber min={0} defaultValue={0} />
    </Form.Item>{" "}
    분 주기로 반복됩니다.
  </div>
);

export default InputPeriodCycle;
