import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Form, Modal, message } from 'antd'

import { getContextDetail, editContext } from '../../lib/api/context'
import { deleteData, editData, getDetail } from '../../lib/api/listTable'
import useError from '../useError'
import { Path } from '../../constants/detail/type'
import { getToken } from '../../lib/token'
import baseUrl from '../../lib/api/baseurl'

export default function useTableDetailButton(path: Path, title: string) {
  const [visible, setVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const location = useLocation()
  const history = useHistory()
  const [modal, contextHolder] = Modal.useModal()
  const index = location.pathname.split('/')[2]
  const [fields] = Form.useForm()
  const { handleError } = useError()

  const onDeleteClick = () => {
    modal.confirm({
      title: `Delete ${title}`,
      content: `Are you sure you want to delete this ${title}?`,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => deleteDetail(),
    })
  }

  const onEditClick = async () => {
    setIsLoading(true)
    try {
      if (location.pathname.split('/')[1] === 'job-context') {
        const response = (await getContextDetail(index)).data
        fields.setFieldsValue({
          key: response.key,
          value: response.value,
          desc: response.desc,
        })
      } else {
        const response = (await getDetail('/product', index)).data
        fields.setFieldsValue({
          product_id: response.product_id,
          owner_id: response.owner_id,
          product_name: response.product_name,
          industry: response.industry,
          desc: response.desc,
        })
      }
      setVisible(true)
    } catch (e) {
      handleError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const saveLink = `${baseUrl}/sourcecode/${index}/download?access_token=Bearer ${getToken()}`

  const onCustomJobClick = () => {
    history.push(`/template/create?source_id=${index}`)
  }

  const onEditSubmitClick = () => {
    fields.validateFields().then(values => {
      submitEditedContext(values).then(() => {})
      fields.resetFields()
    })
  }

  const submitEditedContext = async value => {
    setIsLoading(true)
    try {
      if (location.pathname.split('/')[1] === 'job-context') {
        await editContext(index, value)
        message.success('Successfully context has been edited!', undefined, () => {
          history.go(0)
        })
      } else {
        await editData('/product', index, value)
        message.success('Successfully product has been edited!', undefined, () => {
          history.go(0)
        })
      }
      setVisible(false)
    } catch (e) {
      handleError(e)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDetail = async () => {
    try {
      await deleteData(path, index)
      history.goBack()
      message.success(`Successfully ${title} has been deleted!`)
    } catch (e) {
      handleError(e)
    }
  }

  return {
    fields,
    onDeleteClick,
    contextHolder,
    visible,
    setVisible,
    onEditClick,
    isLoading,
    onEditSubmitClick,
    saveLink,
    onCustomJobClick,
  }
}
