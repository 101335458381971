import { message } from "antd";

export default function useError() {
  const handleError = (e: any) => {
    if (e.response) {
      if (e.response.data && e.response.data.detail) {
        message.error(`${e.response.data.detail}`);
      } else {
        message.error(`[Error Code: ${e.response.status}] ${e.response.statusText}`);
      }
    } else if (e.message) {
      message.error(`${e.message}`);
    } else {
      message.error("Error!");
      console.dir(e);
    }
  };

  return { handleError };
}
