import * as React from "react";

import { Spin } from "antd";

import JSONViewer from "../../JSONViewer";
import JobDetailButtons from "./JobDetailButtons";
import useDetail from "../../../hooks/detail/useDetail";
import useJobDelete from "../../../hooks/job/jobDetail/useJobDelete";
import ModalJobEdit from "../../Modal/ModalJobEdit";

const JobDetail: React.FC = () => {
  const { loading } = useDetail();
  const [visible, setVisible] = React.useState<boolean>(false);
  const { contextHolder, onDeleteClick } = useJobDelete();

  const onChangeVisible = (state: boolean) => {
    setVisible(state);
  };

  return (
    <Spin spinning={loading}>
      <JobDetailButtons
        setVisible={onChangeVisible}
        onDeleteClick={onDeleteClick}
      />
      <JSONViewer />
      <ModalJobEdit visible={visible} setVisible={onChangeVisible} />
      {contextHolder}
    </Spin>
  );
};

export default JobDetail;
