import React, { FC } from "react";

import TemplateForm from "../TemplateForm";
import useTemplateCreate from "../../../hooks/template/useTemplateCreate";
import useTemplateAPI from "../../../hooks/template/useTemplateAPI";
import TemplateFormButton from "../TemplateForm/TemplateFormButton";

const TemplateCreate: FC = () => {
  const { fields } = useTemplateCreate();
  const { submitNewTemplate, loading } = useTemplateAPI();

  return (
    <TemplateForm
      fields={fields}
      SubmitButton={<TemplateFormButton loading={loading} />}
      onFinish={submitNewTemplate}
      isLoading={false}
    />
  );
};

export default TemplateCreate;
