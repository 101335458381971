import { useSelector, TypedUseSelectorHook } from "react-redux";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";

import { RootState } from "../../modules";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTablePagination() {
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const { path } = useRouteMatch();
  const history = useHistory();

  const total = typedUseSelector((state: RootState) => state.table.total);

  const handlePaginationChange = (
    page: number,
    pageSize: number | undefined
  ) => {
    param.set("page", `${page}`);
    param.set("range", `${pageSize}`);
    history.push(`${path}?${param.toString()}`);
  };

  return {
    total,
    page: parseInt(`${
      param
        .get("page")
    }`) || 1,
    range: parseInt(`${
      param
        .get("range")
    }`) || 20,
    handlePaginationChange
  };
}
