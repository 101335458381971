import { Template } from "./form";

// Actions
const LOAD_GROUPS = "createJob/template/LOAD_GROUP" as const;
const CHANGE_GROUP = "createJob/template/CHANGE_GROUP" as const;
const CHANGE_TEMPLATE = "createJob/template/CHANGE_TEMPLATE" as const;
const LOAD_TEMPLATE = "createJob/template/LOAD_TEMPLATE" as const;
const INIT_TEMPLATE = "createJob/template/INIT_TEMPLATE" as const;

// Action Type
type TemplateAction =
  | ReturnType<typeof loadGroups>
  | ReturnType<typeof changeGroup>
  | ReturnType<typeof loadTemplate>
  | ReturnType<typeof changeTemplate>
  | ReturnType<typeof initTemplate>;

// State Type & Initial State
export type TemplateInfo = {
  template_id: string;
  group: string;
  desc: string;
};

type TemplateState = {
  group: string[];
  templateInfos: TemplateInfo[];
  template: Template;
  currentGroup: string;
  currentTemplate: number;
};

const initialState: TemplateState = {
  group: [],
  templateInfos: [
    {
      template_id: "",
      group: "",
      desc: "",
    },
  ],
  template: {
    template_id: "",
    basic_info: [],
    context_param: [],
    job_param: [],
  },
  currentGroup: "",
  currentTemplate: 0,
};

// Reducer
const template = (
  state: TemplateState = initialState,
  action: TemplateAction
) => {
  switch (action.type) {
    case LOAD_GROUPS:
      return {
        ...state,
        group: action.payload.groups,
        templateInfos: action.payload.templateInfos,
        currentGroup: action.payload.groups[0],
        currentTemplate: 0,
      };
    case CHANGE_GROUP:
      return {
        ...state,
        currentGroup: action.payload.group,
        currentTemplate: 0,
        templateInfos: action.payload.templateInfos,
      };
    case CHANGE_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      };
    case LOAD_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case INIT_TEMPLATE:
      return initialState;
    default:
      return state;
  }
};

export default template;

// Action Creators
export const loadGroups = (
  groups: string[],
  templateInfos: TemplateInfo[]
) => ({
  type: LOAD_GROUPS,
  payload: {
    groups,
    templateInfos
  },
});

export const changeGroup = (group: string, templateInfos: TemplateInfo[]) => ({
  type: CHANGE_GROUP,
  payload: {
    group,
    templateInfos
  },
});

export const changeTemplate = (payload: number) => ({
  type: CHANGE_TEMPLATE,
  payload: payload,
});

export const loadTemplate = (payload: Template) => ({
  type: LOAD_TEMPLATE,
  payload: payload,
});

export const initTemplate = () => ({
  type: INIT_TEMPLATE,
});
