import * as React from 'react'

import JobCreateButtonOnFirst from './JobCreateButtons/JobCreateButtonOnFirst'
import VerticalTabs from '../../VerticalTabs'
import JobCreateTemplateInfo from './JobCreateTemplateInfo'
import useTemplateGroups from '../../../hooks/job/jobCreate/useTemplateGroups'
import useTemplates from '../../../hooks/job/jobCreate/useTemplates'

const JobCreateSelectTemplate: React.FC = () => {
  const { groups, currentKey, onClickGroup } = useTemplateGroups()
  const { onClickTemplate, currentTemplate, templates } = useTemplates()

  return (
    <>
      <VerticalTabs
        currentGroup={currentKey}
        onClickGroup={onClickGroup}
        groups={groups.map(group => ({ label: group, value: group }))}
        currentItem={currentTemplate}
        onClickItem={onClickTemplate as any}
        items={templates.map((template, idx) => ({ label: template, value: idx }))}
      >
        <JobCreateTemplateInfo />
      </VerticalTabs>
      <JobCreateButtonOnFirst />
    </>
  )
}

export default JobCreateSelectTemplate
