import React, { FC } from "react";

import { Button, Form } from "antd";

type ScheduleCreateSubmitButtonProps = {
  loading: boolean;
};

const ScheduleCreateSubmitButton: FC<ScheduleCreateSubmitButtonProps> = ({
  loading,
}) => (
  <div style={{ float: "right" }}>
    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Create
      </Button>
    </Form.Item>
  </div>
);

export default ScheduleCreateSubmitButton;
