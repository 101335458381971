import { DetailWithRef } from "./type";

export default <DetailWithRef>{
  title: "Sourcecode",
  info: [
    {
      label: "Source ID",
      dataIndex: "source_id",
    },
    {
      label: "Argo ID",
      dataIndex: "argo_id",
    },
    {
      label: "Class Path",
      dataIndex: "class_path",
    },
    {
      label: "Created Time",
      dataIndex: "created_time",
    },
    {
      label: "Modified Time",
      dataIndex: "modified_time",
    },
    {
      label: "Description",
      dataIndex: "desc",
    },
  ],
  path: "/sourcecode",
};
