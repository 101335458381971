import { useLocation } from "react-router-dom";

export default () => {
  const location = useLocation();
  const classPath = `${
    location.search !== "" ? `custom:${location.search.split("source_id=")[1]}` : ""
  }`;

  const initialValues = {
    template_id: "",
    desc: "",
    basic_info: [
      {
        name: "job_name",
        value: "",
        editable: true,
        required: true,
        type: "text",
        desc: "생성할 Job의 이름을 설정합니다.",
      },
      {
        name: "class_path",
        value: classPath,
        editable: false,
        required: true,
        type: "text",
        desc: "고정값입니다.",
      },
      {
        name: "owner_id",
        value: "",
        editable: false,
        required: true,
        type: "pop_up",
        desc: "생성할 Job의 광고주를 ID를 선택합니다.",
      },
      {
        name: "product_id",
        value: "",
        editable: true,
        required: true,
        type: "pop_up",
        desc: "생성할 Job의 PRODUCT ID를 선택합니다.",
      },
      {
        name: "desc",
        value: "",
        editable: true,
        required: true,
        type: "text",
        desc: "생성할 Job의 설명을 적습니다.",
      },
    ],
    context_param: [],
    job_param: [],
  };

  return initialValues;
};
