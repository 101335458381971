import React, { FC } from "react";

import { Button, Form } from "antd";

import { EditOutlined } from "@ant-design/icons";

type JobEditButtonsProps = {
  fields: any;
  onClickGoBack: () => void;
  loading: boolean;
};

const JobEditButtons: FC<JobEditButtonsProps> = ({
  loading,
  onClickGoBack,
}) => {
  return (
    <section style={{ textAlign: "right" }}>
      <Button
        style={{ marginRight: "12px" }}
        onClick={() => {
          onClickGoBack();
        }}
      >
        Go Back
      </Button>
      <Form.Item style={{ display: "inline-block" }}>
        <Button
          type="primary"
          loading={loading}
          icon={<EditOutlined />}
          htmlType="submit"
        >
          Edit
        </Button>
      </Form.Item>
    </section>
  );
};

export default JobEditButtons;
