// Actions
const INCREASE_STATE = "step/INCREASE_STATE" as const;
const DECREASE_STATE = "step/DECREASE_STATE" as const;
const INIT_STATE = "step/INIT_STATE" as const;
const OCCUR_ERROR = "step/OCCUR_ERROR" as const;
const CHANGE_STATUS_PROCESS = "step/CHANGE_STATUS_PROCESS" as const;
const CHANGE_STATUS_FINISH = "step/CHANGE_STATUS_FINISH" as const;

// Action Type
type StepAction =
  | ReturnType<typeof increaseState>
  | ReturnType<typeof decreaseState>
  | ReturnType<typeof initState>
  | ReturnType<typeof occurError>
  | ReturnType<typeof changeStatusProcess>
  | ReturnType<typeof changeStatusFinish>;

// State Type & Initial State
export type Status = "wait" | "process" | "finish" | "error";

type StepState = {
  current: number;
  status: Status;
};

const initialState: StepState = {
  current: 0,
  status: "process",
};

// Reducer
const step = (state: StepState = initialState, action: StepAction) => {
  switch (action.type) {
    case INCREASE_STATE:
      return { ...state, current: state.current + 1 };
    case DECREASE_STATE:
      return { ...state, current: state.current - 1 };
    case INIT_STATE:
      return initialState;
    case OCCUR_ERROR:
      return { ...state, status: <Status>"error" };
    case CHANGE_STATUS_PROCESS:
      return { ...state, status: <Status>"process" };
    case CHANGE_STATUS_FINISH:
      return { ...state, status: <Status>"finish" };
    default:
      return state;
  }
};

export default step;

// Action Creators
export const increaseState = () => ({
  type: INCREASE_STATE,
});

export const decreaseState = () => ({
  type: DECREASE_STATE,
});

export const initState = () => ({
  type: INIT_STATE,
});

export const occurError = () => ({
  type: OCCUR_ERROR,
});

export const changeStatusProcess = () => ({
  type: CHANGE_STATUS_PROCESS,
});

export const changeStatusFinish = () => ({
  type: CHANGE_STATUS_FINISH,
});
