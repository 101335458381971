// Actions
const CHANGE_IS_COLLAPSED = "sidebar/CHANGE_IS_COLLAPSED" as const;

// Action Type
type SidebarAction = ReturnType<typeof changeIsCollapsed>;

// State Type & Initial State
type SidebarState = {
  isCollapsed: boolean;
};

const initialState: SidebarState = {
  isCollapsed: false,
};

// Reducer
const sidebar = (state: SidebarState = initialState, action: SidebarAction) => {
  switch (action.type) {
    case CHANGE_IS_COLLAPSED:
      return { isCollapsed: !state.isCollapsed };
    default:
      return state;
  }
};

export default sidebar;

// Action Creators
export const changeIsCollapsed = () => ({
  type: CHANGE_IS_COLLAPSED,
});
