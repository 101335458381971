import * as React from 'react'
import { Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { Button } from '@madup-inc/mad-design'
import { Result } from 'antd'

import Job from './Job'
import Auth from './AuthPage'
import Schedule from './Schedule'
import Audit from './Audit'
import Context from './Context'
import Dashboard from './DashboardPage'
import Monitoring from './MonitoringPage'
import Product from './Product'
import Template from './Template'
import Sourcecode from './Sourcecode'
import Report from './Report'
import { RootState } from '../modules'
import CustomLayout from '../components/Layout/CustomLayout'

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector

const Pages: React.FC = () => {
  const isLogin = typedUseSelector((state: RootState) => state.user.isLogin)
  const history = useHistory()

  return (
    <Switch>
      <Route exact path="/">
        {isLogin ? (
          <CustomLayout isDashboard={true}>
            <Dashboard />
          </CustomLayout>
        ) : (
          <Auth />
        )}
      </Route>
      <AuthRoute path="/job" component={Job} />
      <AuthRoute path="/schedule" component={Schedule} />
      <AuthRoute path="/audit" component={Audit} />
      <AuthRoute path="/job-context" component={Context} />
      <AuthRoute path="/monitoring" component={Monitoring} />
      <AuthRoute path="/product" component={Product} />
      <AuthRoute path="/template" component={Template} />
      <AuthRoute path="/sourcecode" component={Sourcecode} />
      <AuthRoute path="/report" component={Report} />
      <Route path="*">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() => {
                history.replace('/')
              }}
            >
              Back Home
            </Button>
          }
        />
      </Route>
    </Switch>
  )
}

export default Pages

const AuthRoute = ({ component: Component, ...rest }) => {
  const isLogin = typedUseSelector((state: RootState) => state.user.isLogin)

  return (
    <Route
      {...rest}
      render={props =>
        isLogin ? (
          <CustomLayout>
            <Component {...props} />
          </CustomLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}
