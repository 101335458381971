import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactJson from "react-json-view";

import { Table as TableComponent } from "antd";
import { Table as TableType } from "../../constants/table/type";
import TablePagination from "./TablePagination";
import useTable from "../../hooks/table/useTable";
import useTableSort from "../../hooks/table/useTableSort";
import useTableAPI from "../../hooks/table/useTableAPI";
import ModalDetail from "../Modal/ModalDetail";

type TableProps = {
  table: TableType;
};

const Table: FC<TableProps> = ({ table }) => {
  const history = useHistory();
  const { loading } = useTableAPI(table.path);
  const { handleSortChanged } = useTableSort();
  const data = useTable();
  const location = useLocation();

  return (
    <>
      <TableComponent
        size="small"
        columns={table.columns}
        dataSource={data}
        pagination={table.pagination}
        onChange={handleSortChanged}
        loading={loading}
        style={{ cursor: "pointer" }}
        onRow={(record, _rowIndex) => ({
          onClick: () => {
            if (table.detail && table.detail !== "expand") {
              if (Array.isArray(table.detail)) return history.push(`${table.path}/${record[table.detail[0]]}:${record[table.detail[1]]}`);
              if (table.path === "/context_param") return history.push(`/job-context/${record[`${table.detail}`]}`);
              if (table.path === "/monitoring") return history.push(`${table.path}/${record[table.detail]}`);
              return history.push(`${table.path}/${record[`${table.detail}`]}`);
            }
          },
        })}
        expandable={
          table.detail === "expand" ? {
            expandedRowRender: record => <ReactJson name={false}
                                                    enableClipboard={false}
                                                    displayDataTypes={false}
                                                    displayObjectSize={false}
                                                    style={{ wordBreak: "break-all" }}
                                                    src={record}/>
          } : undefined
        }
      />
      {table.path === "/monitoring" && <ModalDetail
          visible={!!location.pathname.split("monitoring/")[1]}
          handleOK={() => {
          }}
          handleCancel={() => {
            history.push("/monitoring");
          }}
      />}
      {table.pagination === false && <TablePagination/>}
    </>
  );
};

export default Table;
