import * as React from 'react'

import 'antd/dist/antd.css'
import '@madup-inc/mad-design/dist/index.css'
import { Spin } from 'antd'

import Pages from './pages'
import useOAuth from './hooks/auth/useOAuth'

const App: React.FC = () => {
  const { isLoading } = useOAuth()

  return (
    <Spin spinning={isLoading}>
      <Pages />
    </Spin>
  )
}

export default App
