import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ProductListPage from "./ProductListPage";
import ProductDetailPage from "./ProductDetailPage";

const Product: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ProductListPage} />
      <Route
        exact
        path={`${path}/:owner_product`}
        component={ProductDetailPage}
      />
    </Switch>
  );
};

export default Product;
