import React from "react";

import { Table } from "./type";
import TableSearchFilter from "../../components/Table/TableSearchFilter";
import { convertEmailToId } from "../../utils/tableDataRenderer";

export default {
  path: "/sourcecode",
  detail: "source_id",
  pagination: false,
  columns: [
    {
      title: "Source ID",
      dataIndex: "source_id",
      filterDropdown: <TableSearchFilter name="source_id" placeholder="Search Source ID" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: "10%",
    },
    {
      title: "Class Path",
      dataIndex: "class_path",
      filterDropdown: <TableSearchFilter name="class_path" placeholder="Search Class Path" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Description",
      dataIndex: "desc",
      filterDropdown: <TableSearchFilter name="desc" placeholder="Search Description" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Argo ID",
      dataIndex: "argo_id",
      render: text => <>{convertEmailToId(text)}</>,
      filterDropdown: <TableSearchFilter name="argo_id" placeholder="Search Argo ID" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
      width: "10%",
    },
  ],
} as Table;
