import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { Form, message } from "antd";

import { createNewData } from "../../lib/api/modal";
import useError from "../useError";
import { getList } from "../../lib/api/listTable";
import { setTable } from "../../modules/table";

export default (path: string) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [fields] = Form.useForm();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { handleError } = useError();

  const onClickCreate = () => {
    fields.validateFields().then(values => {
      createContext(values).then(() => {});
      fields.resetFields();
    });
  };

  const createContext = async values => {
    try {
      await createNewData(path, values);
      await fetchData();
      setIsVisible(false);
      message.success(
        `${location.pathname.split("/")[1]} has been successfully created!`,
        undefined,
      );
    } catch (e) {
      handleError(e);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getList(path, new URLSearchParams());
      dispatch(setTable(response.data));
    } catch (e) {
      handleError(e);
    }
  };

  return {
    isVisible,
    setIsVisible,
    fields,
    onClickCreate,
  };
};
