
// Actions
const OPEN_WARNING = "warning/OPEN_WARNING" as const;
const CLOSE_WARNING = "warning/CLOSE_WARNING" as const;

// Action Types
type WarningAction =
  | ReturnType<typeof openWarning>
  | ReturnType<typeof closeWarning>;

// State Type & Initial State
type WarningState = {
  msg: string;
  path: string;
  index: string | number;
  isOpen: boolean;
};

const initialState: WarningState = {
  msg: "warning",
  path: "",
  index: "",
  isOpen: false
};

// Reducer
const warning = (state: WarningState = initialState, action: WarningAction) => {
  switch (action.type) {
    case OPEN_WARNING:
      return {
        index: action.payload.index,
        path: action.payload.path,
        msg: action.payload.msg,
        isOpen: true
      };
    case CLOSE_WARNING:
      return initialState;
    default:
      return state;
  }
};

export default warning;

// Action Creators
export const openWarning = (msg: string, path: string, index: string|number) => ({
  type: OPEN_WARNING,
  payload: {msg: msg, path: path, index: index}
});

export const closeWarning = () => ({
  type: CLOSE_WARNING
});
