import { useState, useEffect } from "react";

export default function useModalTable(selectedKey, visible) {
  const [currentSelectedRow, setCurrentSelectedRow] = useState([]);
  const [currentSelectedKey, setCurrentSelectedKey] = useState([]);

  useEffect(() => {
    setCurrentSelectedKey(selectedKey);
  }, [visible]);

  const onRowChange = (selectedKeys, selectedRow) => {
    setCurrentSelectedRow(selectedRow);
    setCurrentSelectedKey(selectedKeys);
  };

  return { currentSelectedRow, currentSelectedKey, onRowChange };
}
