import React from 'react'

import TableSearchFilter from '../../components/Table/TableSearchFilter'
import TableSelectFilter from '../../components/Table/TableSelectFilter'
import { Table } from './type'
import {
  convertEmptyString,
  convertScheduleStatus,
  formatTime,
} from '../../utils/tableDataRenderer'

export default {
  path: '/schedule',
  detail: 'idx',
  pagination: false,
  columns: [
    {
      title: 'Index',
      dataIndex: 'idx',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="idx" placeholder="Search Index" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Job Name',
      dataIndex: 'job_name',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="job_name" placeholder="Search Job Name" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: text => convertScheduleStatus(text),
      filterDropdown: (
        <TableSelectFilter
          name="status"
          items={['enable', 'disable', 'wait', 'run', 'expired', 'fail']}
        />
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Period',
      dataIndex: 'period',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="period" placeholder="Search Period" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Scheduled Time',
      dataIndex: 'scheduled_time',
      render: text => formatTime(text),
      filterDropdown: (
        <TableSearchFilter name="scheduled_time" placeholder="Search Scheduled Time" />
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Expired Time',
      dataIndex: 'expired_time',
      render: text => formatTime(text),
      filterDropdown: <TableSearchFilter name="expired_time" placeholder="Search Expired Time" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Origin Scheduled Time',
      dataIndex: 'origin_scheduled_time',
      render: text => formatTime(text),
      filterDropdown: <TableSearchFilter name="origin_time" placeholder="Search Origin Time" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
  ],
} as Table
