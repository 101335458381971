import * as React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";

import { Breadcrumb as BreadcrumbComponent } from "antd";
import { HomeOutlined } from "@ant-design/icons";

const Breadcrumb: React.FC<RouteComponentProps> = ({ location, history }) => {
  const breadcrumbItem = location.pathname.split("/").slice(1);
  const link = (index: number) => breadcrumbItem.slice(0, index + 1).join("/");

  const breadcrumb = breadcrumbItem.map((item, index) => (
    <BreadcrumbComponent.Item>
      <Link to={`/${link(index)}`}>{item}</Link>
    </BreadcrumbComponent.Item>
  ));

  return (
    <BreadcrumbComponent style={{ margin: "24px 0 0 24px" }}>
      <BreadcrumbComponent.Item>
        <Link to="/">
          <HomeOutlined />
        </Link>
      </BreadcrumbComponent.Item>
      {breadcrumbItem[0] === "" || breadcrumb}
    </BreadcrumbComponent>
  );
};

export default withRouter(Breadcrumb);
