import useSWR from "swr";
import fetch from "unfetch";

import baseUrl from "../../lib/api/baseurl";
import { getToken } from "../../lib/token";

const fetcher = url =>
  fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then(r => r.json());

export default function useDashboard() {
  const { data, error } = useSWR(`${baseUrl}/dashboard`, fetcher, {
    refreshInterval: 10000,
  });

  return {
    data,
    error,
  };
}
