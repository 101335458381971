import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import { message, Modal } from "antd";

import {
  deleteJob as deleteJobAPI,
  checkJobSchedule
} from "../../../lib/api/job";
import useError from "../../useError";

import { CheckCircleOutlined } from "@ant-design/icons";

export default function useJobDelete() {
  const [modal, contextHolder] = Modal.useModal();
  const location = useLocation();
  const history = useHistory();
  const jobID = location.pathname.split("/job/")[1];
  const { handleError } = useError();

  const deleteJob = async () => {
    try {
      await deleteJobAPI(jobID);
      history.push("/job");
      message.success("Successfully Job has been deleted!");
    } catch (e) {
      handleError(e);
    }
  };

  const onDeleteClick = async () => {
    try {
      const response = await checkJobSchedule(jobID);

      if (response.status === 202) {
        modal.confirm({
          title: "Job can be deleted",
          content: `${response.data.msg}`,
          okText: "Delete",
          onOk: () => {
            deleteJob();
          },
        });
      }
      if (response.status === 200) {
        modal.confirm({
          title: "Job can be deleted",
          content: `${response.data.msg}`,
          okText: "Delete",
          onOk: () => {
            deleteJob();
          },
          icon: <CheckCircleOutlined style={{ color: "#52c41a" }}/>,
        });
      }
    } catch (e) {
      handleError(e);
    }
  };

  return {
    onDeleteClick,
    contextHolder
  };
}
