import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { RootState } from "../../../modules";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useJobScheduledButton() {
  const location = useLocation();
  const history = useHistory();
  const jobName = (() => {
    const JSONViewerState = typedUseSelector(
      (state: RootState) => state.JSONViewer
    );

    if (JSONViewerState.hasOwnProperty("basic_info"))
      return JSONViewerState.basic_info.job_name;
    return "";
  })();
  const jobID = location.pathname.split("/job/")[1];

  const onButtonClick = () => {
    history.push(`/schedule/create?job=${jobName} (${jobID})`);
  };

  return { onButtonClick };
}
