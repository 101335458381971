import React, { FC } from "react";

import { Form, Input, Typography } from "antd";

import TemplateFormGroup from "./TemplateFormGroup";
import TemplateDetailButtons from "../TemplateDetail/TemplateDetailButtons";

const { Title } = Typography;
const { TextArea } = Input;

type TemplateFormInfoProps = {
  isDetail?: boolean;
};

const TemplateFormInfo: FC<TemplateFormInfoProps> = ({ isDetail }) => (
  <>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Title level={3}>Template Information</Title>
      {isDetail && <TemplateDetailButtons />}
    </div>
    <Form.Item name="template_id" label="template_id" required>
      <Input disabled={isDetail} placeholder="템플릿의 고유한 ID를 입력해 주세요" />
    </Form.Item>
    <TemplateFormGroup editable={!isDetail} />
    <Form.Item name="desc" label="desc" required>
      <TextArea disabled={isDetail} placeholder="템플릿에 대한 설명을 작성해 주세요" autoSize />
    </Form.Item>
  </>
);

export default TemplateFormInfo;
