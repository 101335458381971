// Actions
const START_FETCHING = "fetch/START_FETCHING" as const;
const FINISH_FETCHING = "fetch/FINISH_FETCHING" as const;

// Action type
type FetchAction =
  | ReturnType<typeof startFetching>
  | ReturnType<typeof finishFetching>;

// State type & Initial State
type FetchState = {
  isFetching: boolean;
};

const initialState: FetchState = { isFetching: false };

const fetch = (state: FetchState = initialState, action: FetchAction) => {
  // 뭔가를 가지고 올것인지를 의미한다
  switch (action.type) {
    case START_FETCHING:
      return {
        isFetching: true,
      };
    case FINISH_FETCHING:
      return {
        isFetching: false,
      };

    default:
      return state;
  }
};

export default fetch;

// Action creators
export const startFetching = () => ({
  type: START_FETCHING,
});

export const finishFetching = () => ({
  type: FINISH_FETCHING,
});
