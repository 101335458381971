import React, { FC } from 'react'

import { Button, Input, Space } from '@madup-inc/mad-design'
import { SearchOutlined } from '@ant-design/icons'
import useTableFilterState from '../../hooks/table/useTableFilterState'

type TableSearchFilterProps = {
  name: string
  placeholder: string
}

const TableSearchFilter: FC<TableSearchFilterProps> = ({ name, placeholder }) => {
  const { filter, onSearchClick, setFilter, onResetClick } = useTableFilterState(name)

  return (
    <div style={{ padding: 8 }}>
      <Input
        size="small"
        placeholder={`${placeholder}`}
        value={filter}
        onChange={e => setFilter(e.target.value)}
        onPressEnter={() => onSearchClick()}
      />
      <Space>
        <Button type="primary" size="small" style={{ width: 90 }} onClick={() => onSearchClick()}>
          <SearchOutlined />
          Search
        </Button>
        <Button
          onClick={() => {
            setFilter('')
            onResetClick()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  )
}

export default TableSearchFilter
