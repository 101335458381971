import * as React from "react";

import { Steps } from "antd";

import useStep from "../../../hooks/useStep";
import useStepState from "../../../hooks/useStepState";

const { Step } = Steps;

const JobCreateSteps: React.FC = () => {
  const { current } = useStep();
  const { status } = useStepState();

  return (
    <>
      <Steps current={current} style={{ marginBottom: "48px" }} status={status}>
        <Step title="템플릿 선택" />
        <Step title="JOB 정보 입력" />
        <Step title="JOB Preview" />
      </Steps>
    </>
  );
};

export default JobCreateSteps;
