import axios from "axios";

import baseUrl from "./baseurl";
import { getToken } from "../token";

export const getOwnerList = async () => await axios.get(
  `${baseUrl}/product/owner`,
  {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  }
);
