import React from 'react'
import { Tag } from '@madup-inc/mad-design'
import dayjs from 'dayjs'

export const convertEmptyString = (text: any) => {
  if (!text || text == 'None') return <>-</>
  return (
    <div
      style={{
        wordWrap: 'break-word',
        wordBreak: 'break-word',
      }}
    >
      {text.length > 150 ? limitLongCharacter(limitLongCharacter(text)) : text}
    </div>
  )
}

const limitLongCharacter = (text: string) => {
  return `${text.slice(0, 151)} ...`
}

export const convertScheduleStatus = (text: any) => {
  switch (text) {
    case 'enable':
      return <Tag color="geekblue">{text}</Tag>
    case 'disable':
      return <Tag>{text}</Tag>
    case 'wait':
      return <Tag color="gold">{text}</Tag>
    case 'run':
      return <Tag color="green">{text}</Tag>
    case 'expired':
      return <Tag color="purple">{text}</Tag>
    case 'fail':
      return <Tag color="red">{text}</Tag>
    default:
      return <>-</>
  }
}

export const convertEmailToId = (email: string) => {
  if (email) return email.split('@')[0]
  return '-'
}

export const formatTime = (time: string) =>
  time === 'None' ? 'None' : dayjs(time).format('YYYY-MM-DD HH:mm:ss')
