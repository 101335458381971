import React, { FC } from "react";

import { Col } from "antd";

import InputText from "./InputText";
import InputPopup from "./InputPopup";
import InputDatetime from "./InputDatetime";
import InputDropdown from "./InputDropdown";
import { SectionName } from "../../modules/createJob/form";
import InputPeriod from "./InputPeriod";
import InputAutoComplete from "./InputAutoComplete";
import InputPyFile from "./InputPyFile";

type InputProps = {
  name: string;
  required: boolean;
  editable: boolean;
  desc: string;
  type: string;
  span?: number;
  sectionName?: SectionName;
  options?: [string, boolean][];
  autoOptions?: { value: string }[];
  onFieldsChange: (name: string, value: any) => void;
  getFieldValue: (name: string) => any;
};

const Input: FC<InputProps> = ({
  name,
  required,
  editable,
  desc,
  type,
  span = 12,
  sectionName,
  onFieldsChange,
  getFieldValue,
  options,
  autoOptions,
}) => {
  switch (type) {
    case "pop_up":
      return (
        <Col span={span}>
          <InputPopup
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            sectionName={sectionName}
            onFieldsChange={onFieldsChange}
            getFieldValue={getFieldValue}
          />
        </Col>
      );
    case "datetime":
      return (
        <Col span={span}>
          <InputDatetime
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            sectionName={sectionName}
            onFieldsChange={onFieldsChange}
            getFieldValue={getFieldValue}
          />
        </Col>
      );
    case "dropdown":
      return (
        <Col span={span}>
          <InputDropdown
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            sectionName={sectionName}
            options={options}
            onFieldsChange={onFieldsChange}
            getFieldValue={getFieldValue}
          />
        </Col>
      );
    case "period":
      return (
        <Col span={24}>
          <InputPeriod
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            sectionName={sectionName}
            getFieldValue={getFieldValue}
            onFieldsChange={onFieldsChange}
          />
        </Col>
      );
    case "auto":
      return (
        <Col span={span}>
          <InputAutoComplete
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            autoOptions={autoOptions}
          />
        </Col>
      );
    case "python":
      return (
        <Col span={span}>
          <InputPyFile name={name} required={required} editable={editable} />
        </Col>
      );
    default:
      return (
        <Col span={span}>
          <InputText
            required={required}
            editable={editable}
            name={name}
            DESC={desc}
            sectionName={sectionName}
          />
        </Col>
      );
  }
};

export default Input;
