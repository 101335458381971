import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux";

import { RootState } from "../../../modules";
import { getTemplate } from "../../../lib/api/template";
import { setForm as setFormState } from "../../../modules/createJob/form";
import { loadTemplate } from "../../../modules/createJob/template";
import useError from "../../useError";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useForm() {
  const dispatch = useDispatch();
  const { handleError } = useError();

  const templateInfos = typedUseSelector(
    (state: RootState) => state.createJob.template.templateInfos
  );
  const templateInfo = typedUseSelector((state: RootState) => {
    if(templateInfos) {
    const infoState = templateInfos[state.createJob.template.currentTemplate];
    if (infoState) return infoState;}
    return {
      template_id: ""
    };
  });

  const setForm = async () => {
    try {
      const currentTemplate = templateInfo.template_id;
      const response = await getTemplate(currentTemplate);
      dispatch(loadTemplate(response.data));
      dispatch(setFormState(response.data));
    } catch (e) {
      handleError(e);
    }
  };

  return {
    templateID: templateInfo.template_id,
    setForm,
  };
}
