export const loadState = () => {
  try {
    let serializedState = sessionStorage.getItem('http://argo-ui.lever.me:state')

    if (serializedState === null) {
      return
    }

    return JSON.parse(serializedState)
  } catch (error) {
    console.log(error)
  }
}

export const saveState = (state: any) => {
  try {
    let serializedState = JSON.stringify(state)
    sessionStorage.setItem('http://argo-ui.lever.me:state', serializedState)
  } catch (error) {
    console.log(error)
  }
}

export const initialState = () => {
  sessionStorage.removeItem('http://argo-ui.lever.me:state')
}
