import React, { FC } from "react";

import { Col, Divider, Form, Row, Typography } from "antd";

import TemplateFormInput from "./TemplateFormInput";
import TemplateFormPopup from "./TemplateFormPopup";
import TemplateFormSwitch from "./TemplateFormSwitch";
import TemplateFormTypeSelect from "./TemplateFormTypeSelect";
import { FormInstance } from "antd/lib/form";
import { SectionName } from "../../../modules/createJob/form";

const { Title } = Typography;

type TemplateFormBasicInfoProps = {
  isDetail?: boolean;
  form: FormInstance;
  sectionName: SectionName;
};

const TemplateFormBasicInfo: FC<TemplateFormBasicInfoProps> = ({ isDetail, form, sectionName }) => (
  <>
    <Divider />
    <Title level={3}>Basic Information</Title>
    <Form.List name="basic_info">
      {fields => (
        <>
          <Row gutter={[24, 40]}>
            {fields.map(field => {
              return (
                <>
                  <Col span={11}>
                    <TemplateFormInput
                      field={field}
                      name="name"
                      required={true}
                      editable={false}
                      desc="필드의 이름을 입력해 주세요."
                    />
                    <TemplateFormInput
                      field={field}
                      name="desc"
                      required={true}
                      editable={false}
                      desc="필드에 대한 설명을 입력해 주세요."
                    />
                    {field.name === 3 ? (
                      <TemplateFormPopup
                        field={field}
                        name="value"
                        required={false}
                        editable={false}
                        desc="필드의 기본값을 입력해 주세요."
                        form={form}
                        sectionName={sectionName}
                      />
                    ) : (
                      <TemplateFormInput
                        field={field}
                        name="value"
                        required={field.name === 1}
                        editable={!isDetail && (field.name === 4 || field.name === 1)}
                        desc="필드의 기본값을 입력해 주세요."
                      />
                    )}
                    <Row>
                      <Col flex={1}>
                        <TemplateFormSwitch
                          field={field}
                          name="required"
                          required={true}
                          editable={false}
                          desc="필드 값 입력 필수 여부를 입력해 주세요."
                        />
                      </Col>
                      <Col flex={1}>
                        <TemplateFormSwitch
                          field={field}
                          name="editable"
                          required={true}
                          editable={false}
                          desc="필드 값 수정 가능 여부를 입력해 주세요."
                        />
                      </Col>
                      <Col flex={6}>
                        <TemplateFormTypeSelect
                          field={field}
                          name="type"
                          required={true}
                          editable={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} />
                </>
              );
            })}
          </Row>
        </>
      )}
    </Form.List>
  </>
);

export default TemplateFormBasicInfo;
