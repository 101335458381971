import qs from 'qs'

const AUTHORIZE_URI = 'https://accounts.google.com/o/oauth2/v2/auth'

const queryStr = qs.stringify({
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  redirect_uri: window.location.origin,
  response_type: 'token',
  scope:
    'https://www.googleapis.com/auth/userinfo.email' +
    ' https://www.googleapis.com/auth/userinfo.profile openid',
})

export default `${AUTHORIZE_URI}?${queryStr}`
