import { useState, useEffect } from "react";

import { getTemplateGroups as getTemplateGroupsAPI } from "../../lib/api/template";
import useError from "../useError";

type Option = { label: string; value: string };

export default () => {
  const [options, setOptions] = useState<Option[]>([]);
  const { handleError } = useError();

  useEffect(() => {
    (async () => {
      await getTemplateGroups();
    })();
  }, []);

  const getTemplateGroups = async () => {
    try {
      const response = (await getTemplateGroupsAPI()).data;
      const groups = response.map(group => ({ label: group, value: group }));
      setOptions(groups);
    } catch (e) {
      handleError(e);
    }
  };

  return options;
};
