import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ContextListPage from "./ContextListPage";
import ContextDetailPage from "./ContextDetailPage";

const Context: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ContextListPage} />
      <Route
        exact
        path={`${path}/:context_key`}
        component={ContextDetailPage}
      />
    </Switch>
  );
};

export default Context;
