import React, { FC } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import TemplateListPage from "./TemplateListPage";
import TemplateDetailPage from "./TemplateDetailPage";
import TemplateCreatePage from "./TemplateCreatePage";
import TemplateEditPage from "./TemplateEditPage";

const Template: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={TemplateListPage} />
      <Route exact path={`${path}/create`} component={TemplateCreatePage} />
      <Route exact path={`${path}/edit`} component={TemplateEditPage} />
      <Route exact path={`${path}/:templateID`} component={TemplateDetailPage} />
    </Switch>
  );
};

export default Template;
