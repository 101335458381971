import React, { FC } from 'react'

import { Button, Icon } from '@madup-inc/mad-design'
import { DeleteFilled, EditOutlined, SaveOutlined, PartitionOutlined } from '@ant-design/icons'

import useTableDetailButton from '../../../hooks/context/useTableDetailButton'
import ModalForm from '../../Modal/ModalForm'
import { Form } from '../../../constants/form/type'
import { DetailWithRef } from '../../../constants/detail/type'

type TableDetailButtonsProps = {
  modalForm: () => Form[]
  form: DetailWithRef
}

const TableDetailButtons: FC<TableDetailButtonsProps> = ({ modalForm, form }) => {
  const {
    onEditSubmitClick,
    onDeleteClick,
    contextHolder,
    visible,
    setVisible,
    fields,
    onEditClick,
    isLoading,
    saveLink,
    onCustomJobClick,
  } = useTableDetailButton(form.path, form.title)

  return (
    <>
      {(form.path === '/context_param' || form.path === '/product') && (
        <Button
          size="small"
          style={{ marginRight: '4px' }}
          onClick={async () => {
            await onEditClick()
          }}
          type="text"
        >
          <Icon type="edit" />
        </Button>
      )}
      {form.path === '/sourcecode' && (
        <>
          <Button
            size="small"
            style={{ marginRight: '4px' }}
            onClick={() => onCustomJobClick()}
            type="text"
          >
            <PartitionOutlined />
          </Button>
          <a target="_blank" href={saveLink}>
            <Button size="small" style={{ marginRight: '4px' }} type="text">
              <SaveOutlined />
            </Button>
          </a>
        </>
      )}
      <Button size="small" type="text" danger onClick={() => onDeleteClick()}>
        <DeleteFilled />
      </Button>
      <ModalForm
        form={modalForm}
        visible={visible}
        handleOK={() => {
          onEditSubmitClick()
        }}
        handleCancel={() => {
          setVisible(false)
        }}
        fields={fields}
        title={`Edit ${form.title}`}
        okText="Edit"
      />
      {contextHolder}
    </>
  )
}

export default TableDetailButtons
