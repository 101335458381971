import axios from "axios";

import baseUrl from "./baseurl";
import { getToken } from "./../token";
import { Template } from "./../../modules/createJob/form";
import { TemplateInfo } from "../../modules/createJob/template";

export const getTemplateGroups = async () =>
  await axios.get<string[]>(`${baseUrl}/template/group`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getTemplateInfos = async (groupName: string) => {
  const response = await axios.get<TemplateInfo[]>(`${baseUrl}/template/group/${groupName}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

  return response;
};

export const getTemplate = async (templateID: string) =>
  await axios.get<Template>(`${baseUrl}/template/${templateID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const getTemplateOfJob = async (job_id: string) =>
  await axios.get(`${baseUrl}/template?job_id=${job_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const createTemplate = async template =>
  await axios.post(`${baseUrl}/template`, template, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const editTemplate = async (template, id) =>
  await axios.put(`${baseUrl}/template/${id}`, template, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });

export const deleteTemplate = async id =>
  axios.delete(`${baseUrl}/template/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  });
