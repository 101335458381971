import { useState } from "react";

import { message } from "antd";

export default (fileType: string) => {
  const [fileList, updateFileList] = useState<any[]>([]);

  const props = {
    fileList,
    beforeUpload: file => {
      if (file.type !== fileType) {
        message.error(`${file.name} is not a Python file`);
        return false;
      }
      updateFileList([file]);
      return false;
    },
    onRemove: file => {
      updateFileList([]);
    },
  };

  return props;
};
