import axios from "axios";

import baseUrl from "./baseurl";
import { getToken } from "./../token";

const url = `${baseUrl}/context_param`;

export type Context = {
  key: string; value: string; desc: string;
};

export const getContextDetail = async (idx: string | number) => await axios.get(
  `${url}/${idx}`,
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }
);

export const createContext = async (context: Context) => await axios.post(
  `${url}`,
  context,
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }
);

export const deleteContext = async (contextKey: string | number) => await axios.delete(
  `${url}/${contextKey}`,
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }
);

export const editContext = async (
  contextKey: string | number,
  context
) => await axios.put(
  `${url}/${contextKey}`, context, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }
);
