import React, { FC } from 'react'

import { Typography, Divider } from 'antd'

import JSONViewer from '../../JSONViewer'
import JobCreateButtonOnThird from './JobCreateButtons/JobCreateButtonOnThird'

const { Title } = Typography

type JobCreatePreviewProps = {
  createJob: () => void
}

const JobCreatePreview: FC<JobCreatePreviewProps> = ({ createJob }) => (
  <>
    <Title level={3}>Job Preview</Title>
    <p>생성될 JOB의 내용이 맞는지 검토하세요.</p>
    <Divider />
    <JSONViewer />
    <JobCreateButtonOnThird createJob={createJob} />
  </>
)

export default JobCreatePreview
