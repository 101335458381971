import { useSelector, TypedUseSelectorHook } from "react-redux";
import { useLocation } from "react-router-dom";

import { message } from "antd";

import { editJob as editJobAPI } from "../../../lib/api/job";
import { RootState } from "../../../modules";
import useError from "../../useError";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useJobEditAPI() {
  const location = useLocation();
  const jobID = location.search.split("job_id=")[1];
  const form = typedUseSelector((state: RootState) => state.createJob.form);
  const { handleError } = useError();

  const editJob = async () => {
    try {
      await editJobAPI(jobID, {
        basic_info: form.basic_info,
        context_param: form.context_param,
        job_param: form.job_param,
      });
      message.success("Successfully Job has been edited!");
    } catch (e) {
      handleError(e);
    }
  };

  return { editJob };
}
