import { useHistory } from "react-router-dom";

import { Modal } from "antd";

export default function useScheduleEditButtons() {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();

  const onClickPrevious = () => {
    modal.confirm({
      title: "Edit Schedule",
      content: "이전 화면으로 돌아가시겠습니까? 수정 사항은 반영되지 않습니다.",
      onOk: () => {
        history.goBack();
      },
    });
  };

  return { onClickPrevious, contextHolder };
}
