import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../modules";
import { initUserInfo } from "../modules/user";
import { removeToken } from "../lib/token";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useUser() {
  const dispatch = useDispatch();

  const isLogin = typedUseSelector((state: RootState) => state.user.isLogin);
  const name = typedUseSelector((state: RootState) => state.user.name);
  const picture = typedUseSelector((state: RootState) => state.user.picture);
  const logout = () => {
    dispatch(initUserInfo());
    removeToken();
  };

  return {
    isLogin,
    logout,
    name,
    picture
  };
}
