import React, { FC } from "react";

import { Form, Spin } from "antd";
import TemplateFormInfo from "./TemplateFormInfo";
import TemplateFormDynamicField from "./TemplateFormDynamicField";
import TemplateFormBasicInfo from "./TemplateFormBasicInfo";
import { FormInstance } from "antd/lib/form";
import useTemplateInitialValue from "../../../hooks/template/useTemplateInitialValue";

type TemplateFormProps = {
  isDetail?: boolean;
  isLoading?: boolean;
  fields: FormInstance;
  onFinish?: (value: object) => void;
  SubmitButton?: React.ReactElement;
};

const TemplateForm: FC<TemplateFormProps> = ({
  isDetail,
  fields,
  isLoading,
  onFinish,
  SubmitButton,
}) => {
  const initialValues = useTemplateInitialValue();

  return (
    <Spin spinning={isLoading}>
      <Form
        initialValues={initialValues}
        layout={"vertical"}
        form={fields}
        onFinish={template => {
          if (onFinish) {
            onFinish(template);
          }
        }}
      >
        <TemplateFormInfo isDetail={isDetail} />
        <TemplateFormBasicInfo isDetail={isDetail} form={fields} sectionName="basic_info" />
        <TemplateFormDynamicField
          sectionName="context_param"
          title="Context Parameter"
          isDetail={isDetail}
          form={fields}
        />
        <TemplateFormDynamicField
          sectionName="job_param"
          title="Job Parameter"
          isDetail={isDetail}
          form={fields}
        />
        {SubmitButton}
      </Form>
    </Spin>
  );
};

export default TemplateForm;
