import * as React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import ReportCreate from '../../components/Report/ReportCreate'
import ReportList from '../../components/Report/ReportList'
import ReportEdit from '../../components/Report/ReportEdit'
import useError from '../../hooks/useError'
import { getChannels, Channel } from '../../lib/api/report'

const Report: React.FC = () => {
  const { path } = useRouteMatch()
  const [ownerID, setOwnerID] = React.useState<string | undefined>()
  const [channels, setChannels] = React.useState<Channel[]>([])
  const [loading, setLoading] = React.useState(false)
  const { handleError } = useError()
  const fetchChannels = async () => {
    setLoading(true)
    try {
      const response = await getChannels()
      setChannels(response.data.data)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <ReportCreate ownerID={ownerID} channels={channels} />
      </Route>
      <Route exact path={`${path}/edit/:id`} component={ReportEdit} />
      <Route exact path={path}>
        <ReportList ownerID={ownerID} setOwnerID={setOwnerID} fetchChannels={fetchChannels} />
      </Route>
    </Switch>
  )
}

export default Report
