import { useDispatch } from 'react-redux'

import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { changeForm, SectionName } from '../../../modules/createJob/form'
import useJobEditAPI from './useJobEditAPI'
import useError from '../../useError'

export default function useJobEditButtons(fields) {
  const { editJob } = useJobEditAPI()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const location = useLocation()
  const jobName = location.search.split('job_id=')[1]
  const { handleError } = useError()

  const onClickGoBack = () => {
    history.goBack()
  }

  const onClickEdit = async () => {
    try {
      setLoading(true)
      await convertFieldsToForm()
      await editJob()
      history.push(`/job/${jobName}`)
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const convertFieldsToForm = async () => {
    const field = fields.getFieldsValue()
    await Object.entries(field).map(item => {
      // @ts-ignore
      const key: [SectionName, string] = item[0].split('/')
      dispatch(
        changeForm({
          sectionName: key[0],
          name: key[1],
          value: `${item[1]}`,
        }),
      )
    })
  }

  return {
    onClickGoBack,
    onClickEdit,
    loading,
    setLoading,
  }
}
