import React, { FC } from "react";
import { Tabs } from "antd";

type VerticalTabsProps = {
  currentGroup: string;
  onClickGroup: (group: string) => void;
  groups: { label: string; value: string | number }[];
  currentItem: string | number;
  onClickItem: (key: number | string) => any;
  items: { label: string; value: string | number }[];
};

const VerticalTabs: FC<VerticalTabsProps> = ({
  currentGroup,
  currentItem,
  onClickItem,
  items,
  onClickGroup,
  groups,
  children,
}) => {
  return (
    <>
      <Tabs activeKey={currentGroup} tabPosition="left" onTabClick={key => onClickGroup(key)}>
        {groups?.map(group => (
          <Tabs.TabPane tab={group.label} key={group.value}>
            <Tabs
              activeKey={`${currentItem}`}
              tabPosition="left"
              onTabClick={key => onClickItem(key)}
            >
              {items?.map(template => (
                <Tabs.TabPane tab={template.label} key={template.value}>
                  {children}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default VerticalTabs;
