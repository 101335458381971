import { combineReducers } from "redux";

import auth from "./auth";
import modal from "./modal";
import filtering from "./filtering";
import JSONViewer from "./JSONViewer";
import detail from "./detail";
import fetch from "./fetch";
import sidebar from "./sidebar";
import user from "./user";
import createJob from "./createJob";
import popup from "./popup";
import alert from "./alert";
import warning from "./warning";
import scheduleForm from "./createSchedule/scheduleForm";
import step from "./step";
import modaltable from "./modaltable";
import table from "./table";

const rootReducer = combineReducers({
  auth,
  modal,
  filtering,
  JSONViewer,
  detail,
  fetch,
  sidebar,
  user,
  createJob,
  popup,
  alert,
  warning,
  scheduleForm,
  step,
  modaltable,
  table,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
