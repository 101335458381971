import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { createSchedule, editSchedule } from "../../../lib/api/schedule";
import useError from "../../useError";

import { message } from "antd";

export default function useScheduleAPI() {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const idx = location.search.split("index=")[1];
  const { handleError } = useError();

  const history = useHistory();

  const submitNewSchedule = async (schedule) => {
    setLoading(true);
    try {
      if (schedule.period.case === 2 && schedule.period.value.day.length === 0) {
        throw new Error("Please select the days of the week.");
      } else if (schedule.period.case === 1 && schedule.period.value.hour === 0 && schedule.period.value.min === 0) {
        throw new Error("The execution cycle must be at least one minute.");
      } else {
        const response = await createSchedule(schedule);
        message.success("Successfully schedule has been created!", undefined);
        history.push(`/schedule/${response.data.idx}`);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const submitEditedSchedule = async (schedule) => {
    setLoading(true);
    try {
      if (schedule.period.case === 2 && schedule.period.value.day.length === 0) {
        throw new Error("Please select the days of the week.");
      } else if (schedule.period.case === 1 && schedule.period.value.hour === 0 && schedule.period.value.min === 0) {
        throw new Error("The execution cycle must be at least one minute.");
      } else {
        await editSchedule(schedule, idx);
        history.push(`/schedule/${idx}`);
        message.success("Successfully schedule has been edited!");
      }
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    submitNewSchedule,
    loading,
    submitEditedSchedule
  };
}
