import React, { FC } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import SourcecodeListPage from "./SourcecodeListPage";
import SourcecodeDetailPage from "./SourcecodeDetailPage";

const Sourcecode: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SourcecodeListPage} />
      <Route exact path={`${path}/:source_id`} component={SourcecodeDetailPage} />
    </Switch>
  );
};

export default Sourcecode;
