import { FormState, Template } from "../../../modules/createJob/form";

import temporaryTemplate from "../../../constants/temporaryTemplate";

const sections = ["basic_info", "context_param", "job_param"];

export default function useJobTemporaryTemplate() {
  const createTemporaryTemplate = (form: FormState) => {
    const template: Template = {
      template_id: "",
      basic_info: [],
      context_param: [],
      job_param: [],
    };

    sections.map((section) => {
      if (form[section]) {
        Object.entries(form[section]).map((item) => {
          template[section].push({
            name: item[0],
            value: item[1],
            type:
              section === "context_param"
                ? "pop_up"
                : section === "job_param" ? "text" : (temporaryTemplate.pop_up.indexOf(
                item[0])
                === -1
                ? "text"
                : "pop_up"),
            editable:
              temporaryTemplate.disabled.indexOf(item[0]) === -1,
            required:
              section !== "basic_info" ? false : temporaryTemplate.required.indexOf(
                item[0]) !== -1,
            desc: `${item[0]}을 입력해 주세요.`,
          });
        });
      }
    });

    return template;
  };

  return { createTemporaryTemplate };
}
