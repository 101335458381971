// @ts-ignore
import React, { FC } from 'react'

import { Space, Radio } from 'antd'
import { Button } from '@madup-inc/mad-design'
import { SearchOutlined } from '@ant-design/icons'
import useTableFilterState from '../../hooks/table/useTableFilterState'

type TableSelectFilterProps = {
  items: string[]
  name: string
}

const TableSelectFilter: FC<TableSelectFilterProps> = ({ items, name }) => {
  const { filter, onSearchClick, setFilter, onResetClick } = useTableFilterState(name)

  return (
    <div style={{ padding: 8 }}>
      <Radio.Group
        style={{ display: 'block', marginBottom: 8 }}
        value={filter}
        onChange={e => setFilter(e.target.value)}
      >
        {items.map(item => (
          <Radio
            style={{
              display: 'block',
              height: '16px',
              lineHeight: '16px',
              marginBottom: 12,
            }}
            value={item}
          >
            {item}
          </Radio>
        ))}
      </Radio.Group>
      <Space>
        <Button type="primary" size="small" style={{ width: 90 }} onClick={() => onSearchClick()}>
          <SearchOutlined /> Search
        </Button>
        <Button onClick={() => onResetClick()} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  )
}

export default TableSelectFilter
