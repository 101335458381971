import * as React from 'react'

import { Form, Input } from 'antd'

import useModal from '../../hooks/modal/useModal'
import useModalAPI from '../../hooks/modal/useModalAPI'
import ModalTable from '../Modal/ModalTable'
import { SectionName } from '../../modules/createJob/form'

import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'

type InputPopupProps = {
  required: boolean
  editable: boolean
  name: string
  DESC: string
  sectionName?: SectionName
  onFieldsChange: (name: string, value: string) => void
  getFieldValue: (name: string) => any
}

const InputPopup: React.FC<InputPopupProps> = ({
  required,
  editable,
  DESC,
  name,
  sectionName,
  onFieldsChange,
  getFieldValue,
}) => {
  const { handleOK, handleCancel, visible, showModal } = useModal()
  const { onClick, loading } = useModalAPI(name, '')

  return (
    <>
      <Form.Item
        name={sectionName ? `${sectionName}/${name}` : `${name}`}
        label={name}
        rules={[
          {
            required: editable && required,
            message: `'${name}' is required`,
          },
        ]}
      >
        <Input
          placeholder={DESC}
          addonAfter={
            loading ? (
              <LoadingOutlined />
            ) : (
              <SearchOutlined
                onClick={async () => {
                  if (editable && name !== 'owner_id') {
                    await onClick()
                    showModal()
                  }
                }}
                style={{ cursor: editable && name !== 'owner_id' ? 'pointer' : 'not-allowed' }}
              />
            )
          }
          disabled={true}
        />
      </Form.Item>
      <ModalTable
        visible={visible}
        handleOK={handleOK}
        handleCancel={handleCancel}
        name={name}
        selectedKey={getFieldValue(sectionName ? `${sectionName}/${name}` : `${name}`)}
        onFieldsChange={onFieldsChange}
        sectionName={sectionName}
      />
    </>
  )
}

export default InputPopup
