import React, { FC } from 'react'
import { Descriptions } from 'antd'
import { convertScheduleStatus } from '../../../utils/tableDataRenderer'
import useScheduleDetail from '../../../hooks/schedule/scheduleDetail/useScheduleDetail'
import ScheduleDetailButtons from './ScheduleDetailButtons'
import { oneOf } from '@madup-inc/utils'
import dayjs from 'dayjs'
import { PERIOD_LABEL } from '../../../constants'

const ScheduleDetail: FC = () => {
  const { scheduleInfo } = useScheduleDetail()

  const itemValue = item =>
    oneOf(
      [
        [item.label === 'status', () => convertScheduleStatus(scheduleInfo[item.index])],
        [
          item.index.endsWith('_time'),
          () =>
            scheduleInfo[item.index] === 'None'
              ? 'None'
              : dayjs(scheduleInfo[item.index]).format('YYYY-MM-DD HH:mm:ss'),
        ],
        [
          item.index === 'parsed_period',
          () => PERIOD_LABEL[scheduleInfo[item.index]] ?? scheduleInfo[item.index],
        ],
      ],
      scheduleInfo[item.index],
    )
  return (
    <Descriptions
      bordered
      column={2}
      title={`Schedule Detail (${scheduleInfo.idx})`}
      extra={<ScheduleDetailButtons />}
    >
      {DetailLabels.map(item => (
        <Descriptions.Item key={item.index} label={item.label} span={item.span ? item.span : 1}>
          {itemValue(item)}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

export default ScheduleDetail

const DetailLabels = [
  {
    label: 'Job Name',
    index: 'job_name',
    span: 2,
  },
  {
    label: 'Scheduled Time',
    index: 'scheduled_time',
  },
  {
    label: 'Expired Time',
    index: 'expired_time',
  },
  {
    label: 'status',
    index: 'status',
    span: 2,
  },
  {
    label: 'Period',
    index: 'parsed_period',
    span: 2,
  },
  {
    label: 'Created Time',
    index: 'created_time',
  },
  {
    label: 'Modified Time',
    index: 'modified_time',
  },
]
