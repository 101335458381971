import * as React from 'react'
import { Row, Typography, Divider, Form, Input as AntInput, Col } from 'antd'

import useFormState from '../../../hooks/job/jobCreate/useFormState'
import { SectionName, TemplateObj } from '../../../modules/createJob/form'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import useModalAPI from '../../../hooks/modal/useModalAPI'
import ModalTable from '../../Modal/ModalTable'

const { Title } = Typography

type JobCreateFormSectionProps = {
  name: string
  desc: string
  sectionName: SectionName
  setFieldsValue: (value: any) => void
  getFieldValue: (value: any) => any
}

type FormInputProps = {
  sectionName: SectionName
  item: TemplateObj
  setModal: React.Dispatch<React.SetStateAction<undefined | string>>
}

const JobCreateFormSection: React.FC<JobCreateFormSectionProps> = ({
  name,
  desc,
  sectionName,
  setFieldsValue,
  getFieldValue,
}) => {
  const { template } = useFormState()
  const [modal, setModal] = React.useState<undefined | string>()

  return (
    <section>
      <Form.Item noStyle name={sectionName}>
        <Divider />
        <Title level={4}>{name}</Title>
        <p>{desc}</p>
        <Row gutter={16}>
          {template[sectionName].map(item => (
            <FormInput sectionName={sectionName} item={item} setModal={setModal} key={item.name} />
          ))}
        </Row>
        <ModalTable
          visible={Boolean(modal)}
          handleOK={() => setModal(undefined)}
          handleCancel={() => setModal(undefined)}
          name={modal || ''}
          selectedKey={getFieldValue(name)}
          onFieldsChange={() => {}}
          sectionName={sectionName}
          setFieldsValue={setFieldsValue}
        />
      </Form.Item>
    </section>
  )
}

export default JobCreateFormSection

const FormInput: React.FC<FormInputProps> = ({ item, sectionName, setModal }) => {
  const { onClick, loading } = useModalAPI(item.name, '')

  return (
    <Col span={12}>
      {item.type === 'pop_up' ? (
        <Form.Item
          shouldUpdate
          label={item.name}
          name={[sectionName, item.name]}
          rules={[
            {
              required: item.editable,
              message: `'${item.name}' is required`,
            },
          ]}
        >
          <AntInput
            disabled
            placeholder={item.desc}
            addonAfter={
              loading ? (
                <LoadingOutlined />
              ) : (
                <SearchOutlined
                  onClick={async () => {
                    if (item.editable && item.name !== 'owner_id') {
                      await onClick()
                      setModal(item.name)
                    }
                  }}
                  style={{
                    cursor: item.editable && item.name !== 'owner_id' ? 'pointer' : 'not-allowed',
                  }}
                />
              )
            }
          />
        </Form.Item>
      ) : (
        <Form.Item
          label={item.name}
          name={[sectionName, item.name]}
          rules={[
            {
              required: item.editable,
              message: `'${item.name}' is required`,
            },
          ]}
        >
          <AntInput.TextArea autoSize placeholder={item.desc} disabled={!item.editable} />
        </Form.Item>
      )}
    </Col>
  )
}
