import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  createTemplate,
  editTemplate,
  deleteTemplate as deleteTemplateAPI,
} from "../../lib/api/template";
import useError from "../useError";

import { message } from "antd";

export default () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { handleError } = useError();

  const submitNewTemplate = async template => {
    setLoading(true);
    try {
      await createTemplate(template);
      message.success("Successfully template has been created!");
      history.push("/template");
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const submitEditedTemplate = async template => {
    const id = location.search.split("id=")[1];
    setLoading(true);
    try {
      await editTemplate(template, id);
      history.push(`/template/${id}`);
      message.success("Successfully template has been created!");
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteTemplate = async () => {
    const id = location.pathname.split("/template/")[1];
    try {
      await deleteTemplateAPI(id);
      history.push("/template");
      message.success("Successfully template has been deleted!");
    } catch (e) {
      handleError(e);
    }
  };

  return { loading, submitNewTemplate, submitEditedTemplate, deleteTemplate };
};
