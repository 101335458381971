// Actions
const SET_FORM = "createJob/form/SET_FORM" as const;
const INIT_FORM = "createJob/form/INIT_FORM" as const;
const CHANGE_FORM = "createJob/form/CHANGE_FORM" as const;

// Action type
type FormAction =
  | ReturnType<typeof setForm>
  | ReturnType<typeof initForm>
  | ReturnType<typeof changeForm>;

// State Type & Initial Type
export type TemplateObj = {
  name: string;
  value: string;
  type: string;
  editable: boolean;
  required: boolean;
  desc: string;
};

export type Template = {
  template_id: string;
  basic_info: TemplateObj[];
  context_param: TemplateObj[];
  job_param: TemplateObj[];
};

export type SectionName = "basic_info" | "context_param" | "job_param";

export type FormState = {
  template_id: string;
  basic_info: { [key: string]: any };
  context_param: { [key: string]: any };
  job_param: { [key: string]: any };
};

const initialState: FormState = {
  template_id: "",
  basic_info: {},
  context_param: {},
  job_param: {},
};

// Reducer
const form = (state: FormState = initialState, action: FormAction) => {
  switch (action.type) {
    case SET_FORM:
      let setForm: FormState = {
        template_id: "",
        basic_info: {},
        context_param: {},
        job_param: {},
      };

      action.payload.basic_info.map(
        (item) => (setForm.basic_info[`${item.name}`] = item.value)
      );
      action.payload.context_param.map(
        (item) => (setForm.context_param[`${item.name}`] = item.value)
      );
      action.payload.job_param.map(
        (item) => (setForm.job_param[`${item.name}`] = item.value)
      );

      setForm.template_id = action.payload.template_id;

      return setForm;
    case INIT_FORM:
      return {
        template_id: "",
        basic_info: {},
        context_param: {},
        job_param: {},
      };
    case CHANGE_FORM:
      let changedForm = { ...state };
      changedForm[action.payload.sectionName][action.payload.name] =
        action.payload.value;

      return changedForm;
    default:
      return state;
  }
};

export default form;

// Action Creators
export const setForm = (payload: Template) => ({
  type: SET_FORM,
  payload: payload,
});

export const initForm = () => ({
  type: INIT_FORM,
});

export const changeForm = ({
  sectionName,
  name,
  value,
}: {
  sectionName: SectionName;
  name: string;
  value: string;
}) => ({
  type: CHANGE_FORM,
  payload: { name: name, value: value, sectionName: sectionName },
});
