import { useState, useEffect } from "react";

import { getOwnerList } from "../../lib/api/owner";
import useError from "../useError";

export default function useOwnerList() {
  const [ownerList, setOwnerList] = useState<{ value: string }[]>([]);
  const { handleError } = useError();

  useEffect(() => {
    (async () => await fetchOwnerList())();
  }, []);

  const fetchOwnerList = async () => {
    try {
      const response = await getOwnerList();
      let owners: { value: string }[] = [];
      response.data.map(item => {
        owners.push({ value: `${item}` });
      });
      setOwnerList(owners);
    } catch (e) {
      handleError(e);
    }
  };

  return ownerList;
}
