import { useState } from "react";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";

export default function useTableFilterState(name: string) {
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const param = new URLSearchParams(location.search);
  const [filter, setFilter] = useState<string>(param.get(name) || "");

  const onSearchClick = () => {
    if (param.has("page")) param.set("page", "1");
    param.set(name, filter);
    history.push(`${path}?${param.toString()}`);
  };

  const onResetClick = () => {
    setFilter("");
    if (param.has("page")) param.set("page", "1");
    param.delete(name);
    history.push(`${path}?${param.toString()}`);
  };

  return {
    filter,
    onSearchClick,
    setFilter,
    onResetClick
  };
}
