import React, { FC } from 'react'

import useStepState from '../../../../hooks/useStepState'
import { Button } from 'antd'

type JobCreateButtonOnThirdProps = {
  createJob: () => void
}

const JobCreateButtonOnThird: FC<JobCreateButtonOnThirdProps> = ({ createJob }) => {
  const { onClickPrev } = useStepState()

  return (
    <section
      style={{
        marginTop: '24px',
        textAlign: 'right',
      }}
    >
      <Button onClick={() => onClickPrev()}>Previous</Button>
      <Button
        style={{ marginLeft: '8px' }}
        type="primary"
        htmlType="submit"
        onClick={async () => {
          await createJob()
        }}
      >
        Done
      </Button>
    </section>
  )
}

export default JobCreateButtonOnThird
