import React from "react";

import TableSearchFilter from "../../components/Table/TableSearchFilter";
import { Table } from "./type";
import {
  convertEmptyString,
  convertEmailToId
} from "../../utils/tableDataRenderer";

export default {
  path: "/job",
  detail: "job_id",
  pagination: false,
  columns: [
    {
      title: "Job Name",
      dataIndex: "job_name",
      render: (text) => convertEmptyString(text),
      width: "30%",
      filterDropdown: (
        <TableSearchFilter name="job_name" placeholder="Search Job Name"/>
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Description",
      dataIndex: "desc",
      render: (text) => convertEmptyString(text),
      filterDropdown: (
        <TableSearchFilter name="desc" placeholder="Search Description"/>
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      render: (text) => convertEmptyString(text),
      width: "13%",
      filterDropdown: (
        <TableSearchFilter name="product_id" placeholder="Search Product ID"/>
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Owner ID",
      dataIndex: "owner_id",
      render: (text) => convertEmptyString(text),
      width: "16%",
      filterDropdown: (
        <TableSearchFilter
          name="owner_id"
          placeholder="Search Owner ID"
        />
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: "Argo ID",
      dataIndex: "argo_id",
      render: (text) => <>{convertEmailToId(text)}</>,
      width: "9%",
      filterDropdown: (
        <TableSearchFilter name="argo_id" placeholder="Search Argo ID"/>
      ),
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
  ],
} as Table;
