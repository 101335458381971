import { useState } from "react";

export default function useModal() {
  const [visible, setVisible] = useState(false);

  const handleOK = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const showModal = () => {
    setVisible(true);
  };

  return { visible, handleCancel, handleOK, showModal };
}
