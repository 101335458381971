// Actions
const UPDATE_JSON_VIEWER = "JSONViewer/UPDATE_JSON_VIEWER" as const;
const INIT_JSON_VIEWER = "JSONViewer/INIT_JSON_VIEWER" as const;

// Action Type
type JSONViewerAction =
  | ReturnType<typeof updateJSONViewer>
  | ReturnType<typeof initJSONViewer>;

// State Type & Initial State
export type JSONViewerState = {
  [key: string]: any;
};

const initialState: JSONViewerState = {};

// Reducer
const JSONViewer = (
  state: JSONViewerState = initialState,
  action: JSONViewerAction
) => {
  switch (action.type) {
    case UPDATE_JSON_VIEWER:
      return action.payload;
    case INIT_JSON_VIEWER:
      return initialState;
    default:
      return state;
  }
};

export default JSONViewer;

// Action Creators
export const updateJSONViewer = (payload: JSONViewerState) => ({
  type: UPDATE_JSON_VIEWER,
  payload: payload,
});

export const initJSONViewer = () => ({
  type: INIT_JSON_VIEWER,
});
