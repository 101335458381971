import React, { FC } from 'react'

import TemplateForm from './TemplateForm'
import useTemplateDetail from '../../hooks/template/useTemplateDetail'
import { Button, Form } from 'antd'
import useTemplateAPI from '../../hooks/template/useTemplateAPI'

type EditButtonProps = { loading: boolean }

const EditButton: FC<EditButtonProps> = ({ loading }) => (
  <div style={{ float: 'right' }}>
    <Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Edit
      </Button>
    </Form.Item>
  </div>
)

const TemplateEdit: FC = () => {
  const { fields, isLoading } = useTemplateDetail()
  const { submitEditedTemplate } = useTemplateAPI()

  return (
    <TemplateForm
      fields={fields}
      isLoading={false}
      SubmitButton={<EditButton loading={isLoading} />}
      onFinish={submitEditedTemplate}
    />
  )
}

export default TemplateEdit
