import { useHistory, useLocation } from "react-router-dom";

export default function useJobEdit() {
  const location = useLocation();
  const history = useHistory();
  const jobID = location.pathname.split("/")[2];

  const onEditClick = () => {
    history.push(`/job/edit?job_id=${jobID}`);
  };

  return { onEditClick };
}
