import React, { FC, useState } from "react";
import { Form, Input, Select, Typography, Switch } from "antd";

import { ReportField, Field, ReportEditField } from "../../lib/api/report";
import "./style.scss";

type ReportJobSettingFormProps = {
  field: ReportField | ReportEditField;
  isEdit?: true;
};

type ReportJobSettingFormInstanceProps = {
  idx: number;
  subField: [string, Array<Field | string>];
  field: ReportField | ReportEditField;
  isEdit?: true;
};

const ReportJobSettingForm: FC<ReportJobSettingFormProps> = ({ field, isEdit }) => {
  return (
    <>
      {Object.entries(field).map((subField, idx) => (
        <ReportJobSettingFormInstance idx={idx} subField={subField} field={field} isEdit={isEdit} />
      ))}
    </>
  );
};

const ReportJobSettingFormInstance: FC<ReportJobSettingFormInstanceProps> = ({
  idx,
  subField,
  field,
  isEdit,
}) => {
  const switchVisible = Object.keys(field).length > 2 && subField[0] !== "basic_info";
  const [visible, setVisible] = useState(
    switchVisible
      ? field.sub_report_type
        ? (field.sub_report_type as string).indexOf(subField[0]) !== -1
        : true
      : true,
  );

  return subField[0] === "sub_report_type" ? null : (
    <span key={idx} className="report-form-wrapper">
      <div>
        <Typography.Title level={3}>{subField[0]}</Typography.Title>
        {switchVisible && (
          <Switch
            checked={visible}
            onChange={e => {
              setVisible(e);
            }}
          />
        )}
      </div>
      {visible &&
        Object.entries((subField as [string, Field[]])[1]).map(item => (
          <Form.Item
            label={item[1].label}
            name={[subField[0], item[1].name]}
            tooltip={item[1].desc}
            initialValue={item[1].value}
            required={item[1].required}
            key={item[1].label}
          >
            {item[1].column ? (
              <Select mode="multiple" allowClear placeholder={item[1].desc}>
                {item[1].column.map(column => (
                  <Select.Option key={column} value={column}>
                    {column}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input
                placeholder={item[1].desc}
                disabled={isEdit && item[1].name === "start_date"}
              />
            )}
          </Form.Item>
        ))}
    </span>
  );
};

export default ReportJobSettingForm;
