import React from "react";

import TableSearchFilter from "../../components/Table/TableSearchFilter";
import { Table } from "./type";
import { convertEmptyString } from "../../utils/tableDataRenderer";

export default {
  path: "/context_param",
  detail: "context_key",
  pagination: {
    position: ["bottomCenter"],
    defaultPageSize: 20,
    size: "default",
  },
  columns: [
    {
      title: "Context Key",
      dataIndex: "context_key",
      render: (text) => convertEmptyString(text),
      filterDropdown: (
        <TableSearchFilter
          name="context_key"
          placeholder="Search Context Key"
        />
      ),
      sorter: {
        compare: (a, b) => `${a.context_key}`.localeCompare(`${b.context_key}`),
        multiple: 0,
      },
      width: "30%",
    },
    {
      title: "Context Value",
      dataIndex: "context_value",
      render: (text) => convertEmptyString(text),
      filterDropdown: (
        <TableSearchFilter
          name="context_value"
          placeholder="Search Context Parameter"
        />
      ),
      sorter: {
        compare: (a, b) =>
          `${a.context_value}`.localeCompare(`${b.context_value}`),
        multiple: 0,
      },
      width: "30%",
    },
    {
      title: "Description",
      dataIndex: "desc",
      render: (text) => convertEmptyString(text),
      filterDropdown: (
        <TableSearchFilter name="desc" placeholder="Search Description"/>
      ),
      sorter: {
        compare: (a, b) => `${a.desc}`.localeCompare(`${b.desc}`),
        multiple: 0,
      },
    },
  ],
} as Table;
