import * as React from "react";

import { Form, DatePicker, Tooltip } from "antd";

import { SectionName } from "../../modules/createJob/form";

type InputDatetimeProps = {
  required: boolean;
  editable: boolean;
  name: string;
  DESC: string;
  sectionName?: SectionName;
  onFieldsChange: (name: string, value: any) => void;
  getFieldValue: (name: string) => any;
};

const InputDatetime: React.FC<InputDatetimeProps> = ({
  name,
  required,
  DESC,
  editable,
  sectionName,
  getFieldValue,
}) => (
  <Tooltip title={DESC} placement="topLeft">
    <Form.Item noStyle shouldUpdate>
      {() => {
        if (
          name === "expired_time" &&
          getFieldValue("period") &&
          getFieldValue("period").case === 0
        ) {
          return null;
        }
        return (
          <Form.Item
            label={name}
            name={sectionName ? `${sectionName}/${name}` : `${name}`}
            rules={[
              {
                required: editable && required,
                message: `'${name}' is required`,
              },
            ]}
          >
            <DatePicker
              disabled={!editable}
              showTime
              style={{ width: "100%" }}
              format="YYYY-MM-DD HH:mm:ss"
              placeholder={DESC}
              allowClear={!required}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  </Tooltip>
);

export default InputDatetime;
