import React, { FC } from "react";

import { Card } from "antd";

import DashboardLogItem from "./DashboardLogItem";
import useDashboard from "../../../hooks/dashboard/useDashboard";

const DashboardLog: FC = () => {
  const { data } = useDashboard();

  return (
    <Card
      style={{
        backgroundColor: "#262626",
        overflow: "scroll",
        height: "500px",
      }}
    >
      {data &&
        data.log.map(item => (
          <DashboardLogItem
            job_name={item.job_name}
            log_level={item.log_level}
            start_time={item.start_time}
          />
        ))}
    </Card>
  );
};

export default DashboardLog;
