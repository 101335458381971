import React, { FC } from "react";

import { Form, Input } from "antd";

import Modal from "./";
import useJobClone from "../../hooks/job/jobDetail/useJobClone";

type ModalJobEditProps = {
  visible: boolean;
  setVisible: (state: boolean) => void;
};

const ModalJobEdit: FC<ModalJobEditProps> = ({ visible, setVisible }) => {
  const { jobName, fields, onClickOK } = useJobClone();

  return (
    <Modal
      title="Job Clone"
      visible={visible}
      handleOK={() => {
        onClickOK();
        setVisible(false);
      }}
      handleCancel={() => {
        setVisible(false);
      }}
    >
      <Form layout="vertical" form={fields}>
        <Form.Item
          label="Job Name"
          name="job_name"
          initialValue={`${jobName}의 사본`}
          required
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalJobEdit;
