import React, { FC } from "react";

import { Input, Table } from "antd";

import useFormSearch from "../../../hooks/job/jobCreate/useFormSearch";
import useModalAPI from "../../../hooks/modal/useModalAPI";

type ModalTableFormProps = {
  type: string;
  selectedRow: any[];
  onRowChange: (selectedRowKeys, selectedRows) => void;
};

const { Search } = Input;

const getColumnWithType = (type: string) => {
  switch (type) {
    case "product_id":
      return column.product_id;
    case "argo_id":
      return column.argo_id;
    default:
      return column.context_param;
  }
};

type Column = {
  [key: string]: {
    title: string;
    dataIndex: string;
    key: string;
    sorter?: (a: any, b: any) => number;
  }[];
};

const column: Column = {
  product_id: [
    {
      title: "Owner ID",
      dataIndex: "owner_id",
      key: "owner_id",
      sorter: (a, b) => a.owner_id.localeCompare(b.owner_id),
    },
    {
      title: "Product ID",
      dataIndex: "product_id",
      key: "product_id",
      sorter: (a, b) => a.product_id.localeCompare(b.product_id),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
    },
    {
      title: "DESC",
      dataIndex: "desc",
      key: "desc",
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ],
  argo_id: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Team ID",
      dataIndex: "team_id",
      key: "team_id",
      sorter: (a, b) => a.team_id.localeCompare(b.team_id),
    },
  ],
  context_param: [
    {
      title: "Context Key",
      dataIndex: "context_key",
      key: "context_key",
      sorter: (a, b) => a.context_key.localeCompare(b.context_key),
    },
    {
      title: "Context Value",
      dataIndex: "context_value",
      key: "context_value",
      sorter: (a, b) => a.context_value.localeCompare(b.context_value),
    },
    {
      title: "DESC",
      dataIndex: "desc",
      key: "desc",
      sorter: (a, b) => a.desc.localeCompare(b.desc),
    },
  ],
};

const ModalTableForm: FC<ModalTableFormProps> = ({ type, selectedRow, onRowChange }) => {
  const { keyword, setKeyword } = useFormSearch();
  const { table, onClick, loading } = useModalAPI(type, keyword);

  return (
    <div style={{ textAlign: "center" }}>
      <Search
        placeholder="검색 키워드를 입력하세요"
        enterButton
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        style={{ marginBottom: "16px" }}
        onSearch={() => onClick()}
        loading={loading}
      />
      <Table
        columns={getColumnWithType(type)}
        dataSource={table.data}
        pagination={{ position: ["bottomCenter"] }}
        style={{
          marginBottom: "16px",
          wordBreak: "break-all",
        }}
        size="small"
        tableLayout="fixed"
        rowSelection={{
          type: "radio",
          selectedRowKeys: [`${selectedRow[0] ? selectedRow[0].key : ""}`],
          onChange: (selectedRowKeys, selectedRows) => {
            onRowChange(selectedRowKeys, selectedRows);
          },
        }}
      />
    </div>
  );
};

export default ModalTableForm;
