import * as React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import JobDetailPage from "./JobDetailPage";
import JobCreatePage from "./JobCreatePage";
import JobListPage from "./JobListPage";
import JobEditPage from "./JobEditPage";

const Job: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={JobListPage} />
      <Route exact path={`${path}/create`} component={JobCreatePage} />
      <Route exact path={`${path}/edit`} component={JobEditPage} />
      <Route exact path={`${path}/:jobID`} component={JobDetailPage} />
    </Switch>
  );
};

export default Job;
