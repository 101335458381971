import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Form } from "antd";

import useError from "../useError";
import { getTemplate as getTemplateAPI } from "../../lib/api/template";

export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fields] = Form.useForm();
  const { handleError } = useError();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      await getTemplate();
    })();
  }, []);

  const getTemplate = async () => {
    try {
      const response = (await getTemplateAPI(getTemplateID())).data;
      fields.setFieldsValue(response);
      console.log(fields.getFieldsValue());
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getTemplateID = () => {
    if (location.pathname.split("/")[2] === "edit") return location.search.split("id=")[1];
    return location.pathname.split("/")[2];
  };

  return { fields, isLoading };
};
