import React, { FC } from 'react'

import { Typography } from 'antd'

type DashboardLogItemProps = {
  job_name: string
  log_level: 100 | 200 | 300 | 400
  start_time: string
}

const { Text } = Typography

const DashboardLogItem: FC<DashboardLogItemProps> = ({ job_name, log_level, start_time }) => (
  <>
    <samp
      style={{
        color: '#fafafa',
        marginBottom: '4px',
        display: 'inline-block',
      }}
    >
      {'>'} [{start_time}] {getMessage(log_level, job_name)}
    </samp>
    <br />
  </>
)

export default DashboardLogItem

const getMessage = (level: 100 | 200 | 300 | 400, name: string) => {
  switch (level) {
    case 100:
      return (
        <span>
          <span style={{ color: '#bfbfbf' }}>[DEBUG]</span> <Text mark>{name}</Text> has been
          finished!
        </span>
      )
    case 200:
      return (
        <span>
          <span style={{ color: '#52c41a' }}>[SUCCESS]</span> <Text mark>{name}</Text> has been
          finished!
        </span>
      )
    case 300:
      return (
        <span>
          <Text type="warning">[WARNING]</Text> Please check <Text mark>{name}</Text>.
        </span>
      )
    case 400:
      return (
        <span>
          <Text type="danger">[ERROR]</Text> <Text mark>{name}</Text> has been failed.
        </span>
      )
  }
}
