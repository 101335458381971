const TOKEN_NAME = `argo_token:${process.env.REACT_APP_ENV_VAR}`

export const setToken = (token: string) => {
  sessionStorage.setItem(TOKEN_NAME, token)
}

export const getToken = () => {
  const token = sessionStorage.getItem(TOKEN_NAME)

  if (token) {
    return token
  } else {
    console.log('no token')
    return
  }
}

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_NAME)
}
