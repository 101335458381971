import { useEffect } from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../modules";
import { initState } from "../modules/step";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useStep() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initState());
  }, []);

  const current = typedUseSelector((state: RootState) => state.step.current);

  return { current };
}
