import React, { FC } from 'react'

import { LineChart, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts'
import { Card, Typography } from 'antd'

import useDashboard from '../../../hooks/dashboard/useDashboard'

const { Title } = Typography

const DashboardLatencyGraph: FC = () => {
  const { data } = useDashboard()

  return (
    <Card>
      <Title level={4}>Delayed Job</Title>
      {data && (
        <ResponsiveContainer width={'100%'} height={350}>
          <LineChart data={data.latency}>
            <Legend />
            <Line dataKey="count" stroke="#10239e" dot={false} />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  )
}

export default DashboardLatencyGraph
