import { useEffect } from "react";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../../../modules";
import { getTemplateGroups, getTemplateInfos } from "../../../lib/api/template";
import {
  loadGroups,
  changeGroup,
  TemplateInfo,
} from "../../../modules/createJob/template";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useTemplateGroups() {
  const dispatch = useDispatch();
  const loadTemplateInfos = async (group: string) => {
    try {
      const response: TemplateInfo[] = (await getTemplateInfos(group)).data;

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const loadTemplateGroups = async () => {
    try {
      const response = await getTemplateGroups();
      const templateInfos = await loadTemplateInfos(response.data[0]);
      // @ts-ignore
      dispatch(loadGroups(response.data, templateInfos));
    } catch (e) {
      alert(e);
    }
  };

  useEffect(() => {
    loadTemplateGroups();
  }, []);

  const groups = typedUseSelector(
    (state: RootState) => state.createJob.template.group
  );

  const onClickGroup = async (group: string) => {
    const templateInfos = await loadTemplateInfos(group);
    // @ts-ignore
    dispatch(changeGroup(group, templateInfos));
  };

  const currentKey = typedUseSelector(
    (state: RootState) => state.createJob.template.currentGroup
  );

  return { groups, currentKey, onClickGroup };
}
