import * as React from 'react'

import { Typography, Form } from 'antd'

import useForm from '../../../hooks/job/jobCreate/useForm'
import JobCreateFormSection from './JobCreateFormSection'
import JobCreateButtonOnSecond from './JobCreateButtons/JobCreateButtonOnSecond'
import useStepState from '../../../hooks/useStepState'
import { SectionName } from '../../../modules/createJob/form'
import useJOBPreview from '../../../hooks/job/jobCreate/useJOBPreview'
import useFormState from '../../../hooks/job/jobCreate/useFormState'

const { Title } = Typography

type JobCreateFormProps = {
  setForm: React.Dispatch<React.SetStateAction<any>>
}

type Section = {
  sectionName: SectionName
  name: string
  desc: string
}

const sections: Section[] = [
  {
    sectionName: 'basic_info',
    name: 'Basic Information',
    desc: 'JOB 생성을 위한 기본적인 정보입니다.',
  },
  {
    sectionName: 'context_param',
    name: 'Context Parameter',
    desc: '계정 정보 등 공통 정보입니다.',
  },
  {
    sectionName: 'job_param',
    name: 'Job Parameter',
    desc: '생성될 JOB에 필요한 정보입니다.',
  },
]

const JobCreateForm: React.FC<JobCreateFormProps> = ({ setForm }) => {
  const { templateID } = useForm()
  const { onClickNext, handleError } = useStepState()
  const { setJOBPreview } = useJOBPreview()
  const [form] = Form.useForm()
  const { template } = useFormState()
  const getInitialValues = () => {
    let value = {}
    Object.keys(template)?.map(section => {
      if (Array.isArray(template[section]) && template[section][0] !== '') {
        value[section] = {}
        template[section]?.map(item => (value[section][item.name] = item.value))
      }
    })
    return value
  }

  return (
    <section style={{ padding: '0 8px' }}>
      <Title level={3}>{templateID}</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          setForm(form.getFieldsValue)
          setJOBPreview(form.getFieldsValue())
          onClickNext()
        }}
        onFinishFailed={() => handleError()}
        initialValues={getInitialValues()}
      >
        {sections.map(section => (
          <JobCreateFormSection
            name={section.name}
            desc={section.desc}
            sectionName={section.sectionName}
            setFieldsValue={form.setFieldsValue}
            getFieldValue={form.getFieldValue}
          />
        ))}
        <JobCreateButtonOnSecond />
      </Form>
    </section>
  )
}

export default JobCreateForm
