import React, { FC } from "react";

import TemplateForm from "../TemplateForm";
import useTemplateDetail from "../../../hooks/template/useTemplateDetail";

const TemplateDetail: FC = () => {
  const { fields, isLoading } = useTemplateDetail();

  return <TemplateForm isDetail={true} fields={fields} isLoading={isLoading} />;
};

export default TemplateDetail;
