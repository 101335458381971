import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import useJSONViewer from '../JSONViewer/useJSONViewer'
import { getDetail } from '../../lib/api/listTable'
import useError from '../useError'

export default function useDetail() {
  const history = useHistory()
  const { onChangeJSON, cleanUpJSON, loading, setLoading } = useJSONViewer()
  const location = useLocation()
  const index = location.pathname.split('/')[2]
  const { handleError } = useError()

  useEffect(() => {
    getJobDetail()
  }, [])

  const getJobDetail = async () => {
    try {
      setLoading(true)
      cleanUpJSON()
      const response = await getDetail('/job', index)

      onChangeJSON(response.data)
      setLoading(false)
    } catch (e) {
      handleError(e)
      history.push('/job')
    }
  }

  return { loading }
}
