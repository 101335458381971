import React, { FC } from "react";

import { Row, Col, Typography } from "antd";

import DashboardStatus from "./DashboardStatus";
import DashboardLog from "./DashboardLog";
import DashboardGraph from "./DashboardGraph";

const { Title } = Typography;

const Dashboard: FC = () => {
  return (
    <Row style={{ margin: "24px" }} gutter={[0, 24]}>
      <Title level={4}>Today's Job Execution Stats</Title>
      <Col span={24}><DashboardStatus/></Col>
      <Col span={24}><DashboardGraph/></Col>
      <Col span={24}><DashboardLog/></Col>
    </Row>
  );
};

export default Dashboard;
