import { useState } from "react";
import moment from "moment";

export default function useCurrentTime() {
  const [time] = useState(moment(
    moment().format("YYYY-MM-DD HH:mm") + ":00",
    "YYYY-MM-DD" +
    " HH:mm:ss"
  ));

  return time;
}
