import { useLocation } from "react-router-dom";

import { Form } from "./type";

export default () => {
  const location = useLocation();

  return <Form[]>[
    {
      name: "file_name",
      required: true,
      editable: location.pathname === "/sourcecode",
      desc: "파일명을 입력해 주세요.",
      type: "text",
    },
    {
      name: "class_name",
      required: true,
      editable: location.pathname === "/sourcecode",
      desc: "클래스 이름을 입력해 주세요.",
      type: "text",
    },
    {
      name: "file",
      required: true,
      editable: true,
      desc: "파일을 첨부해 주세요.",
      type: "python",
    },
    {
      name: "desc",
      required: false,
      editable: true,
      desc: "Sourcecode에 대한 설명을 입력해 주세요",
      type: "text",
    },
  ];
};
