// Actions
const INITIALIZE_INPUT = "Auth/INITIALIZE_INPUT" as const;
const CHANGE_INPUT = "Auth/CHANGE_INPUT" as const;

// Actions Type
type AuthAction =
  | ReturnType<typeof initializeInput>
  | ReturnType<typeof changeInput>;

// State Type & Initial State
export type FieldData = {
  name: string[];
  value: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
};

type AuthFormState = {
  field: FieldData[];
};

const initialState: AuthFormState = {
  field: [
    { name: ["id"], value: "" },
    { name: ["password"], value: "" },
  ],
};

const auth = (state: AuthFormState = initialState, action: AuthAction) => {
  switch (action.type) {
    case INITIALIZE_INPUT:
      return initialState;
    case CHANGE_INPUT:
      return { field: action.payload };
    default:
      return state;
  }
};

export default auth;

// Action Creators
export const initializeInput = () => ({
  type: INITIALIZE_INPUT,
});

export const changeInput = (payload: FieldData[]) => ({
  type: CHANGE_INPUT,
  payload: payload,
});
