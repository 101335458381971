import React, { FC } from "react";

import { Form, AutoComplete, Input } from "antd";
import useTemplateGroup from "../../../hooks/template/useTemplateGroup";

type TemplateFormGroupProps = {
  editable: boolean;
};

const TemplateFormGroup: FC<TemplateFormGroupProps> = ({ editable }) => {
  const options = useTemplateGroup();

  return (
    <Form.Item name="template_group" label="template_group" required>
      <AutoComplete
        disabled={!editable}
        options={options}
        filterOption={(inputValue, option) => {
          if (option) return option.value.indexOf(inputValue) !== -1;
          return true;
        }}
      >
        <Input placeholder="template이 속한 그룹을 입력해 주세요." />
      </AutoComplete>
    </Form.Item>
  );
};

export default TemplateFormGroup;
