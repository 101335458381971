import React, { FC } from "react";
import ReactJson from "react-json-view";

import Modal from "./index";
import useMonitoringDetail from "../../hooks/detail/useMonitoringDetail";

type ModalDetailProps = {
  visible: boolean;
  handleOK: () => void;
  handleCancel: () => void;
}

const ModalDetail: FC<ModalDetailProps> = ({ visible, handleCancel, handleOK }) => {
  const { detail } = useMonitoringDetail();

  return (
    <Modal
      width={1000}
      title={"Monitoring Detail"}
      visible={visible}
      handleOK={() => {
        handleOK();
      }}
      handleCancel={() => {
        handleCancel();
      }}
      footer={null}
    >
      <ReactJson name={false}
                 enableClipboard={false}
                 displayDataTypes={false}
                 displayObjectSize={false}
                 src={detail}
                 style={{ wordBreak: "break-all" }}
      />
    </Modal>
  );
};

export default ModalDetail;
