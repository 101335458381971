import { useLocation } from "react-router-dom";
import { Form } from "./type";

export default () => {
  const location = useLocation();
  return <Form[]>[
    {
      name: "key",
      required: true,
      editable: location.pathname === "/job-context",
      desc: "Context Key를 입력해 주세요",
      type: "text"
    },
    {
      name: "value",
      required: true,
      editable: true,
      desc: "Context Value를 입력해 주세요",
      type: "text"
    },
    {
      name: "desc",
      required: true,
      editable: true,
      desc: "Context에 대한 설명을 입력해 주세요",
      type: "text"
    },
  ];
}
