import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'
import { Form, Modal } from 'antd'

import { getDetail } from '../../../lib/api/listTable'
import useJobTemporaryTemplate from './useJobTemporaryTemplate'
import { getTemplateOfJob } from '../../../lib/api/template'
import { RootState } from '../../../modules'
import { loadTemplate, initTemplate } from '../../../modules/createJob/template'
import { setForm, changeForm, SectionName, initForm } from '../../../modules/createJob/form'
import useError from '../../useError'

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector

export default function useJobEditForm() {
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [fields] = Form.useForm()
  const form = typedUseSelector((state: RootState) => state.createJob.form)
  const [modal, contextHolder] = Modal.useModal()
  const jobID = location.search.split('job_id=')[1]
  const sections: SectionName[] = ['basic_info', 'context_param', 'job_param']
  const { createTemporaryTemplate } = useJobTemporaryTemplate()
  const { handleError } = useError()

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      await fetchTemplate().then(() => {
        setFieldsValue()
      })
      setLoading(false)
    })()
    return () => {
      dispatch(initTemplate())
      dispatch(initForm())
    }
  }, [])

  useEffect(() => {
    setFieldsValue()
  }, [form])

  const fetchTemplate = async () => {
    try {
      const response = await getTemplateOfJob(jobID)
      const template = {
        ...response.data,
        basic_info: [...response.data.basic_info, ...editTemplate],
      }
      dispatch(setForm(template))
      dispatch(loadTemplate(template))
      await fetchJobValue()
    } catch (e) {
      if (e.response.status === 404) {
        modal.confirm({
          title: 'No Template',
          content:
            '해당 Job에 대한 Template이 존재하지 않아 수정 시 필수 입력 항목을 구분할 수 없습니다. 그래도 수정하시겠습니까?',
          onOk: fetchTemporaryTemplate,
          okText: 'Continue',
          onCancel: () => {
            history.goBack()
          },
        })
      } else {
        handleError(e)
        history.goBack()
      }
    }
  }

  const fetchTemporaryTemplate = async () => {
    let temporaryTemplate
    await getDetail('/job', jobID)
      .then(r => {
        temporaryTemplate = createTemporaryTemplate(r.data)
      })
      .then(() => {
        dispatch(setForm(temporaryTemplate))
        dispatch(loadTemplate(temporaryTemplate))
      })
  }

  const fetchJobValue = async () => {
    try {
      const response = await getDetail('/job', jobID)
      sections.map(section => {
        setFormValue(response.data, section)
      })
    } catch (e) {
      handleError(e)
      history.goBack()
    }
  }

  const setFormValue = (response: object, sectionName: SectionName) => {
    if (response[sectionName]) {
      Object.entries(response[sectionName]).map(item => {
        dispatch(
          changeForm({
            sectionName: sectionName,
            name: item[0],
            value: `${item[1]}`,
          }),
        )
      })
    }
  }

  const onFieldsChange = (name: string, value: string) => {
    const newField = {}
    newField[`${name}`] = value
    fields.setFieldsValue(newField)
  }

  const setFieldsValue = () => {
    sections.map(section => {
      fields.setFieldsValue(convertFormToFields(section))
    })
  }

  const convertFormToFields = (sectionName: string) => {
    const newFields = {}
    Object.entries(form[sectionName]).map(field => {
      newFields[`${sectionName}/${field[0]}`] = field[1]
    })
    return newFields
  }

  const convertFieldsToForm = async () => {
    const fieldsValue = fields.getFieldsValue()
    await Object.entries(fieldsValue).map((field: any) => {
      const key: [SectionName, string] = field[0].split('/')
      dispatch(
        changeForm({
          sectionName: key[0],
          name: key[1],
          value: field[1],
        }),
      )
    })
  }

  return {
    fields,
    onFieldsChange,
    convertFieldsToForm,
    getFieldsValue: (name: string) => fields.getFieldValue(name),
    loading,
    contextHolder,
  }
}

const editTemplate = [
  {
    name: 'argo_id',
    value: '',
    editable: true,
    required: true,
    type: 'pop_up',
    desc: '담당자의 Argo ID',
  },
  {
    name: 'created_time',
    value: '',
    editable: false,
    required: true,
    type: 'text',
    desc: 'Job이 생성된 시간',
  },
  {
    name: 'modified_time',
    value: '',
    editable: false,
    required: true,
    type: 'text',
    desc: 'Job이 마지막으로 수정된 시간',
  },
]
