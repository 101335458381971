import React, { FC } from "react";

import ModalTableForm from "./ModalTableForm";
import Modal from "../index";
import useModalTable from "../../../hooks/modal/useModalTable";
import { getKeyOfValue } from "../../../modules/modaltable";
import { SectionName } from "../../../modules/createJob/form";

type InternalNamePath = (string | number)[];
export type NamePath = string | number | InternalNamePath;

type ModalTableProps = {
  visible: boolean;
  handleOK: () => void;
  handleCancel: () => void;
  name: NamePath;
  sectionName: SectionName;
  selectedKey: any[];
  onFieldsChange: (name: NamePath, value: string) => void;
  type: string;
};

const ModalTemplateTable: FC<ModalTableProps> = ({
  visible,
  handleCancel,
  handleOK,
  name,
  sectionName,
  selectedKey,
  onFieldsChange,
  type,
}) => {
  const { currentSelectedRow, onRowChange } = useModalTable(selectedKey, visible);

  const onClickOK = () => {
    if (sectionName === "basic_info") {
      onFieldsChange([name[0] - 1, name[1]], getKeyOfValue("owner_id", currentSelectedRow[0]));
    }
    onFieldsChange(name, getKeyOfValue(type, currentSelectedRow[0]));
    handleOK();
  };

  return (
    <Modal
      width={800}
      title={type}
      visible={visible}
      handleOK={onClickOK}
      handleCancel={handleCancel}
    >
      <ModalTableForm type={type} selectedRow={currentSelectedRow} onRowChange={onRowChange} />
    </Modal>
  );
};

export default ModalTemplateTable;
