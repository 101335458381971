// Actions
const OPEN_FILTER = "filtering/OPEN_FILTER" as const; // 아마 필터가 열렸을떄를 의미하는것 같다
const CLOSE_FILTER = "filtering/CLOSE_FILTER" as const; // 필터가 닫혔을때 의미하는것 같다
const CHANGE_FILTER_VALUE = "filtering/CHANGE_FILTER_VALUE" as const; // 목록에서 내용이 바뀔떄??
const INIT_FILTER_VALUE = "filtering/INIT_FILTER_VALUE" as const; // 처음에 열리면서 내용 초기화 ??

// Actions Type
type FilteringAction =
  | ReturnType<typeof openFilter>
  | ReturnType<typeof closeFilter>
  | ReturnType<typeof changeFilterValue>
  | ReturnType<typeof initFilterValue>;

// Action Creators
export const openFilter = () => ({
  type: OPEN_FILTER,
});

export const closeFilter = () => ({
  type: CLOSE_FILTER,
});

export const changeFilterValue = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => ({
  type: CHANGE_FILTER_VALUE,
  payload: {
    name,
    value,
  },
});

export const initFilterValue = () => ({
  type: INIT_FILTER_VALUE,
});

// State Type & Initial State
type FilteringState = {
  isOpen: boolean;
  filter: {
    [key: string]: any;
  };
};

const initialState: FilteringState = {
  isOpen: false, // 여기서 isOpen이 필터가 열려있는지 닫혀 있는지를 따지는 것
  filter: {},
};

// Reducer
const filtering = (
  state: FilteringState = initialState,
  action: FilteringAction
) => {
  switch (action.type) {
    case OPEN_FILTER:
      return { isOpen: true, filter: state.filter };
    case CLOSE_FILTER:
      return { isOpen: false, filter: state.filter };
    case CHANGE_FILTER_VALUE:
      const newFilter = { ...state.filter };

      if (!action.payload.value) {
        delete newFilter[action.payload.name];

        return { isOpen: state.isOpen, filter: newFilter };
      } else {
        if (action.payload.name === "run_time") {
          newFilter[action.payload.name] = parseFloat(action.payload.value);
        } else {
          newFilter[action.payload.name] = action.payload.value;
        }

        return { isOpen: state.isOpen, filter: newFilter };
      }
    case INIT_FILTER_VALUE:
      return initialState;

    default:
      return state;
  }
};

export default filtering;
