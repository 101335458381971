import React from 'react'

import TableSearchFilter from '../../components/Table/TableSearchFilter'
import TableSelectFilter from '../../components/Table/TableSelectFilter'
import { Table } from './type'
import { convertEmptyString, formatTime } from '../../utils/tableDataRenderer'

export default {
  path: '/monitoring',
  detail: 'idx',
  pagination: false,
  columns: [
    {
      title: 'Index',
      dataIndex: 'idx',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="idx" placeholder="Search Index" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Job Name',
      dataIndex: 'job_name',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="job_name" placeholder="Search Job Name" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Schedule Time',
      dataIndex: 'schedule_time',
      render: text => formatTime(text),
      filterDropdown: <TableSearchFilter name="schedule_time" placeholder="Search Schedule Time" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Run Time',
      dataIndex: 'run_time',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="run_time" placeholder="Search Run Time" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Log Level',
      dataIndex: 'log_level',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSelectFilter name="log_level" items={['100', '200', '300', '400']} />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
    {
      title: 'Log Message',
      dataIndex: 'log_msg',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="log_msg" placeholder="Search Log Message" />,
    },
    {
      title: 'Result Log',
      dataIndex: 'result_log',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="result_log" placeholder="Search Result Log" />,
    },
    {
      title: 'Owner ID',
      dataIndex: 'owner_id',
      render: text => convertEmptyString(text),
      filterDropdown: <TableSearchFilter name="owner_id" placeholder="Search Owner ID" />,
      sorter: {
        compare: (_a, _b) => 0,
        multiple: 0,
      },
    },
  ],
} as Table
