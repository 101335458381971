import moment from "moment";

export default [
  {
    case: 0,
    text: "한 번만 수행"
  },
  {
    case: 1,
    text: "특정 주기로 수행"
  },
  {
    case: 2,
    text: "특정 요일마다 수행"
  },
  {
    case: 3,
    text: "특정 일에 수행"
  },
  {
    case: 4,
    text: "사용자 설정"
  },
];

export const defaultSet = [
  { case: 0 },
  {
    case: 1,
    value: {
      hour: 0,
      min: 0
    }
  }, {
    case: 2,
    value: {
      day: [],
      time: moment(
        "00:00",
        "HH:mm"
      )
    }
  }, {
    case: 3,
    value: {
      date: 1,
      time: moment(
        "00:00",
        "HH:mm"
      )
    }
  }, {
    case: 4,
    value: ""
  }
];
