import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getDetail } from '../../lib/api/listTable'
import {
  ProductWithJobList,
  ContextWithJobList,
  Path,
  SourcecodeWithJobList,
} from '../../constants/detail/type'
import useError from '../useError'

export default function useDetailWithRef(path: Path) {
  const location = useLocation()
  const { handleError } = useError()
  const [detail, setDetail] = useState<
    ProductWithJobList | ContextWithJobList | SourcecodeWithJobList
  >(getInitialState(path))

  const getDetailData = async () => {
    try {
      const response = await getDetail(path, location.pathname.split('/')[2])
      setDetail(response.data)
    } catch (e) {
      handleError(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      await getDetailData()
    })()
    return () => {
      setDetail(getInitialState(path))
    }
  }, [])

  return { detail }
}

const getInitialState: (
  path: Path,
) => ProductWithJobList | ContextWithJobList | SourcecodeWithJobList = path => {
  switch (path) {
    case '/product':
      return {
        product_id: '',
        owner_id: '',
        product_name: '',
        desc: '',
        industry: '',
        job_list: [],
      } as ProductWithJobList
    case '/context_param':
      return {
        key: '',
        value: '',
        desc: '',
        job_list: [],
      } as ContextWithJobList
    case '/sourcecode':
      return {
        source_id: '',
        argo_id: '',
        class_path: '',
        created_time: '',
        modified_time: '',
        desc: '',
        job_list: [],
      } as SourcecodeWithJobList
  }
}
