import React, { FC } from "react";

import { Descriptions } from "antd";

import TableDetailButtons from "./TableDetailButtons";
import {
  ContextWithJobList,
  DetailWithRef,
  ProductWithJobList,
  SourcecodeWithJobList,
} from "../../../constants/detail/type";
import { Form } from "../../../constants/form/type";

type ContextDetailBasicInfoProps = {
  form: DetailWithRef;
  data: ProductWithJobList | ContextWithJobList | SourcecodeWithJobList;
  modalForm: () => Form[];
};

const TableDetailBasicInfo: FC<ContextDetailBasicInfoProps> = ({ data, form, modalForm }) => (
  <Descriptions
    column={1}
    bordered
    size="middle"
    title={form.title}
    extra={<TableDetailButtons modalForm={modalForm} form={form} />}
    style={{ wordBreak: "break-all" }}
  >
    {form.info.map(item => (
      <Descriptions.Item label={item.label}>{data[item.dataIndex]}</Descriptions.Item>
    ))}
  </Descriptions>
);

export default TableDetailBasicInfo;
