import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import { RootState } from "../../../modules";
import { changeForm } from "../../../modules/createJob/form";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useFormState() {
  const dispatch = useDispatch();

  const form = typedUseSelector((state: RootState) => state.createJob.form);
  const template = typedUseSelector(
    (state: RootState) => state.createJob.template.template
  );

  const onChangeForm = (
    sectionName: "basic_info" | "context_param" | "job_param",
    name: string,
    value: string
  ) =>
    dispatch(
      changeForm({ sectionName: sectionName, name: name, value: value })
    );

  return { form, template, onChangeForm };
}
