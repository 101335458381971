import React, { FC } from 'react'

import { Form, Radio } from 'antd'

import { SectionName } from '../../../modules/createJob/form'
import periods, { defaultSet } from '../../../constants/schedule/periods'
import InputPeriodCycle from './InputPeriodCycle'
import InputPeriodWeek from './InputPeriodWeek'
import InputPeriodMonth from './InputPeriodMonth'
import InputPeriodCustom from './InputPeriodCustom'

type InputPeriodProps = {
  required: boolean
  editable: boolean
  name: string
  DESC: string
  sectionName?: SectionName
  getFieldValue: (name: string) => any
  onFieldsChange: (name: string, value: any) => void
}

export const currentTime = new Date().toISOString().replace('T', ' ').split('.')[0]

const InputPeriod: FC<InputPeriodProps> = ({
  required,
  editable,
  name,
  sectionName,
  getFieldValue,
  onFieldsChange,
}) => {
  return (
    <Form.Item
      label={name}
      required
      rules={[
        {
          required: editable && required,
          message: `'${name}' is required`,
        },
      ]}
    >
      <Form.Item
        name={[sectionName ? `${sectionName}/${name}` : `${name}`, 'case']}
        rules={[
          {
            required: editable && required,
            message: `'${name}' is required`,
          },
        ]}
        noStyle
      >
        <Radio.Group
          defaultValue={0}
          onChange={e => {
            onFieldsChange('period', defaultSet[e.target.value])
          }}
        >
          {periods.map(period => (
            <Radio
              style={{
                display: 'block',
                height: '30px',
                lineHeight: '30px',
              }}
              value={period.case}
            >
              {period.text}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() => {
          if (getFieldValue('period')) {
            return (
              <div style={{ marginTop: '12px' }}>
                {getPeriodInput(getFieldValue('period').case)}
              </div>
            )
          }
        }}
      </Form.Item>
    </Form.Item>
  )
}

const getPeriodInput = (type: number) => {
  switch (type) {
    case 0:
      return null
    case 1:
      return <InputPeriodCycle />
    case 2:
      return <InputPeriodWeek />
    case 3:
      return <InputPeriodMonth />
    case 4:
      return <InputPeriodCustom />
    default:
      return null
  }
}

export default InputPeriod
