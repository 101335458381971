// Actions
const LOAD_SCHEDULE = "scheduleForm/LOAD_SCHEDULE" as const;
const UPDATE_SCHEDULE = "scheduleForm/UPDATE_SCHEDULE" as const;
const UPDATE_PERIOD = "scheduleForm/UPDATE_PERIOD" as const;
const INIT_FORM = "scheduleForm/INIT_FORM" as const;
const SET_FROM_DETAIL = "scheduleForm/SET_FROM_DETAIL" as const;
const SET_SCHEDULE = "scheduleForm/SET_SCHEDULE" as const;

// Actions Type
type ScheduleFormAction =
  | ReturnType<typeof loadSchedule>
  | ReturnType<typeof updateSchedule>
  | ReturnType<typeof updatePeriod>
  | ReturnType<typeof initForm>
  | ReturnType<typeof setFromDetail>
  | ReturnType<typeof setSchedule>;

// State Type & Initial State
type ScheduleFormKey =
  | "job_id"
  | "job_name"
  | "scheduled_time"
  | "expired_time"
  | "status"
  | "period";

type PeriodKey = "case" | "value";

type ScheduleFormState = {
  job_id: string;
  job_name: string;
  argo_id: string;
  scheduled_time: string;
  expired_time: string;
  status: string;
  period: {
    case: number;
    value: any;
  };
  parsed_period: string;
  from_detail: {
    job_id: string;
    job_name: string;
  };
  origin_time: string;
  modified_time: string;
};

const initialState: ScheduleFormState = {
  job_id: "",
  job_name: "",
  argo_id: "",
  scheduled_time: new Date().toISOString().substring(0, 19),
  expired_time: "",
  status: "enable",
  period: {
    case: 0,
    value: new Date().toISOString().substring(0, 19)
  },
  parsed_period: "",
  from_detail: {
    job_id: "",
    job_name: ""
  },
  origin_time: "",
  modified_time: ""
};

// Reducer
const scheduleForm = (
  state: ScheduleFormState = initialState,
  action: ScheduleFormAction
) => {
  switch (action.type) {
    case LOAD_SCHEDULE:
      return { ...state, ...action.payload };
    case UPDATE_SCHEDULE:
      let newSchedule = { ...state };
      newSchedule[action.payload.name] = action.payload.value;

      return { ...state, ...newSchedule };
    case UPDATE_PERIOD:
      let newPeriod = { ...state.period };
      newPeriod[action.payload.name] = action.payload.value;

      return { ...state, period: { ...state.period, ...newPeriod } };
    case SET_FROM_DETAIL:
      return {
        ...state,
        from_detail: {
          ...state.from_detail,
          job_id: action.payload.job_id,
          job_name: action.payload.job_name
        }
      };
    case SET_SCHEDULE:
      return {
        ...state,
        ...action.payload
      };
    case INIT_FORM:
      return { ...initialState };
    default:
      return state;
  }
};

export default scheduleForm;

// Actions
export const loadSchedule = (payload: ScheduleFormState) => ({
  type: LOAD_SCHEDULE,
  payload: payload
});

export const updateSchedule = (payload: {
  name: ScheduleFormKey;
  value: any;
}) => ({
  type: UPDATE_SCHEDULE,
  payload: payload
});

export const updatePeriod = (payload: { name: PeriodKey; value: any }) => ({
  type: UPDATE_PERIOD,
  payload: payload
});

export const initForm = () => ({
  type: INIT_FORM
});

export const setFromDetail = (payload: {
  job_id: string;
  job_name: string;
}) => ({
  type: SET_FROM_DETAIL,
  payload: payload
});

type ScheduleFormResponse = {
  job_id: string;
  job_name: string;
  argo_id: string;
  scheduled_time: string;
  expired_time: string;
  status: string;
  period: {
    case: number;
    value: any;
  };
  parsed_period: string;
  origin_time: string;
  modified_time: string;
};

export const setSchedule = (payload: ScheduleFormResponse) => ({
  type: SET_SCHEDULE,
  payload: payload
});
