import { DetailWithRef } from './type'

export default <DetailWithRef>{
  title: 'Product',
  info: [
    {
      label: 'Product ID',
      dataIndex: 'product_id',
    },
    {
      label: 'Owner ID',
      dataIndex: 'owner_id',
    },
    {
      label: 'Product Name',
      dataIndex: 'product_name',
    },
    {
      label: 'Industry',
      dataIndex: 'industry',
    },
    {
      label: 'Description',
      dataIndex: 'desc',
    },
  ],
  path: '/product',
}
