import React, { FC } from "react";

import { Button, Form } from "antd";

import { EditOutlined } from "@ant-design/icons";

import useScheduleEditButtons from "../../../hooks/schedule/scheduleEdit/useScheduleEditButtons";

export type ScheduleEditButtonsProps = {
  loading: boolean;
};

const ScheduleEditButton: FC<ScheduleEditButtonsProps> = ({ loading }) => {
  const { onClickPrevious, contextHolder } = useScheduleEditButtons();

  return (
    <section style={{ marginTop: "24px", textAlign: "right" }}>
      <Form.Item style={{ display: "inline" }}>
        <Button onClick={() => onClickPrevious()}>Previous</Button>
        <Button
          loading={loading}
          style={{ marginLeft: "8px" }}
          icon={<EditOutlined />}
          type="primary"
          htmlType="submit"
        >
          Edit
        </Button>
      </Form.Item>
      {contextHolder}
    </section>
  );
};
export default ScheduleEditButton;
