import React, { FC } from "react";
import * as L from "./index";
import { Layout, Layout as LayoutComponent } from "antd";

const { Content } = Layout;

type CustomLayoutProps = {
  isDashboard?: true;
}

const CustomLayout: FC<CustomLayoutProps> = ({ isDashboard, children }) => (
  <LayoutComponent style={{
    fontFamily: "Source Sans Pro",
    scrollBehavior: "smooth"
  }}>
    <L.Sidebar/>
    <LayoutComponent className="site-layout">
      <L.Header/>
      {isDashboard ? <>{children}</> : (
        <Content
          className="site-layout-background"
          style={{
            margin: "24px",
            padding: "24px"
          }}
        >
          {children}
        </Content>
      )}
      <L.Footer/>
    </LayoutComponent>
  </LayoutComponent>
);

export default CustomLayout;
