import * as React from "react";

import { Menu as MenuComponent, Dropdown, Avatar } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";

import useUser from "../../hooks/useUser";

const Menu = ({ logout }) => (
  <MenuComponent>
    <MenuComponent.Item onClick={() => logout()}>
      <LogoutOutlined/>
      Logout
    </MenuComponent.Item>
  </MenuComponent>
);

const UserDropdown: React.FC = () => {
  const { logout, name, picture } = useUser();

  return (
    <Dropdown
      overlay={<Menu logout={logout}/>}
      trigger={["click"]}
      placement="bottomRight"
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Avatar src={picture} size="small"
                style={{ marginRight: "4px" }}/> {name} <DownOutlined/>
      </a>
    </Dropdown>
  );
};

export default UserDropdown;
