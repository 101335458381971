import React, { FC } from 'react'

import ModalTableForm from './ModalTableForm'
import ModalJobTableForm from './ModalJobTableForm'
import Modal from '../index'
import useModalTable from '../../../hooks/modal/useModalTable'
import { getKeyOfValue } from '../../../modules/modaltable'

type ModalTableProps = {
  visible: boolean
  handleOK: () => void
  handleCancel: () => void
  name: string
  selectedKey: any[]
  onFieldsChange: (name: string, value: string) => void
  sectionName?: string
  setFieldsValue?: (value: any) => void
}

const ModalTable: FC<ModalTableProps> = ({
  visible,
  handleCancel,
  handleOK,
  name,
  selectedKey,
  onFieldsChange,
  sectionName,
  setFieldsValue,
}) => {
  const { currentSelectedRow, onRowChange } = useModalTable(selectedKey, visible)

  const onClickOK = () => {
    if (name === 'product_id') {
      onFieldsChange(`${sectionName}/owner_id`, getKeyOfValue('owner_id', currentSelectedRow[0]))
    }
    onFieldsChange(
      sectionName ? `${sectionName}/${name}` : `${name}`,
      getKeyOfValue(name, currentSelectedRow[0]),
    )
    if (setFieldsValue) {
      let newValue = {}
      if (name === 'product_id') {
        newValue = {
          basic_info: {
            owner_id: getKeyOfValue('owner_id', currentSelectedRow[0]),
            product_id: getKeyOfValue('product_id', currentSelectedRow[0]),
          },
        }
      }
      if (sectionName === 'context_param') {
        newValue = {
          context_param: {},
        }
        newValue['context_param'][name] = getKeyOfValue(name, currentSelectedRow[0])
      }
      setFieldsValue(newValue)
    }
    handleOK()
  }

  return (
    <Modal
      width={800}
      title={name}
      visible={visible}
      handleOK={onClickOK}
      handleCancel={handleCancel}
    >
      {getTable(name, currentSelectedRow, onRowChange)}
    </Modal>
  )
}

export default ModalTable

const getTable = (name: string, currentSelectedRow, onRowChange) => {
  switch (name) {
    case 'job_id':
      return <ModalJobTableForm selectedRow={currentSelectedRow} onRowChange={onRowChange} />
    default:
      return (
        <ModalTableForm type={name} selectedRow={currentSelectedRow} onRowChange={onRowChange} />
      )
  }
}
