import * as React from "react";

import { Layout } from "antd";

const { Footer: FooterComponent } = Layout;

const Footer: React.FC = () => (
  <FooterComponent style={{ textAlign: "right" }}>
    © 2020 <a href="https://www.madup.com/">MADUP</a>
  </FooterComponent>
);

export default Footer;
