import { useLocation } from "react-router-dom";

type Form = {
  name: string;
  required: boolean;
  editable: boolean;
  desc: string;
  type: string;
  options?: [string, boolean][];
};

const ScheduleForm: () => Form[] = () => {
  const location = useLocation();
  return [
    {
      name: "job_id",
      required: true,
      editable: !(location.pathname === "/schedule/edit"),
      desc: "Job을 선택해 주세요",
      type: "pop_up",
    },
    {
      name: "status",
      required: true,
      editable: true,
      desc: "Schedule의 상태를 설정해 주세요",
      type: "dropdown",
      options: [
        ["enable", true],
        ["disable", true],
        ["wait", false],
        ["run", false],
        ["expired", false],
        ["fail", false],
      ],
    },
    {
      name: "scheduled_time",
      required: true,
      editable: true,
      desc: "Job의 시작 시간을 설정해 주세요",
      type: "datetime",
    },
    {
      name: "expired_time",
      required: false,
      editable: true,
      desc: "Job의 만료 시간을 정해 주세요",
      type: "datetime",
    },
    {
      name: "period",
      required: true,
      editable: true,
      desc: "주기를 설정해 주세요",
      type: "period",
    },
  ];
};

export default ScheduleForm;
