import { useState } from 'react'

import { submitNewJob } from '../../../lib/api/job'
import useStepState from './../../useStepState'
import useError from '../../useError'

export default function useJobAPI() {
  const [job, setJob] = useState('')
  const { onClickDone } = useStepState()
  const { handleError } = useError()

  const createJob = async form => {
    try {
      const response = await submitNewJob(form)
      setJob(`${response.data.job_name} (${response.data.job_id})`)
      await onClickDone()
    } catch (e) {
      handleError(e)
    }
  }

  return {
    createJob,
    job,
  }
}
