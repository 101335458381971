import React, { FC } from "react";

import Table from "./../../Table";
import { jobTable } from "../../../constants/table";

const JobList: FC = () => (
  <div>
    <Table table={jobTable}/>
  </div>
);

export default JobList;
