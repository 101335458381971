import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { getUserInfo, postUserInfo } from '../../lib/api/auth'
import useError from '../useError'
import { updateUserInfo, login, initUserInfo } from '../../modules/user'
import { setToken } from '../../lib/token'

export default function useOAuth() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const location = useLocation()
  const history = useHistory()
  const { handleError } = useError()

  useEffect(() => {
    ;(async () => {
      if (location.hash.length !== 0) {
        setIsLoading(true)
        const userInfo = await getUserInfoFromGoogle()
        // console.log('userInfo', userInfo)
        await submitUserInfo(userInfo)
        history.replace('/')
      }
    })()
  }, [])

  const getUserInfoFromGoogle = async () => {
    try {
      const accessToken = location.hash.split('#access_token=')[1].split('&')[0]
      // console.log('google accessToken:', accessToken)
      const response = (await getUserInfo(accessToken)).data
      dispatch(updateUserInfo(response.name, response.picture))
      return {
        name: response.name,
        id: response.email,
        password: response.id,
      }
    } catch (e) {
      handleError(e)
    }
  }

  const submitUserInfo = async userInfo => {
    try {
      const response = await postUserInfo(userInfo)
      dispatch(login())
      setToken(response.data.access_token)
    } catch (e) {
      handleError(e)
      dispatch(initUserInfo())
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading }
}
