import React, { FC } from 'react'

import { Form, Input } from 'antd'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import useModalAPI from '../../../hooks/modal/useModalAPI'
import useModal from '../../../hooks/modal/useModal'
import ModalTemplateTable, { NamePath } from '../../Modal/ModalTable/ModalTemplateTable'
import { FormInstance } from 'antd/lib/form'
import { SectionName } from '../../../modules/createJob/form'

type TemplateFormPopupProps = {
  form: FormInstance
  field: any
  name: string
  required: boolean
  desc: string
  editable: boolean
  sectionName: SectionName
}

const TemplateFormPopup: FC<TemplateFormPopupProps> = ({
  form,
  field,
  name,
  required,
  desc,
  editable,
  sectionName,
}) => {
  const { onClick, loading } = useModalAPI(name, '')
  const { handleOK, handleCancel, visible, showModal } = useModal()

  const onFieldsChange = (name: NamePath, value: string) => {
    const newField = {}
    let originalField = form.getFieldsValue([sectionName])[sectionName]
    originalField.splice(name[0], 1, { ...originalField[name[0]], value: value })
    // @ts-ignore
    newField[sectionName] = [...originalField]

    form.setFieldsValue(newField)
  }

  return (
    <>
      <Form.Item
        {...field}
        label={name}
        name={[field.name, name]}
        fieldKey={[field.fieldKey, name]}
        rules={[
          {
            required: required,
            message: `Missing ${name}`,
          },
        ]}
      >
        <Input
          placeholder={desc}
          disabled={true}
          addonAfter={
            loading ? (
              <LoadingOutlined />
            ) : (
              <SearchOutlined
                onClick={async () => {
                  if (editable) {
                    await onClick()
                    showModal()
                  }
                }}
                style={{ cursor: editable ? 'pointer' : 'not-allowed' }}
              />
            )
          }
        />
      </Form.Item>
      <ModalTemplateTable
        visible={visible}
        handleOK={handleOK}
        handleCancel={handleCancel}
        name={[field.name, name]}
        selectedKey={form.getFieldValue('')}
        onFieldsChange={onFieldsChange}
        sectionName={sectionName}
        type={sectionName === 'context_param' ? 'context_param' : 'product_id'}
      />
    </>
  )
}

export default TemplateFormPopup
