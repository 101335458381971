// Actions
const OPEN_ALERT = "alert/OPEN_ALERT" as const;
const CLOSE_ALERT = "alert/CLOSE_ALERT" as const;

// Action type
type AlertAction = ReturnType<typeof openAlert> | ReturnType<typeof closeAlert>;

// Initial state, state type
type AlertState = {
  isOpen: boolean;
  value: string;
};

const initialState: AlertState = {
  isOpen: false,
  value: "Error!"
};

// Reducer
const alert = (state: AlertState = initialState, action: AlertAction) => {
  switch (action.type) {
    case OPEN_ALERT:
      return { isOpen: true, value: action.payload };
    case CLOSE_ALERT:
      return initialState;
    default:
      return state;
  }
};

export default alert;

// Action Creator
export const openAlert = (payload: string) => ({
  type: OPEN_ALERT,
  payload: payload
});

export const closeAlert = () => ({
  type: CLOSE_ALERT
});
