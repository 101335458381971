import { useState } from "react";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import { useLocation } from "react-router-dom";

import { getTableData } from "../../lib/api/modal";
import { RootState } from "../../modules";
import { setModalTable } from "../../modules/modaltable";
import useError from "../useError";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useModalAPI(type: string, search: string) {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const table = typedUseSelector((state: RootState) => state.modaltable);
  const { handleError } = useError();
  const location = useLocation();

  const getPathWithType = () => {
    switch (type) {
      case "product_id":
        return "/product";
      case "argo_id":
        return "/auth";
      case "job_id":
        return "/job";
      default:
        return "/context_param";
    }
  };

  const loadTableData = async () => {
    try {
      const response = await getTableData(getPathWithType(), search);

      dispatch(setModalTable(response.data, type));
    } catch (e) {
      handleError(e);
    }
  };

  const onClick = async () => {
    try {
      setLoading(true);
      if (location.pathname.indexOf("schedule") === -1) await loadTableData();
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return {
    table,
    onClick,
    loading,
  };
}
