import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Descriptions } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { propEq } from 'ramda'

import VerticalTabs from '../../VerticalTabs'
import './style.scss'
import { ReportField, Channel } from '../../../lib/api/report'
import ReportNameModal from './ReportNameModal'

type ReportTypeSelectProps = {
  setStep: (step: number) => void
  setReportField: (reportField: ReportField) => void
  channelID: string | number
  setChannelID: (channelID: string | number) => void
  channels: Array<Channel>
  setReportInfo: (reportInfo: { name: string; type: string }) => void
  reportInfo: { name: string; type: string }
}

const ReportTypeSelect: FC<ReportTypeSelectProps> = ({
  setStep,
  setReportField,
  channelID,
  setChannelID,
  channels,
  setReportInfo,
  reportInfo,
}) => {
  const history = useHistory()
  const [visible, setVisible] = useState<boolean>(false)
  const reportTypes = channels.filter(propEq('id', channelID))[0].channel_info
  const channelInfo = reportTypes.filter(propEq('report_type', reportInfo.type))[0]

  return (
    <section className="report-type-wrapper">
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        size="large"
        style={{ margin: '0 0 16px 8px' }}
        onClick={() => {
          history.goBack()
        }}
      />
      <VerticalTabs
        currentGroup={`${channelID}`}
        onClickGroup={group => {
          setChannelID(parseInt(`${group}`))
          if (reportTypes.length > 0) {
            setReportInfo({ name: '', type: '' })
          }
        }}
        groups={channels?.map(group => ({
          label: group.name,
          value: group.id,
        }))}
        currentItem={reportInfo.type}
        onClickItem={item => {
          setReportInfo({
            name: reportTypes.filter(propEq('report_type', item))[0].report_name,
            type: `${item}`,
          })
        }}
        items={reportTypes.map(info => ({
          label: info.report_type,
          value: info.report_type,
        }))}
      >
        <Descriptions title="Report Job Information" bordered column={1}>
          <Descriptions.Item label="Report Name" span={1}>
            {channelInfo?.report_name}
          </Descriptions.Item>
          <Descriptions.Item label="Group" span={1}>
            {channelInfo?.report_group}
          </Descriptions.Item>
          <Descriptions.Item label="Sub Report Type" span={1}>
            {channelInfo?.sub_report_type}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={1}>
            {channelInfo?.report_desc}
          </Descriptions.Item>
        </Descriptions>
      </VerticalTabs>
      <Button
        type="primary"
        className="next-button"
        onClick={() => {
          setVisible(true)
        }}
      >
        Next
      </Button>
      {visible && (
        <ReportNameModal
          visible={visible}
          setVisible={setVisible}
          setStep={setStep}
          setReportField={setReportField}
          reportInfo={reportInfo}
          channelID={channelID}
          setReportInfo={setReportInfo}
        />
      )}
    </section>
  )
}

export default ReportTypeSelect
