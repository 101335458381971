import axios from 'axios'

import baseUrl from './baseurl'

export const postUserInfo = async userInfo =>
  await axios.post(`${baseUrl}/auth`, userInfo, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

export const getUserInfo = async (accessToken: string) =>
  await axios.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
