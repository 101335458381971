import React, { FC } from "react";

import { Form, Typography, Spin, message } from "antd";

import { SectionName } from "../../../modules/createJob/form";
import JobEditButtons from "./JobEditButtons";
import JobEditFormSection from "./JobEditFormSection";
import useJobEditForm from "../../../hooks/job/jobEdit/useJobEditForm";
import useJobEditButtons from "../../../hooks/job/jobEdit/useJobEditButtons";

const { Title } = Typography;

const JobEdit: FC = () => {
  const {
    fields,
    onFieldsChange,
    getFieldsValue,
    loading,
    contextHolder,
  } = useJobEditForm();
  const {
    onClickEdit,
    setLoading,
    onClickGoBack,
    loading: buttonLoading,
  } = useJobEditButtons(fields);

  return (
    <Spin spinning={loading}>
      <section style={{ padding: "0 8px" }}>
        <Title level={3}>Job Edit</Title>
        <Form
          layout={"vertical"}
          form={fields}
          onFinish={async () => {
            await onClickEdit();
          }}
          onFinishFailed={(e) => {
            setLoading(false);
            message.error(`${e.errorFields[0].errors}`);
          }}
        >
          {sections.map((section) => (
            <JobEditFormSection
              name={section.name}
              desc={section.desc}
              sectionName={section.sectionName}
              onFieldChange={onFieldsChange}
              getFieldValue={getFieldsValue}
            />
          ))}
          <JobEditButtons
            fields={fields}
            loading={buttonLoading}
            onClickGoBack={() => {
              onClickGoBack();
            }}
          />
        </Form>
      </section>
      {contextHolder}
    </Spin>
  );
};

export default JobEdit;

type Section = {
  sectionName: SectionName;
  name: string;
  desc: string;
};

const sections: Section[] = [
  {
    sectionName: "basic_info",
    name: "Basic Information",
    desc: "Basic information of Job",
  },
  {
    sectionName: "context_param",
    name: "Context Parameter",
    desc: "Common information such as account information",
  },
  {
    sectionName: "job_param",
    name: "Job Parameter",
    desc: "Parameter required for Job",
  },
];
