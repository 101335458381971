import { useLocation } from 'react-router-dom'

import { Form } from './type'
import useOwnerList from '../../hooks/product/useOwnerList'

export default () => {
  const location = useLocation()
  const ownerList = useOwnerList()

  return <Form[]>[
    {
      name: 'owner_id',
      required: true,
      editable: location.pathname === '/product',
      desc: 'Owner ID를 입력해 주세요',
      type: 'auto',
      autoOptions: ownerList,
    },
    {
      name: 'product_id',
      required: true,
      editable: location.pathname === '/product',
      desc: 'Product ID를 입력해 주세요',
      type: 'text',
    },
    {
      name: 'product_name',
      required: true,
      editable: true,
      desc: 'Product Name을 입력해 주세요',
      type: 'text',
    },
    {
      name: 'industry',
      required: true,
      editable: true,
      desc: '산업군을 입력해 주세요',
      type: 'text',
    },
    {
      name: 'desc',
      required: true,
      editable: true,
      desc: 'Product에 대한 설명을 입력해 주세요',
      type: 'text',
    },
  ]
}
