import React, { FC } from 'react'

import { Tooltip } from 'antd'
import { Button } from '@madup-inc/mad-design'

import useJobScheduleButton from '../../../hooks/job/jobDetail/useJobScheduleButton'
import useJobEdit from '../../../hooks/job/jobDetail/useJobEdit'

import { CopyOutlined, ClockCircleOutlined, EditOutlined, DeleteFilled } from '@ant-design/icons'

type JobDetailButtonsProps = {
  setVisible: (state: boolean) => void
  onDeleteClick: () => void
}

const JobDetailButtons: FC<JobDetailButtonsProps> = ({ setVisible, onDeleteClick }) => {
  const { onButtonClick } = useJobScheduleButton()
  const { onEditClick } = useJobEdit()

  return (
    <div style={{ float: 'right' }}>
      <Tooltip title="clone" placement="bottom">
        <Button onClick={() => setVisible(true)} type="text">
          <CopyOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="schedule" placement="bottom">
        <Button onClick={() => onButtonClick()} type="text">
          <ClockCircleOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="edit" placement="bottom">
        <Button onClick={() => onEditClick()} type="text">
          <EditOutlined />
        </Button>
      </Tooltip>
      <Tooltip title="delete" placement="bottom">
        <Button onClick={() => onDeleteClick()} type="text" danger>
          <DeleteFilled />
        </Button>
      </Tooltip>
    </div>
  )
}

export default JobDetailButtons
