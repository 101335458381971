import React, { FC } from 'react'

import { Form, Select, Tooltip } from 'antd'

import { SectionName } from '../../modules/createJob/form'
import { convertScheduleStatus } from '../../utils/tableDataRenderer'

type InputDropdownProps = {
  required: boolean
  editable: boolean
  name: string
  DESC: string
  sectionName?: SectionName
  options?: [string, boolean][]
  onFieldsChange: (name: string, value: string) => void
  getFieldValue: (name: string) => any
}

const { Option } = Select

const InputDropdown: FC<InputDropdownProps> = ({
  name,
  required,
  DESC,
  editable,
  sectionName,
  options,
  onFieldsChange,
  getFieldValue,
}) => (
  <Tooltip title={DESC} placement="topLeft">
    <Form.Item
      label={name}
      name={sectionName ? `${sectionName}/${name}` : `${name}`}
      rules={[
        {
          required: editable && required,
          message: `'${name}' is required`,
        },
      ]}
    >
      <Select
        disabled={!editable}
        defaultValue="enable"
        onChange={(value, _option) => {
          onFieldsChange(name, value)
        }}
      >
        {options &&
          options.map(option => (
            <Option
              value={option[0]}
              disabled={!option[1]}
              style={{ opacity: option[1] ? '' : 0.3 }}
            >
              {convertScheduleStatus(option[0])}
            </Option>
          ))}
      </Select>
    </Form.Item>
  </Tooltip>
)

export default InputDropdown
