import React, { FC } from "react";

import { Input, Table } from "antd";

import useFormSearch from "../../../hooks/job/jobCreate/useFormSearch";
import useTableAPI from "../../../hooks/table/useTableAPI";
import useTableSort from "../../../hooks/table/useTableSort";
import useTable from "../../../hooks/table/useTable";
import useTablePagination from "../../../hooks/table/useTablePagination";

const { Search } = Input;

type ModalJobTableFormProps = {
  selectedRow: any[];
  onRowChange: (selectedRowKeys, selectedRows) => void;
};

const ModalJobTableForm: FC<ModalJobTableFormProps> = ({
  selectedRow,
  onRowChange,
}) => {
  const { keyword, setKeyword } = useFormSearch();
  const { loading, onSearch, onPageChange } = useTableAPI(
    "/job",
    keyword,
  );
  const { handleSortChanged } = useTableSort();
  const { total } = useTablePagination();
  const data = useTable();

  return (
    <div style={{ textAlign: "center" }}>
      <Search
        placeholder="검색 키워드를 입력하세요"
        enterButton
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        style={{ marginBottom: "16px" }}
        onSearch={() => onSearch()}
        loading={loading}
      />
      <Table
        loading={loading}
        columns={column}
        dataSource={data}
        pagination={{
          position: ["bottomCenter"],
          total: total,
          pageSize: 10,
          pageSizeOptions: ["10"],
          onChange: async (page, _pageSize) => {
            await onPageChange(page);
          },
        }}
        onChange={handleSortChanged}
        style={{
          marginBottom: "16px",
          wordBreak: "break-all",
          cursor: "pointer",
        }}
        size="small"
        tableLayout="fixed"
        rowSelection={{
          type: "radio",
          selectedRowKeys: [`${selectedRow[0] ? selectedRow[0].key : ""}`],
          onChange: (selectedRowKeys, selectedRows) => {
            onRowChange(selectedRowKeys, selectedRows);
          },
        }}
      />
    </div>
  );
};

export default ModalJobTableForm;

type Column = {
  title: string;
  dataIndex: string;
  key: string;
  sorter?: (a: any, b: any) => number;
}[];

const column: Column = [
  {
    title: "Job ID",
    dataIndex: "job_id",
    key: "job_id",
    sorter: (a, b) => a.job_id.localeCompare(b.job_id),
  },
  {
    title: "Job Name",
    dataIndex: "job_name",
    key: "job_name",
    sorter: (a, b) => a.job_name.localeCompare(b.job_name),
  },
  {
    title: "Description",
    dataIndex: "desc",
    key: "desc",
    sorter: (a, b) => a.DESC.localeCompare(b.DESC),
  },
  {
    title: "Argo ID",
    dataIndex: "argo_id",
    key: "argo_id",
    sorter: (a, b) => a.argo_id.localeCompare(b.argo_id),
  },
];
