import React, { FC } from 'react'

import { Modal, Form } from 'antd'
import { Button } from '@madup-inc/mad-design'

import useStepState from '../../../../hooks/useStepState'

const JobCreateButtonOnSecond: FC = () => {
  const { onClickPrev } = useStepState()

  const confirm = () => {
    Modal.confirm({
      title: '경고!',
      content: '지금껏 작성된 모든 정보들이 지워질 수 있습니다. 이전 단계로 돌아가시겠습니까?',
      onCancel: () => {},
      onOk: () => {
        onClickPrev()
      },
    })
  }

  return (
    <section style={{ marginTop: '24px', textAlign: 'right' }}>
      <Form.Item style={{ display: 'inline' }}>
        <Button onClick={() => confirm()}>Previous</Button>
        <Button style={{ marginLeft: '8px' }} type="primary" htmlType="submit">
          next
        </Button>
      </Form.Item>
    </section>
  )
}

export default JobCreateButtonOnSecond
