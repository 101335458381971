import useJSONViewer from '../../JSONViewer/useJSONViewer'

export default function useJOBPreview() {
  const { onChangeJSON } = useJSONViewer()

  const setJOBPreview = form => {
    onChangeJSON(form)
  }

  return { setJOBPreview }
}
