import * as React from "react";

import "./../../lib/styles/JSONViewer.scss";

type JSONViewerObjectProps = {
  heading: string;
  value?: JSX.Element | JSX.Element[];
};

const JSONViewerObject: React.FC<JSONViewerObjectProps> = ({
  heading,
  value,
}) => (
  <div className="json-object-container">
    <header className="json-object-header">{heading}</header>
    <div className="json-object-border" />
    <div className="json-value-container">{value}</div>
  </div>
);

export default JSONViewerObject;
