import React, { FC } from "react";

import { Card, Typography } from "antd";

import { BulbFilled, AlertFilled } from "@ant-design/icons";

const { Title } = Typography;

type DashboardServerStatusCardProps = {
  serverStatus: {
    status: string,
    detail?: string[];
  }
}

const DashboardServerStatusCard: FC<DashboardServerStatusCardProps> = ({ serverStatus }) =>
  <Card style={{
    width: "100%",
    height: "100%"
  }}>
    <div style={{
      display: "flex",
      alignItems: "flex-start"
    }}>
      {getIcon(serverStatus.status)}
      <div style={{
        display: "inline-block",
        marginLeft: "24px"
      }}>
        <p style={{
          margin: 0
        }}>Server Status</p>
        <Title level={4} style={{
          margin: 0
        }}>{serverStatus.status}</Title>
        <ul style={{
          margin: 0,
          paddingLeft: "20px"
        }}>
          {serverStatus.detail && serverStatus.detail.map(item =>
            <li>{item}</li>)}
        </ul>
      </div>
    </div>
  </Card>;


export default DashboardServerStatusCard;

const getIcon = (status: string) => {
  switch (status) {
    case "Good":
      return <BulbFilled style={{
        color: "#52c41a",
        fontSize: "48px",
        display: "inline-block"
      }}/>;
    case "Bad":
      return <AlertFilled style={{
        color: "#ff4d4f",
        fontSize: "48px",
        display: "inline-block"
      }}/>;
  }
};
