// Actions
const SET_MODAL_TABLE = "modaltable/SET_TABLE" as const;
const INIT_MODAL_TABLE = "modaltable/INIT_TABLE" as const;

// Actions Type
type ModalTableAction =
  | ReturnType<typeof setModalTable>
  | ReturnType<typeof initModalTable>;

// State Type & Initial State
export type Data = {
  [key: string]: string;
};

export type ModalTableState = {
  total: number;
  data: Data[];
};

const initialState: ModalTableState = {
  total: 0,
  data: [],
};

// Reducer
export const getKeyOfValue = (name, row) => {
  switch (name) {
    case "product_id":
      return row.product_id;
    case "owner_id":
      return row.owner_id;
    case "argo_id":
      return row.id;
    case "job_id":
      return `${row.job_name} (${row.job_id})`;
    default:
      return `${row.context_key} (${row.context_value})`;
  }
};

const modaltable = (
  state: ModalTableState = initialState,
  action: ModalTableAction
) => {
  switch (action.type) {
    case INIT_MODAL_TABLE:
      return initialState;
    case SET_MODAL_TABLE:
      if ((<ModalTableState>action.payload.data).total) {
        const data: Data[] = (<ModalTableState>action.payload.data).data.map(
          (item) => {
            item.key = getKeyOfValue(action.payload.type, item);
            return item;
          }
        );
        return <ModalTableState>{
          ...state,
          total: (<ModalTableState>action.payload.data).total,
          data: data,
        };
      } else {
        const data: Data[] = (<Data[]>action.payload.data).map((item, idx) => {
          item.key = getKeyOfValue(action.payload.type, item);
          return item;
        });
        return <ModalTableState>{ ...state, data: data };
      }
    default:
      return state;
  }
};

export default modaltable;

// Action Creators
export const setModalTable = (data: ModalTableState | Data[], type) => ({
  type: SET_MODAL_TABLE,
  payload: { data, type },
});

export const initModalTable = () => ({
  type: INIT_MODAL_TABLE,
});
