import React, { FC } from "react";

import { Col, Row } from "antd";
import DashboardJobExecutionGraph from "./DashboardJobExecutionGraph";
import DashboardLatencyGraph from "./DashboardLatencyGraph";

const DashboardGraph: FC = () => (
  <Row gutter={24}>
    <Col span={12}>
      <DashboardLatencyGraph/>
    </Col>
    <Col span={12}>
      <DashboardJobExecutionGraph/>
    </Col>
  </Row>
);

export default DashboardGraph;
