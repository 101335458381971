import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import ReportTypeSelect from './ReportTypeSelect'
import ReportJobSetting from './ReportJobSetting'
import { Channel, ReportField } from '../../../lib/api/report'

type ReportCreateProps = {
  ownerID?: string
  channels: Array<Channel>
}

const ReportCreate: FC<ReportCreateProps> = ({ ownerID = '', channels }) => {
  const [step, setStep] = useState<number>(1)
  const [reportField, setReportField] = useState<ReportField>({})
  const [channelID, setChannelID] = useState<string | number>(1)
  const [reportInfo, setReportInfo] = useState({ name: 'facebook_hourly', type: 'hourly' })
  const history = useHistory()

  if (!ownerID) {
    history.push('/report')
    return null
  }

  return (
    <section className="report-create-wrapper">
      {step === 1 && (
        <ReportTypeSelect
          setStep={setStep}
          setReportField={setReportField}
          channelID={channelID}
          setChannelID={setChannelID}
          channels={channels}
          setReportInfo={setReportInfo}
          reportInfo={reportInfo}
        />
      )}
      {step === 2 && (
        <ReportJobSetting
          setStep={setStep}
          reportField={reportField}
          ownerID={ownerID}
          channelID={channelID}
          reportInfo={reportInfo}
        />
      )}
    </section>
  )
}

export default ReportCreate
