import { useCallback, useState } from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

import { RootState } from "../../modules";
import { updateJSONViewer, initJSONViewer } from "../../modules/JSONViewer";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useJSONViewer() {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const jsonState = typedUseSelector((state: RootState) => state.JSONViewer);

  const onChangeJSON = useCallback((JSON) => dispatch(updateJSONViewer(JSON)), [
    dispatch,
  ]);

  const cleanUpJSON = useCallback(() => dispatch(initJSONViewer()), [dispatch]);

  return {
    jsonState,
    onChangeJSON,
    cleanUpJSON,
    loading,
    setLoading,
  };
}
