import React, { FC } from "react";

import ContextDetail from "../../components/Table/Detail";
import product from "../../constants/detail/product";
import productForm from "../../constants/form/product";

const ProductDetailPage: FC = () => <ContextDetail modalForm={productForm}
                                                   form={product}/>;

export default ProductDetailPage;
