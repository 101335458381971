import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { useLocation } from "react-router-dom";

import * as Icon from "@ant-design/icons";

import { RootState } from "../../modules";
import { changeIsCollapsed } from "../../modules/sidebar";

const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default function useSidebar() {
  const [selectedKey, setSelectedKey] = useState("0");
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedKey(getSidebarStatus());
  }, [location.pathname]);

  const isCollapsed = typedUseSelector((state: RootState) => state.sidebar.isCollapsed);
  const setIsCollapsed = () => {
    dispatch(changeIsCollapsed());
  };

  const getSidebarStatus = () => {
    const submenu = location.pathname.split("/")[1];
    const isCreate = location.pathname.split("/")[2] === "create";
    if (!submenu) return "0";
    switch (submenu) {
      case "job-context":
        return "1-0";
      case "product":
        return "1-1";
      case "sourcecode":
        return "1-2";
      case "template":
        if (isCreate) return "2-1";
        return "2-0";
      case "report":
        return "3";
      case "job":
        if (isCreate) return "4-1";
        return "4-0";
      case "schedule":
        if (isCreate) return "5-1";
        return "5-0";
      case "monitoring":
        return "6";
      case "audit":
        return "7";
      default:
        return "8";
    }
  };

  return {
    isCollapsed,
    setIsCollapsed,
    selectedKey,
  };
}

type Categories = {
  [title: string]: {
    path: string;
    icon: JSX.Element;
    menu?: string[][];
  };
};

export const categories: Categories = {
  Dashboard: {
    path: "/",
    icon: <Icon.DashboardOutlined />,
  },
  Resource: {
    path: "",
    icon: <Icon.DatabaseOutlined />,
    menu: [
      ["Job Context", "/job-context"],
      ["Product", "/product"],
      ["Sourcecode", "/sourcecode"],
    ],
  },
  Template: {
    path: "/template",
    icon: <Icon.FormOutlined />,
    menu: [
      ["List", ""],
      ["Create", "/create"],
    ],
  },
  Report: {
    path: "/report",
    icon: <Icon.FileTextOutlined />,
  },
  Job: {
    path: "/job",
    icon: <Icon.PartitionOutlined />,
    menu: [
      ["List", ""],
      ["Create", "/create"],
    ],
  },
  Schedule: {
    path: "/schedule",
    icon: <Icon.HistoryOutlined />,
    menu: [
      ["List", ""],
      ["Create", "/create"],
    ],
  },
  Monitoring: {
    path: "/monitoring",
    icon: <Icon.LineChartOutlined />,
  },
  Audit: {
    path: "/audit",
    icon: <Icon.SecurityScanOutlined />,
  },
};
