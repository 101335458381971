import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { Key, SortOrder } from "antd/lib/table/interface";

export default function useTableSort() {
  const location = useLocation();
  const param = new URLSearchParams(location.search);
  const history = useHistory();
  const { path } = useRouteMatch();
  const convertOrderKeywords = (keyword: "ascend" | "descend" | null | undefined) => {
    switch (keyword) {
      case "ascend":
        return "ASC";
      case "descend":
        return "DESC";
      default:
        return "";
    }
  };

  const handleTableChange = (
    _pagination,
    _filters,
    sorter: Sorter | Sorter[]
  ) => {
    param.delete("sort");
    if (Array.isArray(sorter)) {
      sorter.map((item: Sorter) => {
        try {
          param.append(
            "sort",
            `${item.field},${convertOrderKeywords(item.order)}`
          );
        } catch (e) {
          console.log(e);
        }
      });
    } else {
      // @ts-ignore
      if (sorter.order) {
        // @ts-ignore
        param.append(
          "sort",
          `${sorter.field},${convertOrderKeywords(sorter.order)}`
        );
      }
    }
  };

  const handleSortChanged = (_pagination, _filters, sorter: object) => {
    handleTableChange(_pagination, _filters, sorter);
    history.push(`${path}?${param.toString()}`);
  };

  return { handleSortChanged };
}

type Sorter = {
  order?: SortOrder;
  field?: Key | Key[];
  [key: string]: any;
};
